import styled from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.white};
  border-radius: 12px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.3s;
  overflow: hidden;

  width: 100%;
  /* max-width: 13.562rem; */

  :hover::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: linear-gradient(240deg, #2469a8, #f8b700, #c61722);
    z-index: -1;
    margin: -2px;
    border-radius: 5px;
    transition: display 5s;
    background-size: 300% 300%;
    animation: colors 8s ease infinite;
  }
  @keyframes colors {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background: ${(props) => props.theme['primary-800']};

    button {
      border: none;
      background: none;
      font-size: 1rem;
      transition: color 0.3s;
      color: ${(props) => props.theme.white};
      padding-right: 0.75rem;
      :hover {
        color: ${(props) => props.theme.primary};
      }
    }
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  :hover::before {
    background: white;
  }

  .contentGraphicValue {
    margin-left: 2px;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    h6 {
      position: absolute;
      margin: 0;
      font-size: 1rem;
    }
  }
  h5 {
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1.25rem;
    color: ${(props) => props.theme['gray-400']};
    padding: 0 0.5rem;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
`;

export const ImageContent = styled.div`
  background: ${(props) => props.theme.white};
  padding: 0.25rem 1rem;
  border-radius: 10px 36px 36px 0px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 2rem;
  }
`;
