import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding: 0;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;

  .slick-track {
    display: flex;
    align-items: center;
  }

  .graphicCard {
    padding-bottom: 0;
    span {
      margin: 0;
    }

    strong {
      color: ${(props) => props.theme['gray-700']};
    }
  }

  .tutorial {
    font-size: 1.5rem;
    /* margin: 0; */
    margin-bottom: 1rem;
    border-bottom: 1px solid ${(props) => props.theme['gray-700']};
  }

  .fade .welcomeModal .modal .show {
    background: blue;
  }

  @media (min-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

interface IPropsRowDiv {
  isShort: boolean;
}

export const RowDiv = styled.div<IPropsRowDiv>`
  display: grid;
  grid-template-columns: 1fr 21.375rem;

  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 250px;
  position: relative;
  gap: 1rem;
  transition: all 0.3s ease-in-out;
  z-index: 10;

  .ant-carousel .slick-prev {
    left: 0 !important;
    z-index: 10;
  }
  .ant-carousel .slick-next {
    right: 0 !important;
  }

  @media (max-width: 1280px) {
    grid-template-columns: 1fr 19.8125rem;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ColDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ContentCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  gap: 1rem;
  width: 100%;
`;
