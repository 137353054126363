import styled from 'styled-components';

export const CompositionGraphicWalletContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 3rem;
  width: 100%;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    strong {
      font-size: 1rem;
      color: ${(props) => props.theme['gray-500']};
      font-weight: 400;
    }
  }
`;

export const ContentButtonChangeGraphic = styled.div`
  display: flex;
  flex-direction: row;
  background: rgba(131, 196, 255, 0.2);
  border-radius: 67px;
  padding: 0.25rem;
  gap: 0.25rem;
`;

interface IPropsButtonChangeGraphic {
  isActive: boolean;
}

export const ButtonChangeGraphic = styled.button<IPropsButtonChangeGraphic>`
  all: unset;
  background: ${(props) => (props.isActive ? '#123656' : 'transparent')};
  border-radius: 100%;
  width: 1.125rem;
  height: 1.125rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.isActive ? props.theme.white : props.theme['gray-500']};
`;

export const ContentGraphic = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: start;
    justify-content: center;
    height: 100%;
    li {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      list-style-type: none;
      white-space: nowrap;
    }
  }
  @media (max-width: 480px) {
    flex-direction: column;

    ul {
      margin-left: 2.5rem;
      padding-right: 1.25rem;
    }
  }
`;

interface IPropsContentListTitle {
  color: string;
}

export const ContentListTitle = styled.span<IPropsContentListTitle>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: ${(props) => props.color};
    position: absolute;
    left: -1.25rem;
    z-index: 10;
  }
`;
