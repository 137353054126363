import ReactApexChart from 'react-apexcharts';
import { IPropsRankActive } from '../../@types/listActiveType';
import {
  CardActiveRankMobileContainer,
  TextContent,
} from './CardActiveRankMobileStyles';
import { Tooltip } from 'antd';
import { cores } from '../../../../../utils/coresGraficos';

interface IPropsCardActiveRank {
  active: IPropsRankActive;
  selectedActive: ({
    slug,
    type,
  }: {
    slug: string;
    type: '' | 'action' | 'fii';
  }) => void;
}

export function CardActiveRankMobile({
  active,
  selectedActive,
}: IPropsCardActiveRank) {
  const compare = {
    series: [
      active.LucroLiquido,
      active.EBITDA,
      active.Dividendos,
      active.DividaLiquida,
      active.DividaLiquidaEbitida,
      active.Resiliencia,
      active.Governanca,
      active.Indices,
    ],
    options: {
      chart: {
        height: 390,
        type: 'donut',
      },
      yaxis: {
        labels: {
          //@ts-ignore
          formatter: (val) => `${val}%`,
        },
      },
      grid: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },

      legend: {
        show: false,
        position: 'bottom',
      },

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              total: {
                show: false,
                label: 'Media',
                formatter: () => `${active.total_chart.toFixed(0)}%`,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
        //@ts-ignore
        formatter: (val) => `${val.toFixed(0)}%`,
      },
      tooltip: {
        y: {
          //@ts-ignore
          formatter: (val) => `${val.toFixed(0)}%`,
        },
      },

      labels: [
        'Lucro Liquido',
        'EBITDA',
        'Dividendos',
        'Divida Líquida',
        'Divida Líquida EBITDA',
        'Resiliencia',
        'Governanca',
        'Índices',
      ],
      colors: cores,
    },
  };

  const mainAsset = active.Assets.split('|')
    .sort(
      (a: string, b: string) =>
        Number(a.replace(/[a-zA-Z]/g, '')) - Number(b.replace(/[a-zA-Z]/g, ''))
    )[0]
    .replace('.SA', '');
  return (
    <CardActiveRankMobileContainer
      onClick={() =>
        selectedActive({
          slug: mainAsset,
          type: 'action',
        })
      }
    >
      <TextContent>
        <h3>{mainAsset}</h3>
        <span title={active.NomeEmpresa}>{active.NomeEmpresa}</span>
      </TextContent>
      <div className="contentGraphicValue">
        <ReactApexChart
          //@ts-ignore
          options={compare.options}
          series={compare.series}
          type="donut"
          width={75}
        />
        <Tooltip title="Essa é a nota da empresa com base na média das nossas avaliações">
          <h6>{active.total_chart.toFixed(0)}%</h6>
        </Tooltip>
      </div>
    </CardActiveRankMobileContainer>
  );
}
