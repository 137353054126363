import styled, { css } from 'styled-components';

interface IPropsContainerCardHomeData {
  isBlocked: boolean;
}

export const Container = styled.div<IPropsContainerCardHomeData>`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  border-radius: 4px;
  padding: 1rem 1.5rem;
  position: relative;

  span {
    margin-bottom: 2.25rem;
    color: ${(props) => props.theme['gray-700']};
    font: 500 0.875rem 'Poppins', sans-serif;
  }
  h1 {
    margin: 0;
    font: 700 1.75rem 'Poppins', sans-serif;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    filter: ${(props) => props.isBlocked && 'blur(10px)'};
  }
  /* .blocked {
    position: absolute;
    width: 200px;
    height: 40px;
    background: rgba(255, 255, 255, 0.7);
    bottom: 10px;
    left: 20px;
    -webkit-filter: blur(1px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(3px);
  } */
`;
