import styled from 'styled-components';

interface IPropsFormFeedbackUser {
  isShow: boolean;
}

export const FormFeedbackUserContainer = styled.form<IPropsFormFeedbackUser>`
  display: ${(props) => (props.isShow ? 'flex' : 'none')};
  flex-direction: column;
  gap: 1rem;

  background: ${(props) => props.theme.white};
  border-radius: 8px;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 10;
  padding: 1rem;
  width: 100%;
  max-width: 20rem;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  max-height: 25rem;
  overflow: auto;

  header {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;

    h6 {
      margin-top: 1.5rem;
    }
    .closeButton {
      all: unset;
      color: ${(props) => props.theme['gray-700']};
      line-height: 0;
      font-size: 1.25rem;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  label {
    font-size: 0.875rem;
    color: ${(props) => props.theme['gray-700']};
    margin: 0;
  }

  input,
  select,
  textarea {
    padding: 0.5rem;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-700']};
    border: ${(props) => props.theme.border};
    border-radius: 4px;
  }
  .starContent {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    gap: 0.25rem;

    button {
      border: 0;
      background: transparent;
      font-size: 1.5rem;
      line-height: 0;
      color: ${(props) => props.theme['brand-secondary']};

      :hover {
        svg {
          fill: ${(props) => props.theme['brand-secondary']};
        }
      }
    }
  }
`;
