import styled from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';

export const WalletContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .contentCarousel {
    .slick-dots {
      .slick-active {
        background: #0176e1;
        height: 12px !important;
        width: 12px !important;
        button {
          background: #0176e1;
          height: 12px !important;
          width: 12px !important;
        }
      }
    }

    ul {
      height: auto;
      bottom: -1rem !important;
      align-items: center;
      li {
        background: #83c4ff;
        width: 8px !important;
        height: 8px !important;
        border-radius: 100%;
        button {
          background: transparent !important;
        }
      }
    }
    @media (max-width: 768px) {
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ContentTab = styled(ScrollContainer)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  overflow: auto;
  white-space: nowrap;
`;

interface IPropsButtonTab {
  isActive: boolean;
}

export const ButtonTab = styled.button<IPropsButtonTab>`
  all: unset;
  padding: 0.675rem 0.875rem;
  width: 100%;
  text-align: center;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;

  background: ${(props) =>
    props.isActive ? props.theme['primary-800'] : props.theme.white};
  color: ${(props) =>
    props.isActive ? props.theme.white : props.theme['gray-300']};
  font-weight: 500;
  font-size: 1.125rem;
`;

export const Content = styled.main`
  background: ${(props) => props.theme.white};
  padding: 1.5rem 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 2px 2fr;
  gap: 0.5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    border-radius: 39px;
  }
`;

export const ValuesContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .contentCarousel {
    .slick-dots {
      .slick-active {
        background: #0176e1;
        height: 12px !important;
        width: 12px !important;
        button {
          background: #0176e1;
          height: 12px !important;
          width: 12px !important;
        }
      }
    }

    ul {
      height: auto;
      bottom: -1rem !important;
      align-items: center;
      li {
        background: #83c4ff;
        width: 8px !important;
        height: 8px !important;
        border-radius: 100%;
        button {
          background: transparent !important;
        }
      }
    }
    @media (max-width: 768px) {
      margin-bottom: 2rem;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const TabValuesContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
  margin-bottom: 4.125rem;
`;
interface IPropsButtonTabContent {
  active: boolean;
}

export const ContentButtonValues = styled.button<IPropsButtonTabContent>`
  all: unset;
  padding: 0.875rem 1.5rem;
  border-radius: 43px;
  border-bottom: 1px solid ${(props) => props.theme['primary-800']};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  font-weight: 400;
  font-size: 1.125rem;
  background: ${(props) =>
    props.active ? props.theme['primary-800'] : props.theme.white};
  color: ${(props) =>
    props.active ? props.theme.white : props.theme['gray-500']};
`;

export const ContentTabMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.theme.white};
  border-radius: 39px;
  justify-content: center;
  width: 100%;
  gap: 1rem;
`;

export const ButtonTabContentMobile = styled.button<IPropsButtonTabContent>`
  all: unset;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  font-weight: 400;
  font-size: 1.125rem;

  color: ${(props) =>
    props.active ? props.theme['primary-800'] : props.theme['gray-500']};
`;

export const ContentTabNavigateMobile = styled(ScrollContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  white-space: nowrap;
  overflow: auto;
  margin-top: 1rem;
`;

export const SelectedTabNavigateMobile = styled.button<IPropsButtonTab>`
  all: unset;
  padding: 0.5rem 1rem;
  border-radius: 39px;
  font-weight: 400;
  font-size: 0.875rem;
  background: ${(props) =>
    props.isActive ? props.theme['primary-800'] : props.theme.white};
  color: ${(props) =>
    props.isActive ? props.theme.white : props.theme['gray-300']};
`;
