import styled from 'styled-components';

interface IPropsContainerSideBar {
  isOpen: boolean;
  title: string;
  isMobile: boolean;
  isBlackScreen?: boolean;
}

export const Container = styled.aside<IPropsContainerSideBar>`
  display: ${(props) => (props.isMobile ? 'none' : 'block')};
  position: fixed;
  z-index: 100;
  /* width: ${(props) => (props.isOpen ? '250px' : '60px')}; */
  height: 100%;
  background-color: ${(props) =>
    props.isBlackScreen ? props.theme['gray-700'] : props.theme.white};
  overflow-y: auto;
  transition: width 1s;
  width: 60px;
  &:hover {
    width: 250px;
  }

  .minLogo {
    display: none;
  }
  transition: all 1s;

  .block {
    background-color: rgba(21, 21, 21, 0.3);
    cursor: no-drop;
  }
  .ant-menu-title-content {
    font-size: 0.8rem !important;
  }

  .ant-menu-item {
    padding-left: 1rem !important;
  }
  .ant-menu-submenu-title {
    padding-left: 1rem !important;
  }

  footer {
    display: flex;
    position: fixed;
    z-index: 1;
    bottom: 0;
    background-color: ${(props) =>
      props.isBlackScreen ? props.theme['gray-700'] : props.theme.white};

    width: ${(props) => (props.isOpen ? '250px' : '60px')};
    padding: ${(props) => (props.isOpen ? '24px' : '1.2rem')};
    align-items: center;
    width: auto;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      color: ${(props) =>
        props.isBlackScreen ? 'white' : props.theme['text-body']};
      font: 400 1rem 'Poppins', sans-serif;
      cursor: pointer;
      transition: color 0.3s;
    }
    .logoutIcon {
      transition: color 0.3s;
      color: ${(props) =>
        props.isBlackScreen ? 'white' : props.theme['text-body']};
    }

    :hover {
      span {
        color: ${(props) => props.theme.primary};
      }
      .logoutIcon {
        color: ${(props) => props.theme.primary};
      }
    }
  }
`;

export const ContainerShort = styled.aside`
  position: fixed;
  z-index: 100;
  width: 50px;
  height: 100%;
  background-color: ${(props) => props.theme.white};
  overflow-y: auto;
  transition: width 1s;
`;

export const HeaderSideBar = styled.header`
  display: flex;

  padding: 1.187rem 4rem 0 0.75rem;
  object-fit: fill;
  width: 100%;
  img {
    width: 8.125rem;
    cursor: pointer;
  }

  @media (max-width: 820px) {
    padding: 1rem 0 0 0.25rem;
  }
`;

export const ShowSideBar = styled.div<IPropsContainerSideBar>`
  clip-path: polygon(100% 77%, 100% 23%, 70% 0, 70% 100%);
  cursor: pointer;
  width: 100%;
  height: 100%;
  max-width: 3rem;
  max-height: 5rem;
  position: fixed;
  background: ${(props) => props.theme.white};
  transition: all 1s;
  left: ${(props) => (props.isOpen ? '13.5rem' : '1.5rem')};
  top: 17rem;
  display: ${(props) => (props.isMobile ? 'none' : 'block')};
  z-index: 100;
  h2 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    color: ${(props) => props.theme.black};
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100%;
    padding-right: 5px;
  }
`;
