import styled from 'styled-components';

export const ContentForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${(props) => props.theme.white};
  padding: 1rem;
  border-radius: 8px;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  position: relative;

  i {
    position: absolute;
    right: 8px;
    bottom: 16px;
    cursor: pointer;
  }
  label {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    color: ${(props) => props.theme['gray-500']};
  }

  input {
    all: unset;
    box-shadow: 2px 2px 6px 0px #0000001a;
    padding: 1rem;
    padding-right: 2rem;
    border-radius: 8px;
    outline: 1px solid transparent;
    font-weight: 400;
    font-size: 0.875rem;
    color: ${(props) => props.theme['gray-500']};

    &:focus {
      outline-color: ${(props) => props.theme['primary-800']};
    }

    &:disabled {
      cursor: not-allowed;
      background: ${(props) => props.theme['white-300']};
    }
  }
`;

export const ValidationPassword = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  flex-wrap: wrap;
`;

interface IPropsInfoValidation {
  error: boolean;
}
export const InfoValidationPassword = styled.li<IPropsInfoValidation>`
  list-style: inside;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  color: ${(props) => (!props.error ? props.theme.black : props.theme.success)};
  align-items: center;
  font-size: 0.75rem;
  align-items: center;
  text-decoration: ${(props) => props.error && 'line-through'};

  /* ::before {
    content: '*';
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  span {
    width: 0.25rem;
    height: 0.25rem;
    background-color: ${(props) =>
      !props.error ? props.theme['gray-700'] : props.theme.success};
    border-radius: 999px;
  }

  /* svg {
    background: ${(props) =>
    !props.error ? props.theme.error : props.theme.success};
    border-radius: 100%;
    color: ${(props) => props.theme.white};
    font-size: 1.1rem;
    opacity: 0.8;
  } */
`;
