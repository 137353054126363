import axios from 'axios';
import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { InfinityScroll } from '../../../components/InfinityScroll';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { financeRoutes } from '../../../utils/RoutesApi';
import { CardActiveRank } from './components/CardActiveRank';
import { ListCardsActivesRank } from '../LoadingModels';
import { AsideFiltersAction } from './components/AsideFiltersAction';
import {
  ContentFiltersAndSearch,
  ContentList,
  LoadingContent,
  RowDiv,
  WarningNewMessage,
} from './acoesPainelStyles';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './styles.css';
import { InputComponent } from '../../../components/InputComponent';
import { CardActiveRankMobile } from './components/CardActiveRank/CardActiveRankMobile';
import { Funnel, Info } from '@phosphor-icons/react';
import { SideModal } from '../../../components/SideModal';

const AcoesPainel = ({
  seg = null,
  isComponent = false,
  ticketActive = '',
  selectedActive = ({ slug, type }) => {},
}) => {
  const { setTitle } = useTitleAndUser();
  // const getFilterActionsPainel = JSON.parse(
  //   localStorage.getItem('filterActionsPainel-v0.0.1')
  // );
  const [modalFilter, setModalFilter] = useState(false);

  const [newLoading, setNewLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [listActiveRank, setListActiveRank] = useState({
    count: 0,
    current: 0,
    data: [],
    links: { next: null, previous: null },
    total_pages: 0,
  });
  const [searchFilters, setSearchFilters] = useState(() => {
    // if (getFilterActionsPainel && !isComponent) {
    //   return getFilterActionsPainel;
    // }
    return {
      lucroliquido: 0,
      roe: 0,
      ebitda: 0,
      dividendos: 0,
      dividaliquida: 0,
      dividaebitda: 0,
      cnpjativo: '',
      setor: '',
      segmento: '',
      nome_empresa: '',
      subsetor: '',
      segmento_listagem: '',
      isFiltered: false,
    };
  });

  useEffect(() => {
    if (seg !== null) {
      setSearchFilters({
        ...searchFilters,
        subsetor: seg.value,
        setor: seg?.seg,
      });
    }
  }, [seg]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isComponent) {
      setTitle('Lista de Ações');
    }
  }, []);

  useEffect(() => {
    // if (!isComponent) {
    //   localStorage.setItem(
    //     'filterActionsPainel-v0.0.1',
    //     JSON.stringify(searchFilters)
    //   );
    // }
    if (searchFilters.nome_empresa.length > 0) {
      const timeOutId = setTimeout(() => {
        handleListRankOrActives();
      }, 500);
      return () => clearTimeout(timeOutId);
    } else {
      const timeOutId = setTimeout(() => {
        handleListRankOrActives();
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [searchFilters]);

  async function handleListRankOrActives() {
    setLoading(true);
    let data = searchFilters;
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_RANK_ACTIVES_LIST}`,
        data,
        {
          params: {
            page: 1,
            page_size: 21,
          },
        }
      )
      .then((response) => {
        if (response.data.count > 0) {
          setListActiveRank(response.data);
        } else {
          setListActiveRank({
            count: 0,
            current: 0,
            data: [],
            links: { next: null, previous: null },
            total_pages: 0,
          });
        }
      })
      .catch((err) => {});
    setLoading(false);
  }
  async function handleListRankOrActivesInfinit(link) {
    setNewLoading(true);
    let data = searchFilters;
    await axios
      .post(link, data)
      .then((response) => {
        setListActiveRank({
          ...response.data,
          data: [...listActiveRank.data, ...response.data.data],
        });
        setNewLoading(false);
      })
      .catch((err) => {
        setNewLoading(false);
      });
  }

  function changeSearchActiveName(event) {
    const value = event.target.value;
    if (value.length > 0) {
      setSearchFilters({
        ...searchFilters,
        nome_empresa: value,
        isFiltered: true,
      });
    } else {
      setSearchFilters({
        ...searchFilters,
        nome_empresa: '',
      });
    }
  }

  return (
    <>
      <ContentList>
        {window.screen.width > 1100 && (
          <AsideFiltersAction
            setSearchFilters={setSearchFilters}
            searchFilters={searchFilters}
          />
        )}
        <div style={{ width: '100%', background: 'white', padding: '1rem' }}>
          {!isComponent && (
            <>
              <ContentFiltersAndSearch>
                <InputComponent
                  placeholder="Buscar pelo nome da empresa"
                  onChange={changeSearchActiveName}
                  value={searchFilters.nome_empresa}
                  roundedFull
                  isSearch
                />
                {window.screen.width < 1100 && (
                  <button
                    className="buttonFilter"
                    onClick={() => setModalFilter(true)}
                  >
                    <Funnel size={36} color="#505050" />
                  </button>
                )}
              </ContentFiltersAndSearch>
              <br />
              <WarningNewMessage>
                <Info color="#606060" size={24} />
                <p>
                  O Ranking tem como objetivo orientar você a iniciar seus
                  estudos pelos ativos mais relevantes. No entanto, é importante
                  ressaltar que ele não deve ser interpretado como uma
                  recomendação específica de compra ou venda.
                </p>
              </WarningNewMessage>
            </>
          )}

          {loading ? (
            <>
              <RowDiv>
                <ListCardsActivesRank />
                <ListCardsActivesRank />
              </RowDiv>
              <br />
              <RowDiv>
                <ListCardsActivesRank />
                <ListCardsActivesRank />
              </RowDiv>
            </>
          ) : (
            <RowDiv>
              {listActiveRank?.data?.map((item) =>
                item.cnpj === ticketActive ? null : window.screen.width <
                  1100 ? (
                  <CardActiveRankMobile
                    active={item}
                    key={item.cnpj}
                    selectedActive={selectedActive}
                  />
                ) : (
                  <CardActiveRank
                    active={item}
                    key={item.cnpj}
                    selectedActive={selectedActive}
                  />
                )
              )}
            </RowDiv>
          )}
          {listActiveRank.data.length < 1 && !loading && (
            <h1 style={{ fontSize: '1rem' }}>
              Não achamos nenhum dado nessa busca
            </h1>
          )}
        </div>
      </ContentList>
      <>
        {newLoading && !loading && (
          <LoadingContent>
            <ReactLoading
              type="spinningBubbles"
              color="var(--primary)"
              width="50px"
              height="50px"
            />
          </LoadingContent>
        )}
        {!loading &&
          !newLoading &&
          listActiveRank.data.length < listActiveRank.count && (
            <InfinityScroll
              fetchMore={() =>
                handleListRankOrActivesInfinit(listActiveRank.links.next)
              }
            />
          )}
      </>

      <SideModal id="sideFilter" isOpen={modalFilter} setState={setModalFilter}>
        <AsideFiltersAction
          setSearchFilters={setSearchFilters}
          searchFilters={searchFilters}
        />
      </SideModal>
    </>
  );
};
export default AcoesPainel;
