import styled from 'styled-components';

export const StrategyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IPropsCardGraphic {
  balanced: boolean;
}

export const CardGraphic = styled.div<IPropsCardGraphic>`
  display: flex;
  padding: 1.5rem;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  position: relative;
  z-index: 1;
  background-color: ${(props) => props.theme.white};
  border-radius: 4px;
  border-top: 10px solid ${(props) => (props.balanced ? 'green' : 'red')};
  width: 100%;
  margin-top: 1rem;

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: ${(props) => props.theme['gray-700']};
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    span {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 480px) {
    h2 {
      font-size: 1rem;
    }
  }
`;
