import { AsideContentFilters, ContentInputFilters } from './styles';

interface IPropsFilters {
  sectors: string[];
  subSectors: string[];
  segments: string[];
}

export interface IPropsFilterSelected {
  sector: string;
  subSector: string;
  segment: string;
}

interface IPropsAsideFiltersCompareAssets {
  selectedFilters: IPropsFilterSelected;
  setSelectedFilters: Function;
  filters: IPropsFilters;
}

export function AsideFiltersCompareAssets({
  selectedFilters,
  setSelectedFilters,
  filters,
}: IPropsAsideFiltersCompareAssets) {
  return (
    <AsideContentFilters>
      <ContentInputFilters>
        <label>Segmento de listagem</label>
        <select
          value={selectedFilters.sector}
          onChange={(e) =>
            setSelectedFilters({
              ...selectedFilters,
              sector: e.target.value,
            })
          }
          defaultValue=""
          className="selectFilter"
        >
          <option value="">Selecione um setor</option>
          {filters.sectors.map((sector) => (
            <option value={sector} key={sector}>
              {sector.substring(0, 1).toUpperCase()}
              {sector.substring(1).toLowerCase()}
            </option>
          ))}
        </select>
      </ContentInputFilters>
      <ContentInputFilters>
        <label>Segmento</label>
        <select
          defaultValue=""
          className="selectFilter"
          value={selectedFilters.subSector}
          onChange={(e) =>
            setSelectedFilters({
              ...selectedFilters,
              subSector: e.target.value,
            })
          }
        >
          <option value="">Selecione um subsetor</option>
          {filters.subSectors.map((subsector) => (
            <option value={subsector} key={subsector}>
              {subsector.substring(0, 1).toUpperCase()}
              {subsector.substring(1).toLowerCase()}
            </option>
          ))}
        </select>
      </ContentInputFilters>
      <ContentInputFilters>
        <label>Setor</label>
        <select
          defaultValue=""
          className="selectFilter"
          value={selectedFilters.segment}
          onChange={(e) =>
            setSelectedFilters({
              ...selectedFilters,
              segment: e.target.value,
            })
          }
        >
          <option value="">Selecione um segmento</option>
          {filters.segments.map((segment) => (
            <option value={segment} key={segment}>
              {segment.substring(0, 1).toUpperCase()}
              {segment.substring(1).toLowerCase()}
            </option>
          ))}
        </select>
      </ContentInputFilters>
    </AsideContentFilters>
  );
}
