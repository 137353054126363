import { Info } from '@phosphor-icons/react';
import { Col, InputNumber, Progress, Row, Slider, Tooltip } from 'antd';
import { AdjustStrategyContainer } from './styles';
import { handleToast } from '../../../../../../components/Toast';
import { walletRoutes } from '../../../../../../utils/RoutesApi';
import { baseURL } from '../../../../../../utils/baseURL';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useStrategy } from '../../../hooks/StrategyHooks';
import { ButtonDesign } from '../../../../../../components/ButtonDesign';

export function AdjustStrategy() {
  const {
    total,
    fiis,
    action,
    rendaFixa,
    handleGetConfigStrategyWallet,
    handleChangeActionPercentage,
    handleChangeFiiPercentage,
    handleChangeRendaFixaPercentage,
  } = useStrategy();
  const { handleSubmit } = useForm();

  async function submit(data: any) {
    if (total === 100) {
      data.PorcentagemFII = fiis;
      data.PorcentagemAcoes = action;
      data.PorcentagemRendaVariavel = rendaFixa;

      await axios
        .post(`${baseURL}/api${walletRoutes.WALLET_CONFIG_STRATEGY}`, data)
        .then((response) => {
          handleGetConfigStrategyWallet();
          return handleToast(false, response.data.message);
        })
        .catch((err) => {});
    } else {
      return handleToast(true, 'Você deve atingir 100% da estratégia ');
    }
  }

  return (
    <AdjustStrategyContainer>
      <form onSubmit={handleSubmit(submit)}>
        <Tooltip
          title={
            <p style={{ margin: 0 }}>
              Você poderá criar para cada ativo da sua carteira um percentual
              específico, que entrara como exceção na distribuição automática
              dos ativos.
              <br />A distribuição da carteira deverá ser efetuada melhor
              estratégia para seu perfil de investidor e será base de comparação
              para seus ativos registrados na base de dados da B3.
            </p>
          }
          placement="bottom"
        >
          <div className="titleStrategy">
            <h2>Distribuicão da carteira</h2>
            <Info size="1.5rem" color="black" />
          </div>
        </Tooltip>
        <br />

        <div className="contentSlider">
          <p>Ação</p>
          <Row>
            <Col
              span={
                window.screen.width <= 740
                  ? 17
                  : window.screen.width <= 1060
                  ? 20
                  : 12
              }
            >
              <Slider
                min={0}
                max={100}
                onChange={handleChangeActionPercentage}
                value={typeof action === 'number' ? action : 0}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={0}
                max={100}
                style={{
                  margin: '0 1rem',
                }}
                value={action ? Number(action.toFixed(2)) : 0}
                onChange={(value) =>
                  handleChangeActionPercentage(value as number)
                }
                className="editInput"
              />
            </Col>
          </Row>
        </div>
        <div className="contentSlider">
          <p>Fii</p>
          <Row>
            <Col
              span={
                window.screen.width <= 740
                  ? 17
                  : window.screen.width <= 1060
                  ? 20
                  : 12
              }
            >
              <Slider
                min={0}
                max={100}
                onChange={handleChangeFiiPercentage}
                value={typeof fiis === 'number' ? fiis : 0}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={0}
                max={100}
                style={{
                  margin: '0 1rem',
                }}
                value={fiis ? Number(fiis.toFixed(2)) : 0}
                onChange={(value) => handleChangeFiiPercentage(value as number)}
                className="editInput"
              />
            </Col>
          </Row>
        </div>

        <div className="contentSlider">
          <p>Renda Fixa</p>
          <Row>
            <Col
              span={
                window.screen.width <= 740
                  ? 17
                  : window.screen.width <= 1060
                  ? 20
                  : 12
              }
            >
              <Slider
                min={0}
                max={100}
                onChange={handleChangeRendaFixaPercentage}
                value={typeof rendaFixa === 'number' ? rendaFixa : 0}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={0}
                max={100}
                style={{
                  margin: '0 1rem',
                }}
                value={rendaFixa ? Number(rendaFixa.toFixed(2)) : 0}
                onChange={(value) =>
                  handleChangeRendaFixaPercentage(value as number)
                }
                className="editInput"
              />
            </Col>
          </Row>
        </div>

        <div className="totalSlide">
          <h4>Total</h4>
          <Row>
            <strong>{total.toFixed(2)}%</strong>
            <Progress
              percent={Number(total.toFixed(2))}
              status="active"
              showInfo={false}
            />
          </Row>
        </div>
        <br />
        <div style={{ width: '100%' }}>
          <ButtonDesign
            style={{ width: 'calc(100% - 2rem - 4px)', maxWidth: 'none' }}
            type="submit"
          >
            Salvar estratégia
          </ButtonDesign>
        </div>
      </form>
    </AdjustStrategyContainer>
  );
}
