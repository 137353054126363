import { Tooltip } from 'antd';
import {
  HiOutlineChartSquareBar,
  HiOutlineOfficeBuilding,
} from 'react-icons/hi';
import { BsCalendar4Week } from 'react-icons/bs';
import { ArrowLeft, Buildings } from '@phosphor-icons/react';
import { AiOutlineLineChart, AiOutlinePieChart } from 'react-icons/ai';
import moment from 'moment';
import { MdEditCalendar } from 'react-icons/md';
import ReactApexChart from 'react-apexcharts';
import {
  ColMainCard,
  ContentLogoCompany,
  ContentRow,
  ContentTab,
  MainCardContainer,
  Percent,
  TabComponent,
} from './styles';
import {
  IPropsClosePrice,
  IPropsDataAndLinks,
  IPropsDataCompany,
} from './@types';
import { HeaderMobile } from './components/HeaderMobile';
import { cores } from '../../../../utils/coresGraficos';

interface IPropsMainCardAnalysisAction {
  clearActive: ({ slug, type }: { slug: string; type: string }) => void;
  setModalFatos: (isShow: boolean) => void;
  companyInfos: IPropsDataCompany;
  closePrice: IPropsClosePrice[];
  setShowExplanationAssetNotes: (isShow: boolean) => void;
  setShowModalAboutCompany: (isShow: boolean) => void;
  setShowProfitXQuotation: (isShow: boolean) => void;
  setModalIndexes: (isShow: boolean) => void;
  setModalCompareAssets: (isShow: boolean) => void;
  dataAndLinks: IPropsDataAndLinks;
}

export function MainCardAnalysisAction({
  clearActive,
  companyInfos,
  closePrice,
  setShowExplanationAssetNotes,
  dataAndLinks,
  setModalFatos,
  setModalCompareAssets,
  setModalIndexes,
  setShowModalAboutCompany,
  setShowProfitXQuotation,
}: IPropsMainCardAnalysisAction) {
  const labels = [
    'Lucro Liquido',
    'EBITDA',
    'Dividendos',
    'Divida Líquida',
    'Divida Líquida EBITDA',
    'Resiliencia',
    'Governança',
    'Índices',
  ];

  const compare = {
    series: [
      companyInfos.LucroLiquido,
      companyInfos.EBITDA,
      companyInfos.Dividendos,
      companyInfos.DividaLiquida,
      companyInfos.DividaLiquidaEbitida,
      companyInfos.Resiliencia,
      companyInfos.Governanca,
      companyInfos.Indices,
    ],
    options: {
      chart: {
        height: 390,
        type: 'donut',
      },
      yaxis: {
        labels: {
          //@ts-ignore
          formatter: (val) => `${val}%`,
        },
      },
      grid: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },

      legend: {
        show: false,
        position: 'bottom',
      },

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              total: {
                show: false,
                label: 'Media',
                // formatter: () => `${active.total_chart.toFixed(0)}%`,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
        //@ts-ignore
        formatter: (val) => `${val.toFixed(0)}%`,
      },
      tooltip: {
        fixed: {
          position: 'bottom',
        },

        y: {
          //@ts-ignore
          formatter: (val) => {
            return `${val.toFixed(0)}%`;
          },
          title: {
            formatter: (titleValue: any) => {
              return titleValue;
            },
          },
        },
        x: {
          formatter: (value: any) => {
            return 'Eixo x do gráfico';
          },
        },
      },

      labels: labels,
      colors: cores,
    },
  };

  return (
    <MainCardContainer>
      {window.screen.width < 909 && (
        <HeaderMobile
          companyInfos={companyInfos}
          setShowExplanationAssetNotes={setShowExplanationAssetNotes}
          clearActive={clearActive}
        />
      )}
      {window.screen.width > 909 && (
        <button
          onClick={() =>
            clearActive({
              slug: '',
              type: '',
            })
          }
          className="backButton"
        >
          <ArrowLeft />
          {companyInfos.NomeEmpresa}
        </button>
      )}
      <ContentTab>
        <TabComponent onClick={() => setShowModalAboutCompany(true)}>
          Sobre {companyInfos.Assets[0]}
        </TabComponent>
        <TabComponent onClick={() => setShowProfitXQuotation(true)}>
          Lucro x Cotação
        </TabComponent>
        <TabComponent onClick={() => setModalIndexes(true)}>
          Índices
        </TabComponent>
        <TabComponent onClick={() => window.open(companyInfos.linkB3)}>
          Link B3
        </TabComponent>
        <TabComponent onClick={() => window.open(companyInfos.ri)}>
          RI
        </TabComponent>
        <TabComponent onClick={() => window.open(companyInfos.site)}>
          Site
        </TabComponent>
        <TabComponent onClick={() => setModalFatos(true)}>
          Fatos relevantes
        </TabComponent>
        {/* <TabComponent onClick={() => setModalCompareAssets(true)}>
          Comparar ativo
        </TabComponent> */}
      </ContentTab>
      <ContentRow>
        {window.screen.width > 909 && (
          <ColMainCard>
            <Tooltip
              title={
                <>
                  <h3 style={{ color: '#fff' }}>Cotação por ticker</h3>

                  {closePrice.map((item: any) => (
                    <p
                      style={{
                        display: 'flex',
                        gap: '0.5rem',
                        margin: '0',
                      }}
                      key={item.asset}
                    >
                      <strong>{item.asset}</strong>
                      {item.priceCost}
                    </p>
                  ))}
                </>
              }
            >
              <ul className="contentAssetsCompany">
                {companyInfos.Assets.map((asset: any) => (
                  <li key={asset}>{asset}</li>
                ))}
              </ul>
            </Tooltip>

            <ContentLogoCompany>
              {companyInfos.LOGO_BRAND ? (
                <img
                  src={companyInfos.LOGO_BRAND}
                  onClick={() =>
                    window.open(
                      `http://www.${dataAndLinks.SITE.replace(
                        'https://',
                        ''
                      ).replace('http://', '')}`
                    )
                  }
                />
              ) : (
                <Buildings size={32} weight="fill" />
              )}
            </ContentLogoCompany>
          </ColMainCard>
        )}

        <ColMainCard>
          <div className="rowContentMainCard">
            <ul>
              <li>
                <span>
                  <HiOutlineChartSquareBar size={'1.2rem'} color="#2469A8" />{' '}
                  Seg. de listagem:
                </span>
                <strong>{companyInfos.SegmentoMercado}</strong>
              </li>
              <li>
                <span>
                  <BsCalendar4Week size={'1.2rem'} color="#2469A8" /> Data de
                  fundação:
                </span>
                <strong>{moment(companyInfos.DT_INI_SIT).format('l')}</strong>
              </li>
              <li>
                <span>
                  <AiOutlineLineChart size={'1.2rem'} color="#2469A8" /> Free
                  float:
                </span>
                <strong>{companyInfos.freeFloat}</strong>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  <HiOutlineOfficeBuilding size={'1.2rem'} color="#2469A8" />{' '}
                  Setor:
                </span>
                <strong>{companyInfos.setor}</strong>
              </li>
              <li>
                <span>
                  <MdEditCalendar size={'1.2rem'} color="#2469A8" /> Tempo em
                  bolsa:
                </span>
                <strong>{`${moment().diff(
                  companyInfos?.DT_INI_SIT,
                  'years'
                )} Anos`}</strong>
              </li>
              <li>
                <span>
                  <AiOutlinePieChart size={'1.2rem'} color="#2469A8" /> Tag
                  along:
                </span>
                <strong>{companyInfos.tagAlong}</strong>
              </li>
            </ul>
          </div>
        </ColMainCard>
        {window.screen.width > 909 && (
          <ColMainCard>
            <Percent onClick={() => setShowExplanationAssetNotes(true)}>
              <ReactApexChart
                //@ts-ignore
                options={compare.options}
                series={compare.series}
                type="donut"
                width={133}
                height={133}
              />
              <Tooltip title="Essa é a nota da empresa com base na média das nossas avaliações. Basta clicar no gráfico para ver a explicação de cada uma das notas">
                <h6>{companyInfos.total_chart.toFixed(0)}%</h6>
              </Tooltip>
            </Percent>
          </ColMainCard>
        )}
      </ContentRow>
    </MainCardContainer>
  );
}
