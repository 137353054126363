import styled from 'styled-components';

export const ContainerJurosCompostos = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  gap: 1.5rem;
  background: ${(props) => props.theme.white};
  width: 100%;
  padding: 0 2.25rem 2rem;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;

export const ContentSimulador = styled.form`
  display: flex;
  flex-direction: column;
  border: 1px solid #d3d3d7;
  border-radius: 8px;
  padding: 1.5rem;
  label {
    color: ${(props) => props.theme['gray-n-600']};
    font-size: 1.125rem;
  }

  .ant-slider-track {
    background-color: #ec6643 !important;
  }
  :where(.css-dev-only-do-not-override-1adbn6x).ant-slider
    .ant-slider-handle::before {
    border-radius: 100%;
    background-color: #ec6643;
  }
  :where(.css-dev-only-do-not-override-1adbn6x).ant-slider
    .ant-slider-handle::after {
    background: transparent !important;
    box-shadow: 0 0 0 2px #ec6643;
  }
`;

export const InputContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    all: unset;
    font-weight: 400;
    color: ${(props) => props.theme['gray-n-400']};
    font-size: 1.625rem;
    width: 100%;
    background: ${(props) => props.theme['orange-50']};
  }
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;
export const MonthlyInputContent = styled.div`
  display: flex;

  align-items: center;
  border-bottom: 1px solid #d3d3d7;

  input {
    all: unset;
    font-weight: 400;
    color: ${(props) => props.theme['gray-n-600']};
    font-size: 1.625rem;
    width: 100%;
    background: ${(props) => props.theme['orange-50']};
  }
  span {
    font-weight: 500;
    color: ${(props) => props.theme['gray-400']};
    font-size: 1.625rem;
    background: ${(props) => props.theme['orange-50']};
  }
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;

export const ContentInputPreFixed = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d3d3d7;
  width: 100%;

  span {
    font-weight: 400;
    color: ${(props) => props.theme['gray-n-500']};
    font-size: 1.625rem;
    background: ${(props) => props.theme['orange-50']};
    padding-right: 0.5rem;
  }
`;

export const ContentButtonValue = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: flex-end;
  button {
    all: unset;
    border-radius: 100%;
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme['primary-800']};
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContentFilterDateAndTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  select {
    padding: 0.5rem;
    color: ${(props) => props.theme['gray-800']};
    font-size: 1rem;
    width: 100%;
    max-width: 7rem;
    position: relative;
    border-radius: 8px;

    border: 1px solid #a0a0a9;
  }
`;

export const ContentDataAndGraphic = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 0.5rem;

  h4 {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 500;
    color: ${(props) => props.theme['navy-700']};
    border-bottom: 1px solid ${(props) => props.theme['gray-n-300']};
  }

  button {
    all: unset;
    border-radius: 100%;
    padding: 0.5rem;
    background-color: ${(props) => props.theme['orange-400']};
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  span {
    font-weight: 400;
    font-size: 1.5rem;
    ${(props) => props.theme['gray-n-600']};
    margin: 0;
  }

  h2 {
    width: 100%;
    margin: 0;
    font-weight: 500;
    font-size: 2.5rem;
    color: ${(props) => props.theme['gray-n-500']};
    border-bottom: 2px solid ${(props) => props.theme['orange-400']};

    strong {
      font-weight: 500;
      color: ${(props) => props.theme['gray-n-400']};
    }
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 1.25rem;
    color: ${(props) => props.theme['gray-n-400']};
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
    strong {
      font-size: 1.25rem;
      font-weight: 500;
      color: ${(props) => props.theme['gray-n-600']};
    }
  }

  .apexcharts-canvas {
    margin-left: -1rem;
  }
`;

interface IPropsCalcButton {
  isOutline?: boolean;
}
export const CalcButton = styled.button<IPropsCalcButton>`
  all: unset;
  color: ${(props) => props.theme['orange-400']};
  border: ${(props) =>
    !props.isOutline && `2px solid  ${props.theme['orange-400']}`};
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
`;
