import { Layout, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useRef, useState } from 'react';
import {
  Calculator,
  ChartBar,
  ChartLine,
  ChatCircleText,
  CurrencyCircleDollar,
  CurrencyDollar,
  GearSix,
  GraduationCap,
  House,
  Info,
  SignOut,
  UserGear,
  Users,
  Wallet,
} from '@phosphor-icons/react';
const { Sider } = Layout;
import { Container, HeaderSideBar } from './sideBarStyles';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { handleToast } from '../../../components/Toast';
import { logout } from '../../../store/actions/AuthActions';
import { whatsAppLinkApi } from '../../../utils/apiWhatsapp';
import { logoBlackImg, smallLogoHeaderImg } from '../../../Constants/images';

export function SideBar({ shortSideBar, setShortSidebar, isMobile }) {
  const hoveredRef = useRef(null);
  const history = useHistory();
  const { title, permissions, setUser } = useTitleAndUser();
  const dispatch = useDispatch();
  const [width, setWidth] = useState(60);

  let path = window.location.pathname;
  path = path.split('/');
  path = path[path.length - 1];

  const navList = [
    {
      label: 'Painel administrativo',
      icon: <UserGear size="1.2rem" />,
      submenu: [
        {
          label: 'Dashboard',
          path: '/admin-painel/dashboard',
        },
        {
          label: 'Feedback',
          path: '/admin-painel/feedback',
        },
        {
          label: 'Dados do usuário',
          path: '/admin-painel/user',
        },
        {
          label: 'Indices',
          path: '/admin-painel/indices',
        },
        // {
        //   label: 'Gestão de planos',
        //   path: '/admin-plan',
        // },
      ],
    },
    {
      label: 'Página inicial',
      icon: <House size="1.2rem" />,
      path: '/dashboard',
    },

    {
      label: 'Análise',
      icon: <ChartBar size="1.2rem" />,
      path: '/analysis',
    },
    {
      label: 'Carteira',
      icon: <Wallet size="1.2rem" />,
      path: '/wallet',
    },
    {
      label: 'Renda passiva',
      icon: <CurrencyCircleDollar size="1.2rem" />,
      path: '/renda-passiva',
    },
    {
      label: 'Simuladores',
      icon: <Calculator size="1.2rem" />,
      path: '/simuladores',
    },
    {
      label: 'Imposto de renda',
      icon: <ChartLine size="1.2rem" />,
      path: '/imposto-renda',
    },
    {
      label: 'Tutoriais',
      icon: <Info size="1.2rem" />,
      path: '/tutoriais',
    },
    {
      label: 'Educação',
      icon: <GraduationCap size="1.2rem" />,
      submenu: [
        {
          label: 'Aulas',
          path: '/Educacao',
        },
        {
          label: 'Gestão de vídeo',
          path: '/MyChannel',
        },
      ],
    },
    {
      label: 'Comunidade',
      icon: <Users size="1.2rem" />,
      path: '/recommended-posts',
    },
    {
      label: 'Assinaturas',
      icon: <CurrencyDollar size="1.2rem" />,
      path: '/pagamentos',
    },
    {
      label: 'Configurações',
      icon: <GearSix size="1.2rem" />,
      path: '/profile-edit',
    },
  ];
  const navListSubChannel = [
    {
      label: 'Página inicial',
      icon: <House size="1.2rem" />,
      path: '/dashboard',
    },

    {
      label: 'Análise',
      icon: <ChartBar size="1.2rem" />,
      path: '/analysis',
    },
    {
      label: 'Carteira',
      icon: <Wallet size="1.2rem" />,
      path: '/wallet',
    },
    {
      label: 'Renda passiva',
      icon: <CurrencyCircleDollar size="1.2rem" />,
      path: '/renda-passiva',
    },
    {
      label: 'Simuladores',
      icon: <Calculator size="1.2rem" />,
      path: '/simuladores',
    },
    {
      label: 'Imposto de renda',
      icon: <ChartLine size="1.2rem" />,
      path: '/imposto-renda',
    },
    {
      label: 'Tutoriais',
      icon: <Info size="1.2rem" />,
      path: '/tutoriais',
    },
    {
      label: 'Educação',
      icon: <GraduationCap size="1.2rem" />,
      path: '/Educacao',
    },
    {
      label: 'Comunidade',
      icon: <Users size="1.2rem" />,
      path: '/recommended-posts',
    },
    {
      label: 'Assinaturas',
      icon: <CurrencyDollar size="1.2rem" />,
      path: '/pagamentos',
    },
    {
      label: 'Configurações',
      icon: <GearSix size="1.2rem" />,
      path: '/profile-edit',
    },
    {
      label: 'Fale conosco',
      icon: <ChatCircleText size="1.2rem" />,
      path: '/suporte',
    },
  ];
  const navListSubChannelShort = [
    {
      icon: <UserGear size="1.2rem" />,
      isAdmin: true,
    },
    {
      icon: <House size="1.2rem" />,
    },
    {
      icon: <ChartBar size="1.2rem" />,
    },
    {
      icon: <Wallet size="1.2rem" />,
    },
    {
      icon: <CurrencyCircleDollar size="1.2rem" />,
    },
    {
      icon: <Calculator size="1.2rem" />,
    },
    {
      icon: <ChartLine size="1.2rem" />,
    },
    {
      icon: <Info size="1.2rem" />,
    },
    {
      icon: <GraduationCap size="1.2rem" />,
    },
    {
      icon: <Users size="1.2rem" />,
    },
    {
      icon: <CurrencyDollar size="1.2rem" />,
    },

    {
      icon: <GearSix size="1.2rem" />,
    },
    {
      icon: <ChatCircleText size="1.2rem" />,
    },
  ];

  const navMap = navList.map((nav, i) => {
    if (!nav.submenu) {
      return {
        key: i.toString(),
        icon: nav.icon,
        label: nav.label,
        onClick: () => history.push(nav.path),
      };
    } else {
      return {
        key: i.toString(),
        icon: nav.icon,
        label: nav.label,
        children: nav.submenu.map((subNav) => {
          if (subNav.block) {
            return {
              key: subNav.path,
              label: subNav.label,
              className: 'block',
              onClick: () =>
                handleToast(true, `${subNav.label} está em desemvolvimento`),
            };
          } else {
            return {
              key: subNav.path,
              label: subNav.label,
              onClick: () => history.push(subNav.path),
            };
          }
        }),
      };
    }
  });

  const navMapNoChannel = navListSubChannel.map((nav, i) => {
    if (!nav.submenu) {
      if (nav.path === '/suporte') {
        return {
          key: i.toString(),
          icon: nav.icon,
          label: nav.label,
          onClick: () => window.open(whatsAppLinkApi),
        };
      }

      return {
        key: i.toString(),
        icon: nav.icon,
        label: nav.label,
        onClick: () => history.push(nav.path),
      };
    } else {
      return {
        key: i.toString(),
        icon: nav.icon,
        label: nav.label,
        children: nav.submenu.map((subNav) => {
          if (subNav.block) {
            return {
              key: subNav.path,
              label: subNav.label,
              className: 'block',
              onClick: () =>
                handleToast(true, `${subNav.label} está em desemvolvimento`),
            };
          } else {
            return {
              key: subNav.path,
              label: subNav.label,
              onClick: () => history.push(subNav.path),
            };
          }
        }),
      };
    }
  });

  const isAdmin = permissions.includes('Admin');

  const navMapNoChannelShort = navListSubChannelShort.map((nav, i) => {
    if (isAdmin && i === 0) {
      return {
        key: i.toString(),
        icon: nav.icon,
      };
    }
    if (!isAdmin && i === 0) {
      return null;
    }
    return {
      key: i.toString(),
      icon: nav.icon,
    };
  });

  const handleHover = () => {
    if (hoveredRef.current) {
      const larguraAtual = hoveredRef.current.offsetWidth;

      setWidth((state) => {
        state = larguraAtual;
        return state;
      });
    }
  };
  const handleUnHover = () => {
    setWidth(60);
  };

  return (
    <>
      <Container
        isOpen={width >= 61}
        title={title}
        isMobile={isMobile}
        onMouseOver={handleHover}
        onMouseLeave={handleUnHover}
        ref={hoveredRef}
        isBlackScreen={window.location.pathname === '/admin-painel/dashboard'}
      >
        <HeaderSideBar>
          {width >= 61 ? (
            <img
              src={logoBlackImg}
              alt="Logo do gif cube"
              onClick={() => history.push('/dashboard')}
              title="Dashboard"
            />
          ) : (
            <img
              src={smallLogoHeaderImg}
              alt="Logo do gif cube"
              onClick={() => history.push('/dashboard')}
              title="Dashboard"
              style={{ width: '2.5rem' }}
            />
          )}
        </HeaderSideBar>
        <Layout>
          {isAdmin ? (
            <Sider width={250} className="site-layout-background">
              <Menu
                mode="inline"
                defaultSelectedKeys={[location.pathname]}
                style={{
                  height: '100%',
                  borderRight: 0,
                  background:
                    window.location.pathname === '/admin-painel/dashboard' &&
                    '#303030',
                  color:
                    window.location.pathname === '/admin-painel/dashboard' &&
                    'white',
                }}
                // items={navMap}
                items={width >= 61 ? navMap : navMapNoChannelShort}
              />
              <br />
            </Sider>
          ) : (
            <Sider width={250} className="site-layout-background">
              <Menu
                mode="inline"
                defaultSelectedKeys={[location.pathname]}
                style={{
                  height: '100%',
                  borderRight: 0,
                }}
                // items={navMapNoChannel}
                items={width >= 61 ? navMapNoChannel : navMapNoChannelShort}
              />
              <br />
            </Sider>
          )}
        </Layout>
        <br />
        <br />
        <br />
        <br />
        <footer>
          <span
            onClick={() => {
              setUser({});
              dispatch(logout(history));
            }}
          >
            {width >= 61 ? (
              <>
                <SignOut size={24} className="logoutIcon" /> Sair
              </>
            ) : (
              <SignOut size={24} className="logoutIcon" />
            )}
          </span>
        </footer>
      </Container>
    </>
  );
}
