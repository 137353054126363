import styled from 'styled-components';

export const ContainerLogin = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
`;
export const AsideContent = styled.aside`
  height: 100vh;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ContentLogin = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 9.187rem;
  }
  h2 {
    color: #40444c;
    text-align: center;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 2.25rem */
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .registerText {
    margin-top: 1.5rem;
    color: #6a717e;

    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    a {
      color: ${(props) => props.theme['orange-500']};
    }
  }
`;

export const ContentForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 28.125rem;
  gap: 1rem;

  @media (max-width: 480px) {
    padding: 1rem;
  }
`;
export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  border-bottom: 1px solid #40444c;
  background-color: ${(props) => props.theme['orange-50']};
  padding: 0.25rem 1rem;
  label {
    width: 100%;
    text-align: start;
    color: #6a717e;

    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  input {
    all: unset;

    width: 100%;
    background-color: ${(props) => props.theme['orange-50']};
    color: #40444c;

    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
      margin-top: -1.5rem;
    }
  }
  &:focus-within {
    border-bottom: 2px solid #ec6643;

    label {
      color: ${(props) =>
        props.theme['orange-500']}; // Exemplo de alteração de cor do label
      font-weight: 400;
    }
  }
`;

export const ButtonRecoveryPassword = styled.button`
  all: unset;
  color: ${(props) => props.theme['orange-500']};
  text-align: right;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
export const Button = styled.button`
  all: unset;
  display: flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.5rem;
  background: ${(props) => props.theme['orange-500']};
  color: #f1f2f3;
  height: 2.5rem;

  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.01094rem;
  text-transform: uppercase;
`;
