import { useEffect, useState } from 'react';
import { Slider } from 'antd';
import Select from 'react-select';
import {
  AsideHeader,
  ButtonShowMoreFilters,
  ContainerAsideFiltersFii,
  ContentInputFilters,
} from './styles';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import axios from 'axios';
import { baseURL } from '../../../../../utils/baseURL';
import { SliderMarks } from 'antd/es/slider';
import { ClearFilterButton } from '../../../../../components/ClearFilterButton/styles';

interface IPropsAsideFiltersFii {
  onSelectedFilters: Function;
  selectedFilters: {
    dy_range: number;
    nome_administrador: string;
    setor: string;
    tipo_gestao: string;
    qtde_ativos_range: number;
    valor_patrimonial_range: number;
    patrimonio_liquido_range: number;
    p_vp_range: number;
    vacancia_range: number;
  };
}

interface IPropsFiltersFii {
  setores: {
    value: string;
    label: string;
  }[];
  administradores: {
    value: string;
    label: string;
  }[];
  tipos_gestao: {
    value: string;
    label: string;
  }[];
}

export function AsideFiltersFii({
  onSelectedFilters,
  selectedFilters,
}: IPropsAsideFiltersFii) {
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [setor, setSetor] = useState(selectedFilters.setor);
  const [gestao, setGestao] = useState(selectedFilters.tipo_gestao);
  const [administradora, setAdministradora] = useState(
    selectedFilters.nome_administrador
  );
  const [type, setType] = useState('');
  const [imoveisAmount, setImoveisAmount] = useState(
    selectedFilters.qtde_ativos_range
  );
  const [dividendYieldValue, setDividendYieldValue] = useState(
    selectedFilters.dy_range
  );
  const [PVPValue, setPVPValue] = useState(selectedFilters.p_vp_range);
  const [vacanciaValue, setVacanciaValue] = useState(
    selectedFilters.vacancia_range
  );
  const [patrimonioLiquidoValue, setPatrimonioLiquidoValue] = useState(() => {
    if (selectedFilters.patrimonio_liquido_range === 10000000) {
      return 32;
    } else if (selectedFilters.patrimonio_liquido_range === 50000000) {
      return 64;
    } else if (selectedFilters.patrimonio_liquido_range === 1000000000) {
      return 96;
    } else if (selectedFilters.patrimonio_liquido_range === 10000000000) {
      return 128;
    } else if (selectedFilters.patrimonio_liquido_range === 100000000000) {
      return 160;
    } else {
      return 0;
    }
  });
  const [valorPatrimonial, setValorPatrimonial] = useState(() => {
    if (selectedFilters.valor_patrimonial_range === 10000000) {
      return 32;
    } else if (selectedFilters.valor_patrimonial_range === 50000000) {
      return 64;
    } else if (selectedFilters.valor_patrimonial_range === 1000000000) {
      return 96;
    } else if (selectedFilters.valor_patrimonial_range === 10000000000) {
      return 128;
    } else if (selectedFilters.valor_patrimonial_range === 100000000000) {
      return 160;
    } else {
      return 0;
    }
  });
  const [allFilters, setAllFilters] = useState<IPropsFiltersFii>({
    administradores: [],
    setores: [],
    tipos_gestao: [],
  });

  function editValueFilter(val: number) {
    if (val === 32) {
      return 10000000;
    }

    if (val === 64) {
      return 50000000;
    }
    if (val === 96) {
      return 1000000000;
    }
    if (val === 128) {
      return 10000000000;
    }

    if (val === 160) {
      return 100000000000;
    }
    return 0;
  }

  useEffect(() => {
    onSelectedFilters({
      ...selectedFilters,
      dy_range: dividendYieldValue,
      nome_administrador: administradora,
      setor: setor,
      tipo_gestao: gestao,
      qtde_ativos_range: imoveisAmount,
      valor_patrimonial_range: editValueFilter(valorPatrimonial),
      patrimonio_liquido_range: editValueFilter(patrimonioLiquidoValue),
      p_vp_range: PVPValue,
      vacancia_range: vacanciaValue / 100,
      tipofii: type,
    });
  }, [
    setor,
    gestao,
    administradora,
    type,
    imoveisAmount,
    dividendYieldValue,
    PVPValue,
    vacanciaValue,
    patrimonioLiquidoValue,
    valorPatrimonial,
  ]);

  useEffect(() => {
    getListFilters();
  }, []);

  const returnSelectValue = (
    id: string,
    arr: {
      value: string;
      label: string;
    }[]
  ) => {
    if (id) {
      let obj = arr?.map((item) => {
        if (item?.value === id) {
          return {
            value: item.value,
            label: item.label,
          };
        }
      });
      return obj;
    } else {
      return null;
    }
  };

  const arrSegList = [
    {
      value: '',
      label: 'Todos',
    },
    {
      value: 'Papel',
      label: 'Papel',
    },
    {
      value: 'Tijolo',
      label: 'Tijolo',
    },
  ];

  function handleChangeImoveisFilter(value: number) {
    setImoveisAmount(value);
  }
  function handleChangeDividendYieldFilter(value: number) {
    setDividendYieldValue(value);
  }
  function handleChangePVPFilter(value: number) {
    setPVPValue(value);
  }
  function handleChangeVacanciaFilter(value: number) {
    setVacanciaValue(value);
  }
  function handleChangePatrimonioLiquidoFilter(value: number) {
    setPatrimonioLiquidoValue(value);
  }
  function handleChangeValorPatrimonialFilter(value: number) {
    setValorPatrimonial(value);
  }

  async function getListFilters() {
    try {
      const response = await axios.get(`${baseURL}/Finance/fiis-filtros`);
      setAllFilters({
        administradores: [
          {
            value: '',
            label: 'Todos',
          },
          ...response.data.administradores
            .map((item: string) => {
              return {
                value: item,
                label: `${item.substring(0, 1).toUpperCase()}${item
                  .substring(1)
                  .toLowerCase()}`,
              };
            })
            .sort((a: any, b: any) => a.label.localeCompare(b.label)),
        ],
        setores: [
          {
            value: '',
            label: 'Todos',
          },
          ...response.data.setores
            .map((item: string) => {
              return {
                value: item,
                label: `${item.substring(0, 1).toUpperCase()}${item
                  .substring(1)
                  .toLowerCase()}`,
              };
            })
            .sort((a: any, b: any) => a.label.localeCompare(b.label))
            .filter((item: any) => item.label !== 'Tipo de gestão:'),
        ],
        tipos_gestao: [
          {
            value: '',
            label: 'Todos',
          },
          ...response.data.tipos_gestao
            .map((item: string) => {
              return {
                value: item,
                label: `${item.substring(0, 1).toUpperCase()}${item
                  .substring(1)
                  .toLowerCase()}`,
              };
            })
            .sort((a: any, b: any) => a.label.localeCompare(b.label)),
        ],
      });
    } catch (error) {}
  }

  const marksMobile: SliderMarks = {
    0: '0',
    32: '10mi',
    64: '500mi',
    96: '1bi',
    128: '10bi',
    160: {
      style: {
        transform: 'translateX(-110%)',
      },
      label: '100bi',
    },
  };

  const tooltip = {
    formatter: (val: number) => {
      if (val === 0) {
        return `0`;
      }

      if (val === 32) {
        return 'Acima de 10 milhões';
      }

      if (val === 64) {
        return 'Acima de 500 milhões';
      }
      if (val === 96) {
        return 'Acima de 1 bilhão';
      }
      if (val === 128) {
        return 'Acima de 10 bilhões';
      }

      if (val === 160) {
        return 'Acima de 100 bilhões';
      }
    },
  };

  return (
    <ContainerAsideFiltersFii>
      <AsideHeader>
        <span>Filtros</span>
        <ClearFilterButton
          type="button"
          onClick={() => {
            onSelectedFilters({
              dy_range: 0,
              nome_administrador: '',
              setor: '',
              tipo_gestao: '',
              qtde_ativos_range: 0,
              valor_patrimonial_range: 0,
              patrimonio_liquido_range: 0,
              p_vp_range: 0,
              vacancia_range: 0,
              nome_ou_codigo: '',
              tipofii: '',
            });
            setSetor('');
            setGestao('');
            setAdministradora('');
            setType('');
            setImoveisAmount(0);
            setDividendYieldValue(0);
            setPVPValue(0);
            setVacanciaValue(0);
            setPatrimonioLiquidoValue(0);
            setValorPatrimonial(0);
          }}
        >
          Limpar filtros
        </ClearFilterButton>
      </AsideHeader>
      <ContentInputFilters>
        <label>Setor</label>
        <Select
          className="select"
          options={allFilters.setores}
          //@ts-ignore
          onChange={(e) => {
            //@ts-ignore
            setSetor(e.value);
          }}
          value={returnSelectValue(setor, allFilters.setores)}
          placeholder="Todos"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? '#0176E1' : 'transparent',
              borderRadius: 8,
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              boxShadow: ' 2px 2px 6px 0px #0000001A',
              borderRadius: 8,
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              color: '#808080',
              fontSize: 16,
            }),
            placeholder: (base) => ({
              ...base,
            }),
            valueContainer: (base) => ({
              ...base,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 8,
              paddingBottom: 8,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),

            dropdownIndicator: (base) => ({
              ...base,
              color: '#808080',
            }),
          }}
        />
      </ContentInputFilters>
      <ContentInputFilters>
        <label>Gestão</label>
        <Select
          className="select"
          options={allFilters.tipos_gestao}
          //@ts-ignore
          onChange={(e) => {
            //@ts-ignore
            setGestao(e.value);
          }}
          value={returnSelectValue(gestao, allFilters.tipos_gestao)}
          placeholder="Todos"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? '#0176E1' : 'transparent',
              borderRadius: 8,
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              boxShadow: ' 2px 2px 6px 0px #0000001A',
              borderRadius: 8,
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              color: '#808080',
              fontSize: 16,
            }),
            placeholder: (base) => ({
              ...base,
            }),
            valueContainer: (base) => ({
              ...base,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 8,
              paddingBottom: 8,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),

            dropdownIndicator: (base) => ({
              ...base,
              color: '#808080',
            }),
          }}
        />
      </ContentInputFilters>
      <ContentInputFilters>
        <label>Administradora</label>
        <Select
          className="select"
          options={allFilters.administradores}
          //@ts-ignore
          onChange={(e) => {
            //@ts-ignore
            setAdministradora(e.value);
          }}
          value={returnSelectValue(administradora, allFilters.administradores)}
          placeholder="Todos"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? '#0176E1' : 'transparent',
              borderRadius: 8,
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              boxShadow: ' 2px 2px 6px 0px #0000001A',
              borderRadius: 8,
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              color: '#808080',
              fontSize: 16,
            }),
            placeholder: (base) => ({
              ...base,
            }),
            valueContainer: (base) => ({
              ...base,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 8,
              paddingBottom: 8,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),

            dropdownIndicator: (base) => ({
              ...base,
              color: '#808080',
            }),
          }}
        />
      </ContentInputFilters>
      <ContentInputFilters>
        <label>Tipo de fundo</label>
        <Select
          className="select"
          options={arrSegList}
          //@ts-ignore
          onChange={(e) => {
            //@ts-ignore
            setType(e.value);
          }}
          value={returnSelectValue(type, arrSegList)}
          placeholder="Todos"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? '#0176E1' : 'transparent',
              borderRadius: 8,
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              boxShadow: ' 2px 2px 6px 0px #0000001A',
              borderRadius: 8,
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              color: '#808080',
              fontSize: 16,
            }),
            placeholder: (base) => ({
              ...base,
            }),
            valueContainer: (base) => ({
              ...base,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 8,
              paddingBottom: 8,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),

            dropdownIndicator: (base) => ({
              ...base,
              color: '#808080',
            }),
          }}
        />
      </ContentInputFilters>
      <ContentInputFilters>
        <label>Imóveis</label>
        <Slider
          onChange={handleChangeImoveisFilter}
          value={typeof imoveisAmount === 'number' ? imoveisAmount : 0}
          className="slider"
          step={1}
          min={0}
          max={100}
        />
        <span>Acima de {imoveisAmount}</span>
      </ContentInputFilters>

      {showAllFilters && (
        <>
          <ContentInputFilters>
            <label>Dividend Yield/DY</label>
            <Slider
              onChange={handleChangeDividendYieldFilter}
              value={
                typeof dividendYieldValue === 'number' ? dividendYieldValue : 0
              }
              className="slider"
              step={0.01}
              min={0}
              max={20}
            />
            <span>Acima de {dividendYieldValue}%</span>
          </ContentInputFilters>
          <ContentInputFilters>
            <label>P/VP</label>
            <Slider
              onChange={handleChangePVPFilter}
              value={typeof PVPValue === 'number' ? PVPValue : 0}
              className="slider"
              step={0.1}
              min={0}
              max={3}
            />
            <span>Acima de {PVPValue}</span>
          </ContentInputFilters>
          <ContentInputFilters>
            <label>Vacância</label>
            <Slider
              onChange={handleChangeVacanciaFilter}
              value={typeof vacanciaValue === 'number' ? vacanciaValue : 0}
              className="slider"
              step={1}
              min={0}
              max={100}
            />
            <span>Abaixo de {vacanciaValue}%</span>
          </ContentInputFilters>
          <ContentInputFilters>
            <label>Patrimonio liquido</label>
            <Slider
              onChange={handleChangePatrimonioLiquidoFilter}
              value={
                typeof patrimonioLiquidoValue === 'number'
                  ? patrimonioLiquidoValue
                  : 0
              }
              className="slider"
              step={null}
              max={160}
              // @ts-ignore
              tooltip={tooltip}
              marks={marksMobile}
            />
            <span>Acima de {tooltip.formatter(patrimonioLiquidoValue)}</span>
          </ContentInputFilters>
          <ContentInputFilters>
            <label>Valor patrimonial</label>
            <Slider
              onChange={handleChangeValorPatrimonialFilter}
              value={
                typeof valorPatrimonial === 'number' ? valorPatrimonial : 0
              }
              className="slider"
              max={160}
              // @ts-ignore
              tooltip={tooltip}
              marks={marksMobile}
              step={null}
            />
            <span>Acima de {tooltip.formatter(valorPatrimonial)}</span>
          </ContentInputFilters>
        </>
      )}

      <ButtonShowMoreFilters
        onClick={() => setShowAllFilters(!showAllFilters)}
        type="button"
      >
        {showAllFilters ? 'Esconder filtros' : ' Mostrar mais filtros'}
        {showAllFilters ? (
          <CaretUp weight="bold" size={24} />
        ) : (
          <CaretDown weight="bold" size={24} />
        )}
      </ButtonShowMoreFilters>
    </ContainerAsideFiltersFii>
  );
}
