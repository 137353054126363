import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .modal-content {
    border-radius: 8px !important;
  }
`;
export const RowContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-start;
  width: 100%;
`;
export const ColContent = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
  padding: 1rem;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow']};
  min-width: 400px;

  header {
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 1.5rem;
    }
  }

  .linkB3 {
    cursor: pointer;
    transition: color 0.3s;
    :hover {
      color: ${(props) => props.theme.primary};
    }
  }

  .contentSwitch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;
      font-size: 1rem;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    div {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 0.2rem;
      }

      input {
        padding: 0.5rem;
        border: ${(props) => props.theme.border};
        outline: ${(props) => props.theme.primary};
        border-radius: 4px;
        transition: all 0.3s;

        :disabled {
          background-color: rgba(21, 21, 21, 0.1);
          cursor: no-drop;
          :hover {
            box-shadow: 0 0 10px 0 rgba(255, 0, 0, 0.5);
            color: #6c757d;
          }
        }

        :hover {
          box-shadow: 0 0 10px 0 ${(props) => props.theme.primary};
          color: ${(props) => props.theme.black};
        }
        :focus {
          box-shadow: 0 0 10px 0 ${(props) => props.theme.primary};
          color: ${(props) => props.theme.black};
        }
      }
      .inputContent {
        position: relative;
        .password {
          padding-right: 2rem;
        }
        i {
          position: absolute;
          right: 7px;
          top: 7px;
          cursor: pointer;
        }
      }
    }
    div + div {
      margin-top: 1rem;
    }
  }

  @media (max-width: 430px) {
    margin-right: 1rem;

    min-width: 325px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const RowContentForm = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1rem;
  width: 100%;
  align-items: start;
`;

export const ButtonDeleteAccount = styled.button`
  all: unset;
  border-radius: 12px;
  background: #ff5353;
  padding: 1rem;
  width: calc(100% - 32px);
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) => props.theme.white};
`;

export const RowHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  @media (max-width: 816px) {
    flex-wrap: wrap;
  }
`;
export const CardContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  padding: 1rem;
  background: ${(props) => props.theme.white};
  border-radius: 8px;

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    h1 {
      margin: 0;
      font-weight: 500;
      font-size: 1.25rem;
      color: ${(props) => props.theme['gray-500']};
    }
  }

  p {
    margin: 0;
    font-size: 0.875rem;
    color: ${(props) => props.theme['gray-100']};
  }

  .contentButton {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    @media (max-width: 1004px) {
      flex-direction: column;
    }
  }
`;

export const LinkButton = styled.button`
  all: unset;
  border-radius: 38px;
  border: 1px solid ${(props) => props.theme['primary-800']};
  padding: 0.5rem 1rem;
  color: ${(props) => props.theme['primary-800']};
  font-weight: 500;
  @media (max-width: 1004px) {
    text-align: center;
  }
`;
