import moment from 'moment';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useActives } from '../../../../../hooks/useActives';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';

import { GraphicContainer } from './styles';
import { ModalDesigner } from '../../../../../components/ModalDesigner';

interface IRentabilidade {
  referenceDate: Date;
  value: number;
}

interface IPropsRentabilidadeGraphic {
  title: string;
  rentabilidade: IRentabilidade[];
}

export function Rentabilidade({
  title,
  rentabilidade,
}: IPropsRentabilidadeGraphic) {
  //@ts-ignore
  const { showActive } = useActives();
  const [showModalGraphic, setShowModalGraphic] = useState<boolean>(false);

  const compare = {
    series: [
      {
        data: rentabilidade.map((item) => Number(item.value.toFixed(2))) ?? [0],
      },
    ],
    options: {
      chart: {
        type: 'area',
        height: 350,
      },

      yaxis: {
        labels: {
          formatter: function (value: any) {
            if (showActive) {
              return transformMaskCurrency(value);
            } else {
              return 'R$ *****';
            }
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            if (showActive) {
              return transformMaskCurrency(val);
            } else {
              return 'R$ *****';
            }
          },
        },
      },

      dataLabels: {
        enabled: false,
      },

      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },

      colors: [
        rentabilidade[0]?.value < rentabilidade[rentabilidade.length - 1]?.value
          ? '#52C41A'
          : rentabilidade[0]?.value ===
            rentabilidade[rentabilidade.length - 1]?.value
          ? 'var(--primary)'
          : '#C61722',
      ],
      labels: rentabilidade.map(
        (item) => moment(item.referenceDate).format('l') ?? moment().format('l')
      ),
    },
  };

  return (
    <>
      <GraphicContainer onClick={() => setShowModalGraphic(true)}>
        <strong>{title}</strong>
        {showActive ? (
          <ReactApexChart
            //@ts-ignore
            options={compare.options}
            series={compare.series}
            type="area"
            height={220}
          />
        ) : (
          <ReactApexChart
            //@ts-ignore
            options={compare.options}
            series={compare.series}
            type="area"
            height={220}
          />
        )}
      </GraphicContainer>
      <ModalDesigner
        isOpen={showModalGraphic}
        setState={setShowModalGraphic}
        size="lg"
        id="graphic"
      >
        <ReactApexChart
          //@ts-ignore
          options={compare.options}
          series={compare.series}
          type="area"
        />
      </ModalDesigner>
    </>
  );
}
