import { useState } from 'react';
import { Check } from '@phosphor-icons/react';
import { useHistory } from 'react-router-dom';
import { useTitleAndUser } from '../../hooks/userTitle';
import { Container } from './styles';
import { LinkAccountB3 } from '../../jsx/components/Dashboard/Settings/LinkAccountB3';
import { ButtonDesign } from '../ButtonDesign';
import { ModalDesigner } from '../ModalDesigner';

export function LinkB3Card() {
  //@ts-ignore
  const { user } = useTitleAndUser();
  const history = useHistory();
  const [modalLinkAccountB3, setModalLinkAccountB3] = useState<boolean>(false);

  const benefitsOfConnectingTithB3 = [
    'Movimentações a partir de 11/2019',
    'Evolução da carteira patrimonial',
    'Calendário de Proventos da Carteira',
    'Acompanhamento das posições diariamente',
  ];

  return (
    <>
      <Container>
        <header>
          <h1>
            {user.carteira !== 'automatico'
              ? 'Integração disponível'
              : 'Você está integrado!'}
          </h1>
        </header>
        <main>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/d/d7/B3_logo.png"
            alt="logo b3"
          />
          <ul>
            {benefitsOfConnectingTithB3.map((item) => (
              <li key={item}>
                <Check
                  size={16}
                  color="#00BBF4
"
                />
                {item}
              </li>
            ))}
          </ul>
          <div className="buttonContent">
            {user.carteira !== 'automatico' ? (
              <ButtonDesign onClick={() => setModalLinkAccountB3(true)}>
                Conectar conta
              </ButtonDesign>
            ) : (
              <ButtonDesign
                onClick={() => history.push('/my-wallet')}
                isOutline
              >
                Visualizar carteira
              </ButtonDesign>
            )}
          </div>
        </main>
      </Container>
      <ModalDesigner
        id="b3Link"
        isOpen={modalLinkAccountB3}
        setState={setModalLinkAccountB3}
        title="Vincular conta B3"
        size="sm"
      >
        <LinkAccountB3 onCloseModal={setModalLinkAccountB3} />
      </ModalDesigner>
    </>
  );
}
