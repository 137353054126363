import { useDispatch } from 'react-redux';
import { Eye, EyeClosed } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';

import ReactLoading from 'react-loading';
import { useForm } from 'react-hook-form';

import {
  loadingToggleAction,
  loginAction,
} from '../../store/actions/AuthActions';
import { handleToast } from '../../components/Toast';
import { FormRecoveryPassword } from '../components/FormRecoveryPassword';
import {
  AsideContent,
  ButtonRecoveryPassword,
  ContainerLogin,
  ContentForm,
  ContentInput,
  ContentLogin,
} from './LoginStyles';

import asideImg from '../../assets/loginAside.svg';
import logoImg from '../../assets/logo/logoOrange.png';
import { ButtonStyle } from '../../components/ButtonStyle';

export default function Login(props) {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, watch } = useForm();
  const [viewPassword, setViewPassword] = useState(false);
  const [modalRecoveryPassword, setModalRecoveryPassword] = useState({
    open: false,
    success: false,
  });
  const [loading, setLoading] = useState(false);

  const paramsTraffic = window.location.search
    .substr(1)
    .split('&')
    .reduce((queryParams, param) => {
      const [key, value] = param.split('=');
      queryParams[key] = value;
      return queryParams;
    }, {});

  useEffect(() => {
    if (paramsTraffic.existing_code) {
      dispatch(loadingToggleAction(true));
      dispatch(
        loginAction({
          token: paramsTraffic.existing_code,
          history: props.history,
          setLoading,
          email: '',
          password: '',
          register: false,
          registerBusiness: true,
        })
      );
    } else if (paramsTraffic.email) {
      setValue('email', paramsTraffic.email);
      setValue('password', '@ibox2022');
      dispatch(loadingToggleAction(true));
      dispatch(
        loginAction(
          paramsTraffic.email,
          '@ibox2022',
          props.history,
          false,
          setLoading
        )
      );
    }
  }, [paramsTraffic]);

  const email = watch('email');

  async function onLogin(data) {
    setLoading(true);
    if (!data.email) {
      setLoading(false);

      return handleToast(true, 'Email obrigatório');
    }
    data.email = data.email.toLowerCase();
    if (!data.password) {
      setLoading(false);

      return handleToast(true, 'Senha obrigatório');
    }

    dispatch(loadingToggleAction(true));
    dispatch(
      loginAction({
        email: data.email,
        password: data.password,
        history: props.history,
        register: false,
        setLoading,
      })
    );
  }

  return (
    <ContainerLogin>
      <AsideContent>
        <img src={asideImg} />
      </AsideContent>

      <ContentLogin>
        <img src={logoImg} alt="logo" />
        <h2>
          {modalRecoveryPassword.success
            ? 'Confira seu e-mail'
            : modalRecoveryPassword.open
            ? 'Auxílio de senha'
            : 'Acesse sua conta'}
        </h2>
        {modalRecoveryPassword.open ? (
          <FormRecoveryPassword email={email} setModalRecoveryPassword={setModalRecoveryPassword}/>
        ) : (
          <ContentForm onSubmit={handleSubmit(onLogin)}>
            <ContentInput>
              <label>E-mail *</label>
              <input
                type="text"
                placeholder="johndoe@example.com"
                {...register('email')}
              />
            </ContentInput>
            <ContentInput>
              <label>Senha *</label>

              <div>
                <input
                  type={viewPassword ? 'text' : 'password'}
                  placeholder="*********"
                  {...register('password')}
                />
                <i>
                  {viewPassword ? (
                    <Eye
                      onClick={() => setViewPassword(!viewPassword)}
                      size={24}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <EyeClosed
                      onClick={() => setViewPassword(!viewPassword)}
                      size={24}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </i>
              </div>
            </ContentInput>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <ButtonRecoveryPassword
                onClick={() =>
                  setModalRecoveryPassword({
                    ...modalRecoveryPassword,
                    open: true,
                  })
                }
                type="button"
              >
                Esqueceu a senha?
              </ButtonRecoveryPassword>
            </div>

            {loading ? (
              <ButtonStyle
                disabled={loading}
                background="#EC6643"
                color="#fff"
                type="submit"
              >
                <ReactLoading
                  type="balls"
                  color="#FFF"
                  width="2rem"
                  height="2rem"
                />
              </ButtonStyle>
            ) : (
              <ButtonStyle
                disabled={loading}
                background="#EC6643"
                color="#fff"
                type="submit"
              >
                ACESSE
              </ButtonStyle>
            )}
          </ContentForm>
        )}
        {!modalRecoveryPassword.open && (
          <span className="registerText">
            Ainda não tem uma conta? <a href="page-register">Cadastrar</a>
          </span>
        )}
      </ContentLogin>

      {/* <ModalDesigner
        id="recoverPassword"
        isOpen={modalRecoveryPassword}
        setState={setModalRecoveryPassword}
        title="Login a partir de e-mail ou número de telefone"
        size="sm"
      >
        <FormRecoveryPassword email={email} />
      </ModalDesigner> */}
    </ContainerLogin>
  );
}
