import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  transition: margin 1s;
  max-width: 1440px;
  margin: 0 auto;

  /* @media (max-width: 820px) {
    margin-left: -3rem;
  }
  @media (max-width: 767px) {
    margin-left: 5rem;
  } */
`;

export const ContentFilterSearchVideo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: ${(props) => props.theme.white};
  padding: 10px;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  margin-bottom: 1rem;

  .selected {
    color: ${(props) => props.theme.primary};
    font-weight: 700;
  }
  span {
    color: ${(props) => props.theme.white};
  }
  .z-0 {
    z-index: 0;
  }
`;

export const ContainerVideos = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  position: relative;
  z-index: 1;

  footer {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    p {
      display: flex;
      gap: 10px;
      cursor: pointer;

      font-weight: 500;
      color: ${(props) => props.theme.primary};
      font-size: 1.2rem;
      align-items: center;
    }
  }
`;
export const RowDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const ContentVideo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  box-shadow: ${(props) => props.theme['card-shadow']};

  border-radius: 8px;
  width: 240px;
  height: 275px;
  object-fit: cover;

  h4 {
    max-width: 10ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span {
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ThumbContent = styled.div`
  display: flex;

  width: 100%;
  width: 240px;
  height: 139.98px;
  img {
    object-fit: cover;
    border-radius: 10px 10px 0 0;

    width: 100%;
    height: 100%;
  }
`;
export const ContentChannelInfos = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  margin-top: 10px;
  width: 100%;
  svg {
    width: 1rem;
    height: 1rem;
  }

  a {
    padding: 0;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    align-items: center;
    gap: 10px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: 700;
    color: ${(props) => props.theme.primary};
  }
`;

export const Divider = styled.div`
  border-bottom: rgba(0, 0, 0, 0.5) 1px solid;
  max-width: 380px;
  position: relative;
  z-index: 1;

  h2 {
    font: 500 1.5rem 'Poppins', sans-serif;

    color: black;
  }
`;

export const ShowLess = styled.span`
  margin-top: 1rem;
  cursor: pointer;
  transform: color 0.3s;
  :hover {
    color: ${(props) => props.theme.primary};
  }
`;
