import styled from 'styled-components';

export const AsideFiltersProventosRecebidosContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
  border-radius: 8px;
  width: 100%;
  max-width: 18.75rem;
  padding: 1rem;
  gap: 0.5rem;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    margin: 0;
    font-weight: 400;
    font-size: 0.875rem;
  }

  .inputDate {
    border: 1px solid transparent;
    border-radius: 8px;
    box-shadow: 2px 2px 6px 0px #0000001a;
    padding: 1rem;
    font-weight: 400;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-200']};
  }

  .radioContent {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
  }

  .inputRadio {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
`;
