import ReactApexChart from 'react-apexcharts';
import { ChartDonut, ChartLineUp } from '@phosphor-icons/react';
import { useState } from 'react';
import moment from 'moment';
import { useActives } from '../../../../../hooks/useActives';

import { useActivesWallet } from '../../hooks/ActivesHooks';
import {
  ButtonChangeGraphic,
  CompositionGraphicWalletContainer,
  ContentButtonChangeGraphic,
  ContentGraphic,
  ContentListTitle,
} from './styles';
import { GraphicWalletActives } from '../../../../components/Boltz/MyWallet/Graphic';

interface IPropsCompositionGraphicWallet {
  tab: number;
}

export function CompositionGraphicWallet({
  tab,
}: IPropsCompositionGraphicWallet) {
  const { fullWallet, othersFii, othersAction } = useActivesWallet();
  const { rentabilidade } = fullWallet;
  // @ts-ignore
  const { percentage, showActive } = useActives();
  const [typeGraphic, setTypeGraphic] = useState<'wallet' | 'rentability'>(
    'wallet'
  );

  const dataGraphicRentabilidade = {
    series: [
      {
        name: 'Patrimônio',
        data: rentabilidade?.map((rent) => rent.value) ?? { data: [0] },
      },
    ],
    options: {
      chart: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        curve: 'smooth',
      },

      grid: {
        padding: {
          bottom: 0,
          top: 0,
          right: 0,
          left: 0,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      theme: {
        mode: 'light',
        monochrome: {
          enabled: true,
          color:
            rentabilidade &&
            rentabilidade.length >= 1 &&
            rentabilidade[rentabilidade?.length - 1].value >
              rentabilidade[0]?.value
              ? '#006AAC'
              : '#f11414',
        },
      },
      yaxis: {
        labels: {
          formatter: (val: number) =>
            new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(val),
        },
      },
      colors:
        rentabilidade && rentabilidade.length >= 1
          ? [
              rentabilidade[rentabilidade?.length - 1].value >
              rentabilidade[0]?.value
                ? '#006AAC'
                : 'red',
            ]
          : ['green'],

      labels: rentabilidade?.map((rent) =>
        moment(rent.referenceDate).format('l')
      ) ?? [''],
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200,
      //       },
      //       legend: {
      //         position: 'bottom',
      //       },
      //     },
      //   },
      // ],
    },
  };
  const dataGraphicRentabilidadeNotShowValue = {
    series: [
      {
        name: 'Patrimônio',
        data: rentabilidade?.map((rent) => rent.value) ?? { data: [0] },
      },
    ],
    options: {
      chart: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        curve: 'smooth',
      },

      grid: {
        padding: {
          bottom: 0,
          top: 0,
          right: 0,
          left: 0,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      theme: {
        mode: 'light',
        monochrome: {
          enabled: true,
          color:
            rentabilidade &&
            rentabilidade.length >= 1 &&
            rentabilidade[rentabilidade?.length - 1].value >
              rentabilidade[0]?.value
              ? '#006AAC'
              : '#f11414',
        },
      },
      yaxis: {
        labels: {
          formatter: (val: number) => 'R$ *******',
        },
      },
      colors:
        rentabilidade && rentabilidade.length >= 1
          ? [
              rentabilidade[rentabilidade?.length - 1].value >
              rentabilidade[0]?.value
                ? '#006AAC'
                : 'red',
            ]
          : ['green'],

      labels: rentabilidade?.map((rent) =>
        moment(rent.referenceDate).format('l')
      ) ?? [''],
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200,
      //       },
      //       legend: {
      //         position: 'bottom',
      //       },
      //     },
      //   },
      // ],
    },
  };
  const dataGraphic = {
    series: [percentage.fii, percentage.action, percentage.rendaFixa],
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -10,
          },
        },
      },
      grid: {
        padding: {
          bottom: 0,
          top: 0,
          right: 0,
          left: 0,
        },
      },
      colors: ['#006AAC', '#ffb900', '#FF8B4B'],
      legend: {
        show: false,
      },
      labels: ['FIIs', 'Ações', 'Renda fixa'],
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200,
      //       },
      //       legend: {
      //         position: 'bottom',
      //       },
      //     },
      //   },
      // ],
    },
  };

  return (
    <CompositionGraphicWalletContainer>
      <header>
        <strong>
          {typeGraphic === 'wallet'
            ? 'Composição da carteira'
            : 'Evolução do patrimônio'}
        </strong>
        <ContentButtonChangeGraphic>
          <ButtonChangeGraphic
            type="button"
            isActive={typeGraphic === 'wallet'}
            onClick={() => setTypeGraphic('wallet')}
          >
            <ChartDonut />
          </ButtonChangeGraphic>
          <ButtonChangeGraphic
            type="button"
            isActive={typeGraphic === 'rentability'}
            onClick={() => setTypeGraphic('rentability')}
          >
            <ChartLineUp />
          </ButtonChangeGraphic>
        </ContentButtonChangeGraphic>
      </header>
      {typeGraphic === 'rentability' && (
        <ReactApexChart
          // @ts-ignore
          options={
            showActive
              ? dataGraphicRentabilidade.options
              : dataGraphicRentabilidadeNotShowValue.options
          }
          series={dataGraphicRentabilidade.series}
          type="area"
          height={300}
        />
      )}
      {typeGraphic === 'wallet' && tab !== 3 && tab !== 2 && (
        <ContentGraphic>
          <ReactApexChart
            // @ts-ignore
            options={dataGraphic.options}
            series={dataGraphic.series}
            type="pie"
            width={200}
          />
          <ul>
            <li>
              <ContentListTitle color="#ffb900">Ações</ContentListTitle>
              <strong>{percentage.action}%</strong>
            </li>
            <li>
              <ContentListTitle color="#006AAC">FIIs</ContentListTitle>
              <strong>{percentage.fii}%</strong>
            </li>
            <li>
              <ContentListTitle color="#FF8B4B">Renda fixa</ContentListTitle>
              <strong>{percentage.rendaFixa}%</strong>
            </li>
          </ul>
        </ContentGraphic>
      )}
      {typeGraphic === 'wallet' && tab === 3 && (
        <GraphicWalletActives
          active="fii"
          title="Distribuição por ativo"
          othersFii={othersFii}
        />
      )}
      {typeGraphic === 'wallet' && tab === 2 && (
        <GraphicWalletActives
          active="action"
          othersAction={othersAction}
          title="Distribuição por ativo"
        />
      )}
    </CompositionGraphicWalletContainer>
  );
}
