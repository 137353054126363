import axios from 'axios';
import { useForm } from 'react-hook-form';
import { ArrowUp, Info } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

import { Comment } from '../../../components/Comment';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { socialRoutes } from '../../../utils/RoutesApi';
import { IPropsComment } from '../../../utils/@types/comment';
import { handleToast } from '../../../components/Toast';
import LoadingSpinner from '../../../jsx/components/Finance/LoadingSpinner';
import { InfinityScroll } from '../../../components/InfinityScroll';
import { CardProfileUser } from '../../../jsx/components/CardProfileUser';
import { ButtonComponent } from '../../../components/ButtonComponent';
import { FeedLoader } from '../../../jsx/components/LoadingModels';
import {
  BackToTop,
  CardZ1,
  Col,
  Comments,
  Container,
  NewComment,
  RowButtonComment,
  RowComment,
  TabContent,
  WarningNewMessage,
} from './styles';
import { whatsAppLinkApi } from '../../../utils/apiWhatsapp';

interface IComment {
  count: number;
  next: string;
  previous: string;
  results: IPropsComment[];
  responseComments: IPropsComment[];
}

interface IPropsTreendTopics {
  text: string;
  value: number;
}

function RecommendedPosts() {
  //@ts-ignore
  const { user, setTitle } = useTitleAndUser();
  const { register, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [newLoading, setNewLoading] = useState<boolean>(false);
  const [treendTopics, setTreendTopics] = useState<IPropsTreendTopics[]>([]);
  const [selected, setSelected] = useState<string>('');

  const [comments, setComments] = useState<IComment>({
    count: 0,
    next: '',
    previous: '',
    results: [],
    responseComments: [],
  });

  useEffect(() => {
    if (selected === '') {
      handleComments();
    } else {
      handleComments({ hashtag: selected });
    }
  }, [selected]);

  useEffect(() => {
    handleMostWords();
    setTitle('Feed');
  }, []);

  async function handleMostWords() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}${socialRoutes.SOCIAL_MOST_WORLDS}`)
      .then((response) => {
        setTreendTopics(response.data.results);
      })
      .catch((error) => {});
  }

  async function handleComments(params?: object) {
    setLoading(true);
    setNewLoading(false);
    await axios
      .get(
        selected !== user.slug
          ? `${process.env.REACT_APP_API_URL}${socialRoutes.SOCIAL_GET_COMMENTS_HASHTAG}`
          : `${process.env.REACT_APP_API_URL}/social/api/posts/profile/${user.slug}/posts/`,
        { params }
      )
      .then((response) => {
        let responseComments = response.data.results.filter(
          (comment: IPropsComment) => comment.parent !== null
        );

        let res = response.data.results.filter(
          (comment: IPropsComment) => comment.parent === null
        );

        res = res.map((resp: IPropsComment) => {
          return {
            ...resp,
            responseComment: responseComments.filter(
              (item: IPropsComment) => item.parent.id === resp.id
            ),
          };
        });

        setComments({
          ...response.data,
          results: res,
          responseComments: responseComments,
        });

        setLoading(false);
      })
      .catch((err) => {});
    setLoading(false);
    setNewLoading(true);
  }

  async function handleInfinityComments(link: string) {
    setNewLoading(false);
    await axios
      .get(link)
      .then((response) => {
        let responseComments = response.data.results.filter(
          (comment: IPropsComment) => comment.parent !== null
        );

        let res = response.data.results.filter(
          (comment: IPropsComment) => comment.parent === null
        );

        res = res.map((resp: IPropsComment) => {
          return {
            ...resp,
            responseComment: [
              ...responseComments.filter(
                (item: IPropsComment) => item.parent.id === resp.id
              ),
              ...comments.responseComments.filter(
                (item) => item.parent.id === resp.id
              ),
            ],
          };
        });

        setComments({
          ...response.data,
          results: [...comments.results, ...res],
          responseComments: [...comments.responseComments, ...responseComments],
        });
      })
      .catch((err) => {});

    setNewLoading(true);
  }

  function handleDeleteComment(id: number | string) {
    let newCommentsList = comments.results.filter(
      (comment) => comment.id !== id
    );
    setComments({
      ...comments,
      results: newCommentsList,
    });
  }

  async function handleCreateNewComment(data: any) {
    setLoading(true);
    if (!data.body) {
      setLoading(false);

      return handleToast(true, 'Você não pode enviar uma postagem em branco');
    }

    let searchHashTags = data.body.split(' ');
    searchHashTags = searchHashTags.filter((hashtag: string) =>
      hashtag.includes('#')
    );

    searchHashTags = searchHashTags.toString();

    if (searchHashTags) {
      data.hashtag = searchHashTags;
    } else {
      data.hashtag = '';
    }

    data.is_reply = false;
    data.parent_id = null;

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}${socialRoutes.SOCIAL_CREATE_POST}`,
        data
      )
      .then((response) => {
        const newComment = {
          ...response.data,
          responseComment: [],
        };
        setComments({
          ...comments,
          results: [newComment, ...comments.results],
        });
        setValue('body', '');
        setLoading(false);
        return handleToast(false, 'Publicado com sucesso');
      })
      .catch((err) => {
        setLoading(false);
        handleToast(true, 'Erro ao publicar');
      });
    setLoading(false);
  }

  async function handleLikeComment(id: string | number) {
    const comment = comments.results.find((comment) => comment.id === id);
    if (comment?.liked.includes(user.id)) {
      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}/social/api/posts/${id}/likes/`
        )
        .then((response) => {
          const liked = comments.results.findIndex(
            (comment) => comment.id === id
          );
          let newComments = comments.results;
          newComments[liked].liked = newComments[liked].liked.splice(
            user.id,
            1
          );
          setComments({
            ...comments,
            results: newComments,
          });
        })
        .catch((err) => {});
      return;
    }
    await axios
      .post(`${process.env.REACT_APP_API_URL}/social/api/posts/${id}/likes/`)
      .then((response) => {
        const liked = comments.results.findIndex(
          (comment) => comment.id === id
        );
        let newComments = comments.results;
        newComments[liked].liked = [...newComments[liked].liked, user.id];
        setComments({
          ...comments,
          results: newComments,
        });
      })
      .catch((err) => {});
  }

  return loading ? (
    <FeedLoader />
  ) : (
    <>
      <Container>
        <Col>
          <CardProfileUser userProfile={user} />

          <TabContent selected={selected}>
            <span
              className={selected === '' ? 'selected' : ''}
              onClick={() => setSelected('')}
            >
              Feed
            </span>
            <span
              className={selected === user.slug ? 'selected' : ''}
              onClick={() => setSelected(user.slug)}
            >
              Minhas postagens
            </span>
            {treendTopics.map((treend, index) => (
              <span
                key={index}
                className={selected === treend.text ? 'selected' : ''}
                onClick={() => setSelected(treend.text)}
              >
                #{treend.text}
              </span>
            ))}
          </TabContent>
          <CardZ1>
            <RowComment>
              <NewComment onSubmit={handleSubmit(handleCreateNewComment)}>
                <WarningNewMessage>
                  <Info  color="#606060" size={24} />
                  <p>
                    Atenção: Mensagens sobre suporte técnico não serão aceitas
                    neste espaço. Para obter ajuda rápida, por favor, clique no
                    link abaixo. Estamos à disposição para atendê-lo!{' '}
                    <a href={whatsAppLinkApi} target="_blank">
                      {' '}
                      FALAR COM O SUPORTE
                    </a>
                  </p>
                </WarningNewMessage>
                <textarea
                  placeholder="No que você está pensando hoje? Utilize # para destacar uma palavra."
                  {...register('body')}
                />

                <RowButtonComment>
                  <ButtonComponent variant="primary" type="submit">
                    Postar
                  </ButtonComponent>
                </RowButtonComment>
              </NewComment>
            </RowComment>
          </CardZ1>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Comments>
              {comments.results.length > 0 &&
                comments.results.map((comment) => {
                  let body = comment.body;
                  body = body.replace(/\n/g, `<br />`);

                  let newComment = {
                    ...comment,
                    body,
                  };

                  return (
                    <Comment
                      key={comment.id}
                      //@ts-ignore
                      comment={newComment}
                      handleLikeComment={handleLikeComment}
                      deleteComment={(id) => handleDeleteComment(id)}
                    />
                  );
                })}
            </Comments>
          )}

          {!newLoading && (
            <div className="loading">
              <ReactLoading
                type="spokes"
                color="var(--primary)"
                width="50px"
                height="50px"
              />
            </div>
          )}
          {newLoading &&
            comments.results.length + comments.responseComments.length <
              comments.count && (
              <InfinityScroll
                fetchMore={() => handleInfinityComments(comments.next)}
              />
            )}
        </Col>
      </Container>

      <BackToTop
        onClick={() => window.scroll(0, 0)}
        title="Voltar ao topo da página"
      >
        <ArrowUp size={32} color="var(--primary)" />
      </BackToTop>
    </>
  );
}

export default RecommendedPosts;
