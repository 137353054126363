import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';

export const IncomeTaxPagesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentTab = styled(ScrollContainer)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  overflow: auto;
  white-space: nowrap;
`;

interface IPropsButtonTab {
  isActive: boolean;
}

export const ButtonTab = styled.button<IPropsButtonTab>`
  all: unset;
  padding: 1rem ;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;

  background: ${(props) =>
    props.isActive ? props.theme['primary-800'] : props.theme.white};
  color: ${(props) =>
    props.isActive ? props.theme.white : props.theme['gray-300']};
  font-weight: 500;
  font-size: 1.125rem;
`;

export const ContentPages = styled.div`
  background: ${(props) => props.theme.white};
  padding: 1rem;
`;
