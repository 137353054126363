import { Info, NotePencil } from '@phosphor-icons/react';
import { InputComponent } from '../../../../../components/InputComponent';
import { Brokerages } from '../../components/Brokerages/intex';
import {
  ActionActiveContainer,
  ContentItemsFilterColumnTable,
  ContentTable,
  FooterTable,
} from './styles';
import { useEffect, useState } from 'react';
import { ModalDesigner } from '../../../../../components/ModalDesigner';
import { ActionsTable } from '../../../../components/Boltz/MyWallet/components/Tables/ActionsTable';
import { FormCreateRule } from '../../../../components/Boltz/MyWallet/FormCreateRule';
import { useActivesWallet } from '../../hooks/ActivesHooks';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { useActives } from '../../../../../hooks/useActives';

export function ActionActives() {
  const { fullWallet, updateWallet, setFullWallet } = useActivesWallet();
  // @ts-ignore
  const { showActive } = useActives();
  let filterTable = localStorage.getItem('filterTableAction-v1.0.0');
  // @ts-ignore
  filterTable = JSON.parse(filterTable);
  const [selectedBrokerage, setSelectedBrokerage] = useState('');
  const [rule, setRule] = useState(false);
  const [showModalCreateRule, setShowModalCreateRule] = useState(false);
  const [showModalSelectedDateTable, setShowModalSelectedDateTable] =
    useState(false);
  const [filterTextSearchActive, setFilterTextSearchActive] = useState('');

  const [filterShowColumnTable, setFilterShowColumnTable] = useState(() => {
    if (filterTable === null) {
      return ['Ativo', 'Cotação', 'Nota', 'Quant.', 'Objetivo'];
    } else {
      return filterTable;
    }
  });
  const arrColumnTableData = [
    'Ativo',
    'Nota',
    'Quant.',
    'Patrimônio',
    'Atual',
    'Diferença',
    'Lucro/Prejuízo',
    'Rentabilidade',
    'Preço médio',
    'Cotação',
  ];

  const arrColumnTableRecommended = [
    'Ativo',
    'Cotação',
    'Nota',
    'Quant.',
    'Objetivo',
  ];

  useEffect(() => {
    localStorage.setItem(
      'filterTableAction-v1.0.0',
      JSON.stringify(filterShowColumnTable)
    );
  }, [filterShowColumnTable]);

  function handleSelectedColumnTable(value: any) {
    // @ts-ignore
    const isValueAllReadyExist = filterShowColumnTable.find(
      (item: any) => item === value
    );
    if (isValueAllReadyExist) {
      setFilterShowColumnTable((state: any) => {
        return state.filter((item: any) => item !== value);
      });
    } else {
      setFilterShowColumnTable((state: any) => {
        return [...state, value];
      });
    }
  }

  const totalActionMoney = fullWallet.positionAcao
    ? fullWallet.positionAcao.reduce((acc, item) => {
        return acc + item.closingPrice * item.equitiesQuantity;
      }, 0)
    : 0;
  const amountActives =
    fullWallet.positionAcao && fullWallet?.positionAcao?.length > 0
      ? fullWallet.positionAcao.length
      : 0;

  return (
    <ActionActiveContainer>
      {window.screen.width > 768 && <Brokerages />}
      <InputComponent
        isSearch
        placeholder="Busque por código ou nome da empresa"
        roundedFull
        onChange={(e) => setFilterTextSearchActive(e.target.value)}
      />
      <ContentTable>
        <header>
          <span>Seus ativos</span>
          <button
            type="button"
            onClick={() => setShowModalSelectedDateTable(true)}
          >
            Adicionar campos <NotePencil />
          </button>
        </header>

        <ActionsTable
          setRule={setRule}
          setShowModalCreateRule={setShowModalCreateRule}
          setWalletValues={setFullWallet}
          //@ts-ignore
          walletValues={fullWallet}
          filterBrokerage={selectedBrokerage}
          filterShowColumnTable={filterShowColumnTable as string[]}
          filterTextSearchActive={filterTextSearchActive}
        />
        <FooterTable>
          <p>
            Total:{' '}
            <strong>
              {showActive
                ? transformMaskCurrency(totalActionMoney)
                : 'R$ ******'}
            </strong>{' '}
          </p>
          <p>
            Total de ativos: <strong>{amountActives}</strong>
          </p>
          <p>
            Porcentagem total: <strong>100%</strong>
          </p>
        </FooterTable>
      </ContentTable>

      <ModalDesigner
        id="selecionarColunas"
        isOpen={showModalSelectedDateTable}
        setState={setShowModalSelectedDateTable}
        title="Adicionar campos"
        size="sm"
      >
        <ContentItemsFilterColumnTable>
          <span className="recommendText">Recomendado</span>
          <div className="contentInput">
            {arrColumnTableRecommended.map((item) => (
              <label key={item}>
                <input
                  type="checkbox"
                  value={item}
                  defaultChecked={filterShowColumnTable.includes(item)}
                  onChange={(e) => handleSelectedColumnTable(e.target.value)}
                />
                {item}
              </label>
            ))}
          </div>

          <span className="addMore">CAMPOS ADICIONAIS</span>
          <div className="contentInput">
            {arrColumnTableData
              .filter(
                (item) =>
                  item !== 'Ativo' &&
                  item !== 'Cotação' &&
                  item !== 'Nota' &&
                  item !== 'Quant.'
              )
              .map((item) => (
                <label key={item}>
                  <input
                    type="checkbox"
                    value={item}
                    defaultChecked={filterShowColumnTable.includes(item)}
                    onChange={(e) => handleSelectedColumnTable(e.target.value)}
                  />
                  {item}
                </label>
              ))}
          </div>
          <span className="important">
            <Info size={16} color="#505050" weight="bold" /> IMPORTANTE
          </span>
          <p className="warning">
            Alterar a estrutura da tabela de ativos padrão pode afetar sua
            experiência, recomendamos que sempre que possível, utilize a tabela
            padrão.
          </p>
        </ContentItemsFilterColumnTable>
      </ModalDesigner>

      {showModalCreateRule && (
        <FormCreateRule
          //@ts-ignore
          rule={rule}
          setShowModalCreateRule={setShowModalCreateRule}
          showModalCreateRule={showModalCreateRule}
          update={() => updateWallet()}
        />
      )}
    </ActionActiveContainer>
  );
}
