import styled from 'styled-components';

export const SimuladoresContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TabPagesContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background-color: white;
  overflow: hidden;
  padding: 2rem;
`;

interface IPropsButton {
  isActive: boolean;
}

export const ButtonTab = styled.button<IPropsButton>`
  all: unset;
  padding: 0.675rem 0.875rem;
  width: 100%;
  font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
  font-size: 1.125rem;
  text-align: center;
  color: #3f3f46;
  border-bottom: ${(props) =>
    props.isActive ? '3px solid #EC6643' : '1px solid #d3d3d7'};

  @media (max-width: 480px) {
    /* padding: 1rem 0.5rem; */
    font-size: 0.75rem;
  }
`;
