import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .ant-carousel .slick-prev {
    left: 0;
    z-index: 1;
  }
  .ant-carousel .slick-next {
    right: 0;
  }
`;

export const ContentCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  gap: 1rem;
  width: 100%;
`;
