import styled from 'styled-components';

export const GraphicNoteFiiContainer = styled.div`
  margin-left: 2px;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  h6 {
    position: absolute;
    margin: 0;
    font-size: 0.75rem;
    font-weight: 500;
    color: ${(props) => props.theme['gray-500']};
  }
`;
