import Select from 'react-select';
import {
  AsideContentFilters,
  AsideHeader,
  ButtonShowMoreFilters,
  ContentInputFilters,
} from './styles';
import { useEffect, useState } from 'react';
import { IPropsFilters } from '../SearchFiltersListRankAction';
import axios from 'axios';
import { baseURL } from '../../../../../utils/baseURL';
import { financeRoutes } from '../../../../../utils/RoutesApi';
import { Slider } from 'antd';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { SliderMarks } from 'antd/es/slider';

import { editValueFilterDivida } from './components/editValueFilterDivida';
import { ClearFilterButton } from '../../../../../components/ClearFilterButton/styles';

interface IPropsSearchFilterListRankAction {
  setSearchFilters: Function;
  searchFilters: IPropsFilters;
}
interface IPropsSelectedArr {
  value: string;
  label: string;
}

export function AsideFiltersAction({
  searchFilters,
  setSearchFilters,
}: IPropsSearchFilterListRankAction) {
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [segmentos, setSegmentos] = useState<IPropsSelectedArr[]>([
    {
      value: '',
      label: 'Todos',
    },
  ]);
  const [setores, setSetores] = useState<IPropsSelectedArr[]>([
    {
      value: '',
      label: 'Todos',
    },
  ]);
  const [subSetores, setSubSetores] = useState<IPropsSelectedArr[]>([
    {
      value: '',
      label: 'Todos',
    },
  ]);
  const [dyValueFilter, setDyValueFilter] = useState<number>(
    searchFilters.dividendos
  );
  // const [roeFilter, setRoeFilter] = useState<number>(searchFilters.roe);
  const [ebitdaFilter, setEbitdaFilter] = useState<number>(() => {
    if (searchFilters.ebitda === 10000000) {
      return 32;
    }
    if (searchFilters.ebitda === 50000000) {
      return 64;
    }
    if (searchFilters.ebitda === 1000000000) {
      return 96;
    }
    if (searchFilters.ebitda === 10000000000) {
      return 128;
    }
    if (searchFilters.ebitda === 100000000000) {
      return 160;
    }
    return 0;
  });
  const [dividaLiquidaFilter, setDividaLiquidaFilter] = useState(() => {
    if (searchFilters.dividaliquida === -50000000) {
      return 0;
    }
    if (searchFilters.dividaliquida === -10000000) {
      return 25;
    }
    if (searchFilters.dividaliquida === 0) {
      return 50;
    }
    if (searchFilters.dividaliquida === 10000000) {
      return 75;
    }

    if (searchFilters.dividaliquida === 30000000) {
      return 100;
    }

    return 0;
  });
  const [dividaEbitdaFilter, setDividaEbitdaFilter] = useState<number>(
    searchFilters.dividaebitda
  );
  const [lucroFilter, setLucroFilter] = useState<number>(() => {
    if (searchFilters.lucroliquido === 10000000) {
      return 32;
    }

    if (searchFilters.lucroliquido === 50000000) {
      return 64;
    }
    if (searchFilters.lucroliquido === 1000000000) {
      return 96;
    }
    if (searchFilters.lucroliquido === 10000000000) {
      return 128;
    }

    if (searchFilters.lucroliquido === 100000000000) {
      return 160;
    }

    return 0;
  });
  const [selectedValues, setSelectedValues] = useState({
    segmento: searchFilters.segmento,
    segmento_listagem: searchFilters.segmento_listagem,
    setor: searchFilters.setor,
    subsetor: searchFilters.subsetor,
  });

  useEffect(() => {
    filtersSectorsActions();
  }, []);

  async function filtersSectorsActions() {
    await axios
      .get(`${baseURL}${financeRoutes.FILTERS_SECTORS_ACTIONS}`)
      .then((response) => {
        const removeDuplicate = new Set();

        const segmentosList = response.data.segmentos
          //@ts-ignore
          .map((item) => {
            return {
              value: item.segmento,
              label: `${item.segmento
                .substring(0, 1)
                .toUpperCase()}${item.segmento.substring(1).toLowerCase()}`,
            };
          })
          .sort((a: any, b: any) => a.label.localeCompare(b.label));
        const setoresList = response.data.setores
          //@ts-ignore
          .map((item) => {
            return {
              value: item.setor,
              label: `${item.setor.substring(0, 1).toUpperCase()}${item.setor
                .substring(1)
                .toLowerCase()}`,
            };
          })
          .sort((a: any, b: any) => a.label.localeCompare(b.label));
        const subSetoresList = response.data.sub_setores
          //@ts-ignore
          .map((item) => {
            return {
              value: item.sub_setor,
              label: `${item.sub_setor
                .substring(0, 1)
                .toUpperCase()}${item.sub_setor.substring(1).toLowerCase()}`,
            };
          })
          .sort((a: any, b: any) => a.label.localeCompare(b.label));

        let newSetores = setoresList;
        // @ts-ignore
        newSetores = newSetores.filter((setor) => {
          const duplicatedSector = removeDuplicate.has(
            setor.value.toLowerCase()
          );
          removeDuplicate.add(setor.value.toLowerCase());
          return !duplicatedSector;
        });
        let newSubSetores = subSetoresList;
        // @ts-ignore
        newSubSetores = newSubSetores.filter((setor) => {
          const duplicatedSector = removeDuplicate.has(
            setor.value.toLowerCase()
          );
          removeDuplicate.add(setor.value.toLowerCase());
          return !duplicatedSector;
        });
        let newSegmentos = segmentosList;
        // @ts-ignore
        newSegmentos = newSegmentos.filter((setor) => {
          const duplicatedSector = removeDuplicate.has(
            setor.value.toLowerCase()
          );
          removeDuplicate.add(setor.value.toLowerCase());
          return !duplicatedSector;
        });
        setSubSetores([...subSetores, ...newSubSetores]);
        setSetores([...setores, ...newSetores]);
        setSegmentos([...segmentos, ...newSegmentos]);
      })
      .catch((err) => {});
  }

  const arrSegList = [
    {
      value: '',
      label: 'Todos',
    },
    {
      value: 'N1',
      label: 'Nível 1',
    },
    {
      value: 'N2',
      label: 'Nível 2',
    },
    {
      value: 'NM',
      label: 'Novo mercado',
    },
    {
      value: 'MA',
      label: 'Bovespa mais',
    },
    {
      value: 'Tradicional',
      label: 'Tradicional',
    },
  ];

  const returnSelectValue = (
    id: string,
    arr: {
      value: string;
      label: string;
    }[]
  ) => {
    if (id) {
      let obj = arr?.map((item) => {
        if (item?.value === id) {
          return {
            value: item.value,
            label: item.label,
          };
        }
      });
      return obj;
    } else {
      return null;
    }
  };

  function handleChangeDyFilter(value: number) {
    setDyValueFilter(value);
    setSearchFilters({
      ...searchFilters,
      isFiltered: true,
      dividendos: value,
    });
  }
  function handleChangeDividaEbitdaFilter(value: number) {
    setDividaEbitdaFilter(value);
    setSearchFilters({
      ...searchFilters,
      isFiltered: true,
      dividaebitda: value,
    });
  }
  // function handleChangeRoeFilter(value: number) {
  //   setRoeFilter(value);
  //   setSearchFilters({
  //     ...searchFilters,
  //     isFiltered: true,
  //     roe: value,
  //   });
  // }
  function handleChangeEbitdaFilter(value: number) {
    setEbitdaFilter(value);
    setSearchFilters({
      ...searchFilters,
      isFiltered: true,
      ebitda: editValueFilter(value),
    });
  }

  function editValueFilter(val: number) {
    if (val === 32) {
      return 10000000;
    }

    if (val === 64) {
      return 50000000;
    }
    if (val === 96) {
      return 1000000000;
    }
    if (val === 128) {
      return 10000000000;
    }

    if (val === 160) {
      return 100000000000;
    }
    return 0;
  }

  function handleChangeLucroFilter(value: number) {
    setLucroFilter(value);
    setSearchFilters({
      ...searchFilters,
      isFiltered: true,
      lucroliquido: editValueFilter(value),
    });
  }
  function handleChangeDividaLiquidaFilter(value: number) {
    setDividaLiquidaFilter(value);
    setSearchFilters({
      ...searchFilters,
      isFiltered: true,
      dividaliquida: editValueFilterDivida(value),
    });
  }

  const marksMobile: SliderMarks = {
    0: '0',
    32: '10mi',
    64: '500mi',
    96: '1bi',
    128: '10bi',
    160: {
      style: {
        transform: 'translateX(-110%)',
      },
      label: '100bi',
    },
  };

  const tooltip = {
    formatter: (val: number) => {
      if (val === 0) {
        return `0`;
      }

      if (val === 32) {
        return 'Acima de 10 milhões';
      }

      if (val === 64) {
        return 'Acima de 500 milhões';
      }
      if (val === 96) {
        return 'Acima de 1 bilhão';
      }
      if (val === 128) {
        return 'Acima de 10 bilhões';
      }

      if (val === 160) {
        return 'Acima de 100 bilhões';
      }
    },
  };
  const tooltipDividaLiquida = {
    formatter: (val: number) => {
      if (val === 0) {
        return `Abaixo de -50 milhões`;
      }
      if (val === 25) {
        return 'Abaixo de -10 milhões';
      }

      if (val === 50) {
        return 'Abaixo de 0';
      }
      if (val === 75) {
        return 'Abaixo de 10 milhões';
      }
      if (val === 100) {
        return 'Abaixo de 30 milhões';
      }
    },
  };

  const marksDividaLiquidaMobile: SliderMarks = {
    0: '-50mi',
    25: '-10mi',
    50: '0',
    75: '10mi',

    100: {
      style: {
        transform: 'translateX(-110%)',
      },
      label: '30mi',
    },
  };

  return (
    <AsideContentFilters>
      <AsideHeader>
        <span>Filtros</span>

        <ClearFilterButton
          type="button"
          onClick={() => {
            setSearchFilters({
              lucroliquido: 0,
              roe: 0,
              ebitda: 0,
              dividendos: 0,
              dividaliquida: 0,
              dividaebitda: 0,
              cnpjativo: '',
              setor: '',
              segmento: '',
              nome_empresa: '',
              subsetor: '',
              segmento_listagem: '',
              isFiltered: false,
            });
            setDyValueFilter(0);
            setEbitdaFilter(0);
            setDividaLiquidaFilter(50);
            setDividaEbitdaFilter(0);
            setLucroFilter(0);
            setSelectedValues({
              segmento: '',
              segmento_listagem: '',
              setor: '',
              subsetor: '',
            });
          }}
        >
          Limpar filtros
        </ClearFilterButton>
      </AsideHeader>

      <ContentInputFilters>
        <label>Segmento de listagem</label>
        <Select
          className="select"
          options={arrSegList}
          //@ts-ignore
          onChange={(e) => {
            setSearchFilters({
              ...searchFilters,
              // @ts-ignore
              segmento_listagem: e.value,
            });
            setSelectedValues({
              ...selectedValues,
              // @ts-ignore
              segmento_listagem: e.value,
            });
          }}
          value={returnSelectValue(
            selectedValues.segmento_listagem,
            arrSegList
          )}
          placeholder="Todos"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? '#0176E1' : 'transparent',
              borderRadius: 8,
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              boxShadow: ' 2px 2px 6px 0px #0000001A',
              borderRadius: 8,
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              color: '#808080',
              fontSize: 16,
            }),
            placeholder: (base) => ({
              ...base,
            }),
            valueContainer: (base) => ({
              ...base,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 8,
              paddingBottom: 8,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),

            dropdownIndicator: (base) => ({
              ...base,
              color: '#808080',
            }),
          }}
        />
      </ContentInputFilters>
      <ContentInputFilters>
        <label>Segmento</label>
        <Select
          options={segmentos}
          //@ts-ignore
          onChange={(e) => {
            setSearchFilters({
              ...searchFilters,
              //@ts-ignore
              segmento: e.value,
            });
            setSelectedValues({
              ...selectedValues,
              // @ts-ignore
              segmento: e.value,
            });
          }}
          value={returnSelectValue(selectedValues.segmento, segmentos)}
          placeholder="Todos"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? '#0176E1' : 'transparent',
              borderRadius: 8,
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              boxShadow: ' 2px 2px 6px 0px #0000001A',
              borderRadius: 8,
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              color: '#808080',
              fontSize: 16,
            }),
            placeholder: (base) => ({
              ...base,
            }),
            valueContainer: (base) => ({
              ...base,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 8,
              paddingBottom: 8,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),

            dropdownIndicator: (base) => ({
              ...base,
              color: '#808080',
            }),
          }}
        />
      </ContentInputFilters>
      <ContentInputFilters>
        <label>Setor</label>
        <Select
          className="select"
          options={setores}
          //@ts-ignore
          onChange={(e) => {
            setSearchFilters({
              ...searchFilters,
              //@ts-ignore
              setor: e.value,
            });
            setSelectedValues({
              ...selectedValues,
              // @ts-ignore
              setor: e.value,
            });
          }}
          value={returnSelectValue(selectedValues.setor, setores)}
          placeholder="Todos"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? '#0176E1' : 'transparent',
              borderRadius: 8,
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              boxShadow: ' 2px 2px 6px 0px #0000001A',
              borderRadius: 8,
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              color: '#808080',
              fontSize: 16,
            }),
            placeholder: (base) => ({
              ...base,
            }),
            valueContainer: (base) => ({
              ...base,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 8,
              paddingBottom: 8,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),

            dropdownIndicator: (base) => ({
              ...base,
              color: '#808080',
            }),
          }}
        />
      </ContentInputFilters>
      <ContentInputFilters>
        <label>Sub setor</label>
        <Select
          className="select"
          options={subSetores}
          //@ts-ignore
          onChange={(e) => {
            setSearchFilters({
              ...searchFilters,
              //@ts-ignore
              subsetor: e.value,
            });
            setSelectedValues({
              ...selectedValues,
              // @ts-ignore
              subsetor: e.value,
            });
          }}
          value={returnSelectValue(selectedValues.subsetor, subSetores)}
          placeholder="Todos"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? '#0176E1' : 'transparent',
              borderRadius: 8,
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              boxShadow: ' 2px 2px 6px 0px #0000001A',
              borderRadius: 8,
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              color: '#808080',
              fontSize: 16,
            }),
            placeholder: (base) => ({
              ...base,
            }),
            valueContainer: (base) => ({
              ...base,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 8,
              paddingBottom: 8,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),

            dropdownIndicator: (base) => ({
              ...base,
              color: '#808080',
            }),
          }}
        />
      </ContentInputFilters>
      <ContentInputFilters>
        <label>Dividend Yield/DY </label>
        <Slider
          min={0}
          max={20}
          step={0.01}
          onChange={handleChangeDyFilter}
          value={typeof dyValueFilter === 'number' ? dyValueFilter : 0}
          className="slider"
        />
        <span>Acima de {dyValueFilter}%</span>
      </ContentInputFilters>
      {showAllFilters && (
        <>
          {/* <ContentInputFilters>
            <label>ROE</label>
            <Slider
              min={0}
              max={15}
              onChange={handleChangeRoeFilter}
              value={typeof roeFilter === 'number' ? roeFilter : 0}
              className="slider"
            />
            <span>Acima de {roeFilter}%</span>
          </ContentInputFilters> */}
          <ContentInputFilters>
            <label>EBITDA</label>
            <Slider
              max={160}
              onChange={handleChangeEbitdaFilter}
              value={typeof ebitdaFilter === 'number' ? ebitdaFilter : 0}
              className="slider"
              step={null}
              // @ts-ignore
              tooltip={tooltip}
              marks={marksMobile}
            />
            {/* <span>{maskValueEbitda(ebitdaFilter)}</span> */}
          </ContentInputFilters>
          <ContentInputFilters>
            <label>Lucro líquido</label>
            <Slider
              onChange={handleChangeLucroFilter}
              value={typeof lucroFilter === 'number' ? lucroFilter : 0}
              className="slider"
              step={null}
              max={160}
              // @ts-ignore
              tooltip={tooltip}
              marks={marksMobile}
            />
            {/* <span>{maskValueEbitda(lucroFilter)}</span> */}
          </ContentInputFilters>
          <ContentInputFilters>
            <label>Dívida líquida</label>
            <Slider
              onChange={handleChangeDividaLiquidaFilter}
              value={
                typeof dividaLiquidaFilter === 'number'
                  ? dividaLiquidaFilter
                  : 0
              }
              draggableTrack
              className="slider"
              step={null}
              max={100}
              // @ts-ignore
              tooltip={tooltipDividaLiquida}
              included={false}
              marks={marksDividaLiquidaMobile}
            />
            {/* <span>{maskValueDivida(dividaLiquidaFilter)}</span> */}
          </ContentInputFilters>
          <ContentInputFilters>
            <label>Dívida EBITDA</label>
            <Slider
              onChange={handleChangeDividaEbitdaFilter}
              value={
                typeof dividaEbitdaFilter === 'number' ? dividaEbitdaFilter : 0
              }
              className="slider"
              step={1}
              min={-15}
              max={15}
              included={false}
            />
            <span>Abaixo de {dividaEbitdaFilter}%</span>
          </ContentInputFilters>
        </>
      )}

      <ButtonShowMoreFilters
        onClick={() => setShowAllFilters(!showAllFilters)}
        type="button"
      >
        {showAllFilters ? 'Esconder filtros' : ' Mostrar mais filtros'}
        {showAllFilters ? (
          <CaretUp weight="bold" size={24} />
        ) : (
          <CaretDown weight="bold" size={24} />
        )}
      </ButtonShowMoreFilters>
    </AsideContentFilters>
  );
}
