import styled from 'styled-components';

export const ContainerAsideFilters = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 18.75rem;
  background: ${(props) => props.theme.white};
  border-radius: 8px;
  padding: 1.5rem 1rem;
  gap: 1rem;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${(props) => props.theme['gray-500']};
    margin-bottom: 1rem;
    width: 100%;
  }

  select {
    box-shadow: 2px 2px 6px 0px #0000001a;
    border: none;
    padding: 1rem;
    border-radius: 8px;
    width: 100%;
  }
`;
