import { Buildings } from '@phosphor-icons/react';
import ReactApexChart from 'react-apexcharts';
import { IPropsRankActive } from '../../@types/listActiveType';
import { Container, Content, ImageContent } from './styles';
import { Tooltip } from 'antd';
import { cores } from '../../../../../utils/coresGraficos';

interface IPropsCardActiveRank {
  active: IPropsRankActive;
  selectedActive: ({
    slug,
    type,
  }: {
    slug: string;
    type: '' | 'action' | 'fii';
  }) => void;
}

export function CardActiveRank({ active,selectedActive }: IPropsCardActiveRank) {

  const compare = {
    series: [
      active.LucroLiquido,
      active.EBITDA,
      active.Dividendos,
      active.DividaLiquida,
      active.DividaLiquidaEbitida,
      active.Resiliencia,
      active.Governanca,
      active.Indices,
    ],
    options: {
      chart: {
        height: 390,
        type: 'donut',
      },
      yaxis: {
        labels: {
          //@ts-ignore
          formatter: (val) => `${val}%`,
        },
      },
      grid: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },

      legend: {
        show: false,
        position: 'bottom',
      },

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              total: {
                show: false,
                label: 'Media',
                formatter: () => `${active.total_chart.toFixed(0)}%`,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
        //@ts-ignore
        formatter: (val) => `${val.toFixed(0)}%`,
      },
      tooltip: {
        y: {
          //@ts-ignore
          formatter: (val) => `${val.toFixed(0)}%`,
        },
      },

      labels: [
        'Lucro Liquido',
        'EBITDA',
        'Dividendos',
        'Divida Líquida',
        'Divida Líquida EBITDA',
        'Resiliencia',
        'Governanca',
        'Índices',
      ],
      colors: cores,

    },
  };
  //@ts-ignore
  const tickerArray = active.Assets.split('|').sort((a: any, b: any) => {
    if (a > b) {
      return 1;
    } else {
      return -1;
    }
  });

  const mainAsset = active.Assets.split('|')
  .sort(
    (a: string, b: string) =>
      Number(a.replace(/[a-zA-Z]/g, '')) - Number(b.replace(/[a-zA-Z]/g, ''))
  )[0]
  .replace('.SA', '');

  return (
    <Container
      onClick={() =>
        selectedActive({
          slug: mainAsset,
          type: 'action',
        })
      }
    >
      <header>
        <ImageContent>
          {active.LOGO_BRAND ? (
            <img src={active.LOGO_BRAND} />
          ) : (
            <Buildings size="2rem" weight="fill" />
          )}
        </ImageContent>
        <button
          onClick={() =>
            selectedActive({
              slug: mainAsset,
              type: 'action',
            })
          }
          title={tickerArray.map((item) => item).join(', ')}
        >
          {mainAsset}
        </button>
      </header>
      <Content>
        <div className="contentGraphicValue">
          <ReactApexChart
            //@ts-ignore
            options={compare.options}
            series={compare.series}
            type="donut"
            width={150}
          />
          <Tooltip title="Essa é a nota da empresa com base na média das nossas avaliações">
            <h6>{active.total_chart.toFixed(0)}%</h6>
          </Tooltip>
        </div>
        <div>
          <h5 title={active.NomeEmpresa}>{active.NomeEmpresa}</h5>
        </div>
      </Content>
    </Container>
  );
}
