import styled from 'styled-components';

export const CardActiveRankMobileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-height: 5rem;
  box-shadow: 1px 1px 6px 0px #0000001a;
  padding: 1rem;
  border-radius: 8px;

  .contentGraphicValue {
    margin-left: 2px;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    h6 {
      position: absolute;
      margin: 0;
      font-size: 0.75rem;
      font-weight: 500;
      color: ${(props) => props.theme['gray-500']};
    }
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;

  h3 {
    font-weight: 600;
    font-size: 1.25rem;
    color: ${(props) => props.theme['gray-600']};
    margin: 0;
  }

  span {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${(props) => props.theme['gray-600']};
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    text-align: start;
  }
`;
