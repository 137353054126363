import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  CaretDown,
  CaretUp,
  Check,
  Plus,
  Trash,
  X,
} from '@phosphor-icons/react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { InputComponent } from '../../../../../components/InputComponent';
import { Brokerages } from '../../components/Brokerages/intex';
import {
  ManualImportContainer,
  ContentTable,
  TypeActiveContent,
  EditActive,
  DeleteActive,
  DeleteAllActives,
  CompanyNameContent,
} from './styles';
import { filterTableSorte } from '../../../../../utils/filterTableSort';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { Modal } from '../../../../../components/Modal';
import { SimpleActive } from '../../../../../components/Forms/SimpleActive';
import { baseURL } from '../../../../../utils/baseURL';
import { walletRoutes } from '../../../../../utils/RoutesApi';
import { useTitleAndUser } from '../../../../../hooks/userTitle';
import { handleToast } from '../../../../../components/Toast';
import { IPropsManualActive } from './@types';

import { Button } from '../../../../pages/RegistrationStyles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: 'Poppins, sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const header = [
  { label: 'Ativo', active: 'tickerSymbol' },
  { label: 'Corretora', active: 'participantName' },
  { label: 'Tipo de investimento', active: 'productTypeName' },
  { label: 'Quantidade', active: 'equitiesQuantity' },
  { label: 'Auto.' },
  { label: 'Ações' },
];

interface IPropsValues {
  count: number;
  current: number;
  data: IPropsManualActive[];
  links: { next: string; previous: string };
  total_pages: number;
}

export function ManualImport() {
  // @ts-ignore
  const { user } = useTitleAndUser();
  const [filterTextSearchActive, setFilterTextSearchActive] = useState('');
  const [modalCreateActive, setModalCreateActive] = useState(false);
  const [modalEditActive, setModalEditActive] = useState(false);
  const [modalDeleteAll, setModalDeleteAll] = useState(false);
  const [values, setValues] = useState<IPropsValues>({} as IPropsValues);
  const [edit, setEdit] = useState<IPropsManualActive>(
    {} as IPropsManualActive
  );

  useEffect(() => {
    handleGetListActives();
  }, []);

  async function handleGetListActives(params = {}) {
    await axios
      .get(`${baseURL}/Finance/carteira/all`, { params })
      .then((response) => {
        setValues(response.data);
      })
      .catch((error) => {});
  }
  async function handleDeleteActive(key: string) {
    let data = {
      username: user.username,
    };
    await axios
      .delete(`${baseURL}/Finance/carteira/item/${key}/delete/`, { data })
      .then((response) => {
        handleToast(false, 'Ativo deletado com sucesso');
        handleGetListActives({ page: 1, page_size: 100 });
        return;
      })
      .catch((error) => {
        return;
      });
  }

  const idCreate = 'createActive';
  const idEdit = 'editActive';

  function handleOutSideClick(e: any) {
    if (e.target.id === idCreate) setModalCreateActive(false);
    if (e.target.id === idEdit) setModalEditActive(false);
  }

  async function handleDeleteAllImports() {
    await axios
      .delete(`${baseURL}${walletRoutes.WALLET_DELETE_ALL_IMPORTS}`)
      .then((response) => {
        setModalDeleteAll(false);
        handleGetListActives({ page: 1, page_size: 100 });
        handleToast(false, 'Todos os ativos foram deletados');
        return;
      })
      .catch((err) => {
        handleToast(true, 'Erro ao deletar ativos');

        return;
      });
  }

  function ascOrdination(key: string) {
    setValues({
      ...values,
      // @ts-ignore
      data: filterTableSorte({
        data: values.data,
        dir: 'asc',
        // @ts-ignore
        key,
      }),
    });
  }
  function descOrdination(key: string) {
    setValues({
      ...values,
      // @ts-ignore
      data: filterTableSorte({
        data: values.data,
        dir: 'desc',
        // @ts-ignore
        key,
      }),
    });
  }

  const ArrayFilteredAllTabe = values?.data?.filter((item) => {
    if (
      item.tickerSymbol
        .toLowerCase()
        .includes(filterTextSearchActive.toLocaleLowerCase()) ||
      item.corporationName
        .toLowerCase()
        .includes(filterTextSearchActive.toLocaleLowerCase())
    ) {
      return item;
    }
  });

  return (
    <ManualImportContainer>
      {window.screen.width > 768 && <Brokerages />}
      <InputComponent
        isSearch
        placeholder="Busque por código ou nome da empresa"
        roundedFull
        onChange={(e) => setFilterTextSearchActive(e.target.value)}
      />
      <ContentTable>
        <header>
          <span>Seus ativos</span>
          <div className="buttonContent">
            <button
              type="button"
              className="addNewActiveButton"
              onClick={() => setModalCreateActive(true)}
            >
              Adicionar ativo <Plus color="white" weight="bold" />
            </button>
            <button
              type="button"
              className="trashButton"
              onClick={() => setModalDeleteAll(true)}
            >
              <Trash color="#606060" />
            </button>
          </div>
        </header>
        <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
          <Table
            sx={{ minWidth: 700 }}
            stickyHeader
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                {header.map((item) => (
                  <StyledTableCell key={item.label}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        fontSize: '0.95rem',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {item.label}
                      {item.active && (
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <CaretUp
                            size={14}
                            color="black"
                            onClick={() => ascOrdination(item.active)}
                          />
                          <CaretDown
                            size={14}
                            color="black"
                            onClick={() => {
                              descOrdination(item.active);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {ArrayFilteredAllTabe && ArrayFilteredAllTabe.length > 0 ? (
                ArrayFilteredAllTabe.map((item, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" className="reduce">
                      <CompanyNameContent>
                        <strong>{item.tickerSymbol}</strong>
                        <span>{item.corporationName}</span>
                      </CompanyNameContent>
                    </StyledTableCell>
                    <StyledTableCell>{item.participantName}</StyledTableCell>
                    <StyledTableCell>
                      <TypeActiveContent typeActive={item.productTypeName}>
                        {item.productTypeName === 'acao'
                          ? 'Ação'
                          : item.productTypeName === 'FII'
                          ? 'FII'
                          : item.productTypeName === 'rendaFixa'
                          ? 'Renda fixa'
                          : null}
                      </TypeActiveContent>
                    </StyledTableCell>
                    <StyledTableCell>
                      {item.productTypeName === 'rendaFixa'
                        ? transformMaskCurrency(Number(item.equitiesQuantity))
                        : item.equitiesQuantity}
                    </StyledTableCell>

                    <StyledTableCell>
                      {item.agregarAutomatico ? (
                        <Check size={24} />
                      ) : (
                        <X size={24} />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <EditActive
                        onClick={() => {
                          setEdit(item);
                          setModalEditActive(true);
                        }}
                      />{' '}
                      <DeleteActive
                        onClick={() => handleDeleteActive(item.key)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
                  Ainda não há dados
                </h1>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentTable>

      {modalCreateActive && (
        <Modal
          id={idCreate}
          title="Cadastrar novo ativo"
          setState={setModalCreateActive}
          onClick={handleOutSideClick}
        >
          <SimpleActive
            setCancel={() => setModalCreateActive(false)}
            update={() => {
              setModalCreateActive(false);
              handleGetListActives({ page: 1, page_size: 100 });
            }}
          />
        </Modal>
      )}

      {modalEditActive && (
        <Modal
          id={idEdit}
          title="Editar ativo"
          setState={setModalEditActive}
          onClick={handleOutSideClick}
        >
          <SimpleActive
            setCancel={() => setModalEditActive(false)}
            edit={edit}
            update={() => {
              setModalEditActive(false);
              handleGetListActives({ page: 1, page_size: 100 });
            }}
          />
        </Modal>
      )}

      {modalDeleteAll && (
        <Modal
          id="Delete"
          title="Deletar todos os ativos"
          setState={setModalDeleteAll}
          onClick={handleOutSideClick}
        >
          <DeleteAllActives>
            <p>
              Ao confirmar você aceita que todos os ativos cadastrados na conta
              serão excluídos
            </p>
            <div className="buttonContent">
              <Button
                background="red"
                type="button"
                color="#FFF"
                onClick={() => setModalDeleteAll(false)}
              >
                Cancelar
              </Button>
              <Button
                style={{ background: 'red' }}
                background="#ff0000"
                type="button"
                color="#FFF"
                onClick={handleDeleteAllImports}
              >
                Confirmar
              </Button>
            </div>
          </DeleteAllActives>
        </Modal>
      )}
    </ManualImportContainer>
  );
}
