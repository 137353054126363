import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
`;

export const RegisterAside = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 7rem;
  }
  h2 {
    color: #40444c;
    text-align: center;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 2.25rem */
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .backToLoginText {
    margin-top: 1.5rem;
    color: #6a717e;

    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    a {
      color: ${(props) => props.theme['orange-500']};
    }
  }
`;

export const ContentAside = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 4rem;
  align-items: flex-start;
  position: relative;
  z-index: 10;

  img {
    width: 14.125rem;
  }

  p {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    margin: 0;
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 150%;
    color: #404040;
    width: 100%;
    max-width: 29.687rem;

    strong {
      color: #505050;
      font-size: 1.75rem;
      font-weight: 600;
    }
  }
  @media (max-width: 768px) {
    img {
      width: 13rem;
    }
    p {
      font-size: 1.5rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 28.125rem;

  position: relative;
  border-radius: 4px;
  gap: 1rem;
  z-index: 1;

  img {
    width: 9.187rem;
    height: auto;
    margin-bottom: 3rem;
  }

  header {
    font-family: 'Poppins', sans-serif;
    h2 {
      color: #40444c;
      text-align: center;
      /* Heading/H5/SemiBold */
      font-family: Poppins;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 2.25rem */
      margin-bottom: 0.5rem;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;

    a {
      color: ${(props) => props.theme['gray-700']};
      text-decoration: underline;
    }
  }

  .inputTransparent {
    background: transparent;
    border: 1px solid rgba(21, 21, 21, 0.3);
    border-radius: 4px;
    input {
      background: transparent !important;
      color: ${(props) => props.theme['gray-700']} !important;
    }
    .ant-input {
      background-color: transparent !important;
    }
  }

  .checkbox {
    align-items: center;
    input:checked {
      background-color: ${(props) => props.theme.primary} !important;
    }
  }

  transition: all 1s;

  @media (max-width: 480px) {
    padding: 1rem;
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

interface IPropsContentInput {
  suffix?: boolean;
}

export const ContentInput = styled.div<IPropsContentInput>`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 100%;
  input {
    background: transparent;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #909090;
    color: ${(props) => props.theme['gray-700']};
    padding-right: ${(props) => (props.suffix ? '3rem' : '0.5rem')};
    width: 100%;
    font-size: 0.875rem;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: ${(props) => props.theme['gray-700']};
    background-color: white;

    :hover,
    :focus {
      border: 1px solid ${(props) => props.theme.primary};
    }
  }

  i {
    position: absolute;
    right: 0.5rem;
    display: flex;
  }

  .useOfTerms {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    span {
      color: #6a717e;
      /* Body/Small/Regular */
      font-family: 'Poppins',sans-serif;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      a {
        text-decoration: none;
        color: #EC6643;
      }
    }
  }
`;

export const RowContentInput = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
`;

interface IPropsButton {
  background: string;
}

export const Button = styled.button<IPropsButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.813rem 0;
  background-color: ${(props) => props.theme['primary-800']};
  color: ${(props) => props.color};
  border: none;
  outline: none;
  box-shadow: 4px 4px 12px 0px #4c4c4c26;
  border-radius: 4px;
  width: 100%;
  transition: filter 0.3s;
  font-size: 1rem;
  line-height: 150%;
  font-weight: 500;

  :disabled {
    background: #1b0c45;
    cursor: not-allowed;
  }

  :hover {
    filter: brightness(0.9);
  }

  div {
    display: flex;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  overflow-y: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 750px;
  @media (max-width: 675px) {
    flex-direction: column-reverse;
  }
`;
export const Cards = styled.div`
  min-width: 470px;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ContentButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 1rem;
    color: ${(props) => props.theme.white};

    a {
      text-decoration: underline;
      color: ${(props) => props.theme.white};
    }
  }
`;

export const ValidationPassword = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  flex-wrap: wrap;
`;

interface IPropsInfoValidation {
  error: boolean;
}
export const InfoValidationPassword = styled.li<IPropsInfoValidation>`
  font-family: Poppins, sans-serif;
  list-style: inside;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  color: ${(props) => props.theme.black};
  font-size: 0.75rem;
  align-items: center;
  text-decoration: ${(props) => props.error && 'line-through'};

  span {
    width: 0.25rem;
    height: 0.25rem;
    background-color: ${(props) => props.theme['gray-700']};
    border-radius: 999px;
  }
`;

export const AsideContent = styled.aside`
  height: 100vh;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
