import { useState } from 'react';
import { Content, NewsContainer } from './styles';

import logo from '../../../../../../../assets/logoWhite.svg';
import { NewsComponent } from '../../../NewsComponent';
import { ModalDesigner } from '../../../../../../../components/ModalDesigner';

export function NewsComponentDash() {
  const [newAtt, setNewAtt] = useState(false);
  return (
    <>
      <NewsContainer onClick={() => setNewAtt(true)}>
        <div className="redContentColor" />
        <div className="blueContentColor" />
        <div className="yellowContentColor" />
        <Content>
          <img src={logo} alt="" />
          <p>Fique por dentro das últimas novidades do Ibox6</p>
        </Content>
      </NewsContainer>
      <ModalDesigner
        id="Novidades"
        isOpen={newAtt}
        setState={setNewAtt}
        title="Novidades"
      >
        <NewsComponent isComponent />
      </ModalDesigner>
    </>
  );
}
