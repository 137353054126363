import axios from 'axios';
import { useEffect, useState } from 'react';
import { InputComponent } from '../../../../../components/InputComponent';
import { Brokerages } from '../../components/Brokerages/intex';
import {
  ManualImportContainer,
  ContentTable,
  TypeActiveContent,
  EditActive,
  DeleteActive,
  DeleteAllActives,
  CompanyNameContent,
} from './styles';
import { IPropsPositionAll } from '../../../../../utils/@types/wallet';
import { IPropsMattress } from '../../../../components/Boltz/MyWallet/Mattress/@types';
import { financeRoutes } from '../../../../../utils/RoutesApi';
import { baseURL } from '../../../../../utils/baseURL';
import { TableSelectedMattress } from '../../../../components/Boltz/MyWallet/Mattress/components/TableSelectedMattress';

const header = [
  { label: 'Ativo', active: 'tickerSymbol' },
  { label: 'Adicionar/Remover' },

  { label: 'Total', active: 'updateValue' },
];

export interface IPropsAllPosition extends IPropsPositionAll {
  key: string;
}

export function EmergenceReserve() {
  const [filterTextSearchActive, setFilterTextSearchActive] = useState('');

  const [dataAllActive, setDataAllActive] = useState<IPropsAllPosition[]>([]);

  useEffect(() => {
    getActives();
  }, []);

  async function getActives() {
    let arrMattress: IPropsMattress[] = [];
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_EMERGENCY_RESERVE}`, {
        params: {
          page_size: 100,
        },
      })
      .then((response) => {
        arrMattress = response.data.data;
      })
      .catch((err) => {});

    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_POSITION}`)
      .then((response) => {
        let newData: IPropsAllPosition[] = response.data.positionsAll;
        let totalReduce = response.data.positionsAll.filter(
          //@ts-ignore
          (item) => item.reservadeemergencia
        );
        //@ts-ignore

        for (var mattress in arrMattress) {
          newData.map((item) => {
            if (item.tickerSymbol === arrMattress[mattress].ativo) {
              item.key = arrMattress[mattress].key;
            }
            return item;
          });
        }
        newData = newData.filter(
          (item) =>
            item.productTypeName === 'tesourodireto' ||
            item.productTypeName === 'rendafixa'
        );
        setDataAllActive(newData);
      })
      .catch((err) => {});
  }

  function handleUpdateMattress(id: string) {
    const newState = dataAllActive.map((item) => {
      if (item.tickerSymbol === id) {
        return {
          ...item,
          reservadeemergencia: !item.reservadeemergencia,
        };
      }
      return item;
    });

    setDataAllActive(newState);
  }

  const ArrayFilteredAllTabe = dataAllActive?.filter((item) => {
    if (
      item.tickerSymbol
        .toLowerCase()
        .includes(filterTextSearchActive.toLocaleLowerCase()) ||
      item.corporationName
        .toLowerCase()
        .includes(filterTextSearchActive.toLocaleLowerCase())
    ) {
      return item;
    }
  });

  return (
    <ManualImportContainer>
      {window.screen.width > 768 && <Brokerages />}
      <InputComponent
        isSearch
        placeholder="Busque por código ou nome da empresa"
        roundedFull
        onChange={(e) => setFilterTextSearchActive(e.target.value)}
      />
      <ContentTable>
        <header>
          <span>Seus ativos</span>
        </header>
        <TableSelectedMattress
          ASC={(newValue: IPropsAllPosition[]) => {
            setDataAllActive(newValue);
          }}
          DESC={(newValue: IPropsAllPosition[]) => {
            setDataAllActive(newValue);
          }}
          header={header}
          dataTable={ArrayFilteredAllTabe}
          onSubmitUpdateMattress={(id) => handleUpdateMattress(id)}
        />
      </ContentTable>
    </ManualImportContainer>
  );
}
