import { Eye, EyeSlash } from '@phosphor-icons/react';
import { ButtonDesign } from '../../../../../../../components/ButtonDesign';
import {
  ContentForm,
  ContentInput,
  InfoValidationPassword,
  ValidationPassword,
} from './styles';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { handleToast } from '../../../../../../../components/Toast';
import axios from 'axios';
import { baseURL } from '../../../../../../../utils/baseURL';
import { userRoutes } from '../../../../../../../utils/RoutesApi';
import { Popover } from 'antd';

export function ChangePassword() {
  const {
    handleSubmit,
    register,

    watch,
    formState: { isSubmitting },
  } = useForm();
  const [viewOldPassword, setViewOldPassword] = useState<boolean>(false);
  const [viewNewPassword, setViewNewPassword] = useState<boolean>(false);
  const [viewNewPasswordConfirm, setViewNewPasswordConfirm] =
    useState<boolean>(false);

  const watchPasswords = watch('password');
  const validateLettersInPassword = !!watchPasswords?.match(/[A-z]/);
  const validateNumberInPassword = !!watchPasswords?.match(/[0-9]/);
  const validateSpecialCharactersInPassword = !!watchPasswords?.match(
    /[!|@|#|$|%|^|&|*|(|)|-|_]/
  );

  //@ts-ignore
  function handleValidatePassword(_data) {
    if (!_data.current_password) {
      return handleToast(true, 'Informe a senha atual');
    }
    if (!_data.password) {
      return handleToast(true, 'Informe a nova senha');
    }
    if (_data.password.length < 8) {
      return handleToast(true, 'a senha deve conter pelo menos 8 caracteres');
    }
  }

  //@ts-ignore
  async function submitPassword(data) {
    if (handleValidatePassword(data)) {
      return;
    }

    await axios
      .put(`${baseURL}${userRoutes.USER_UPDATE_PASSWORD}`, data)
      .then((response) => {
        handleToast(false, 'Senha atualizado com sucesso');

        return;
      })
      .catch((err) => {
        if (err.response.data.password2) {
          return handleToast(true, 'As senhas são diferentes');
        } else if (err.response.data.current_password) {
          return handleToast(true, 'Senha atual incorreta');
        }
      });
  }

  return (
    <ContentForm onSubmit={handleSubmit(submitPassword)}>
      <ContentInput>
        <label>Senha antiga</label>

        <i>
          {!viewOldPassword ? (
            <Eye
              size={24}
              color="black"
              onClick={() => setViewOldPassword(true)}
            />
          ) : (
            <EyeSlash
              size={24}
              color="black"
              onClick={() => setViewOldPassword(false)}
            />
          )}
        </i>
        <input
          type={!viewOldPassword ? 'password' : 'text'}
          {...register('current_password')}
          className="password"
        />
      </ContentInput>
      <Popover
        content={
          <ValidationPassword>
            <InfoValidationPassword error={watchPasswords?.length >= 8}>
              <span />
              Deve ter no mínimo 8 caracteres{' '}
            </InfoValidationPassword>
            <InfoValidationPassword error={validateNumberInPassword}>
              <span />
              Deve ter no mínimo 1 número
            </InfoValidationPassword>
            <InfoValidationPassword error={validateLettersInPassword}>
              <span />
              Deve ter no mínimo 1 letra
            </InfoValidationPassword>

            <InfoValidationPassword error={validateSpecialCharactersInPassword}>
              <span />
              Deve ter no mínimo 1 símbolo ex: @#$!{' '}
            </InfoValidationPassword>
          </ValidationPassword>
        }
        title="Crie uma senha segura"
        trigger="focus"
      >
        <ContentInput>
          <label>Nova senha</label>

          <i>
            {!viewNewPassword ? (
              <Eye
                size={24}
                color="black"
                onClick={() => setViewNewPassword(true)}
              />
            ) : (
              <EyeSlash
                size={24}
                color="black"
                onClick={() => setViewNewPassword(false)}
              />
            )}
          </i>

          <input
            type={viewNewPassword ? 'text' : 'password'}
            {...register('password')}
            className="password"
          />
        </ContentInput>
      </Popover>

      <ContentInput>
        <label>Confirmar senha</label>

        <i>
          {!viewNewPasswordConfirm ? (
            <Eye
              size={24}
              color="black"
              onClick={() => setViewNewPasswordConfirm(true)}
            />
          ) : (
            <EyeSlash
              size={24}
              color="black"
              onClick={() => setViewNewPasswordConfirm(false)}
            />
          )}
        </i>

        <input
          type={viewNewPasswordConfirm ? 'text' : 'password'}
          {...register('password2')}
          className="password"
        />
      </ContentInput>
      <br />
      <ButtonDesign
        style={{ width: 'calc(100% - 2rem - 4px)', maxWidth: 'none' }}
        type="submit"
        disabled={isSubmitting}
      >
        Atualizar
      </ButtonDesign>
    </ContentForm>
  );
}
