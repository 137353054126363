import { Tooltip } from 'antd';
import {
  BottomCardHeader,
  BottomCardInfo,
  CardInfoValue,
  GraphicCotationContainer,
} from './styles';
import { Info } from '@phosphor-icons/react';
import Chart from '../../Chart';
import ReactLoading from 'react-loading';
import { CardGraphicLoader } from '../../../../jsx/components/LoadingModels';

export interface IPropsDataCotation {
  adj_close: number;
  close: number;
  date: Date;
  max: number;
  min: number;
  open: number;
  volume: number;
}

interface IPropsGraphicCotation {
  label: string;
  helpText: string;
  value: string | number;
  data: IPropsDataCotation[];
  fillcolor: string;
  openModal: Function;
  loading: boolean;
}

export function GraphicCotation({
  data,
  helpText,
  label,
  value,
  fillcolor,
  openModal,
  loading,
}: IPropsGraphicCotation) {
  return (
    <GraphicCotationContainer onClick={() => openModal()}>
      {loading ? (
        <CardGraphicLoader />
      ) : (
        <>
          <BottomCardHeader>
            <span>{label}</span>
            <Tooltip title={helpText}>
              <Info size="1rem" color="var(--primary)" />
            </Tooltip>
          </BottomCardHeader>
          <BottomCardInfo>
            <CardInfoValue>{value}</CardInfoValue>
          </BottomCardInfo>
          <Chart
            data={data.map((item) => item.close)}
            fillcolor={fillcolor}
            height={60}
          />
        </>
      )}
    </GraphicCotationContainer>
  );
}
