import {
  ComponentProps,
  ElementRef,
  MouseEventHandler,
  forwardRef,
} from 'react';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { Container, Input, FixContent } from './styles';

interface IPropsInputComponent extends ComponentProps<typeof Input> {
  isSearch?: boolean;
  isOutline?: boolean;
  isError?: boolean;
  suffix?: JSX.Element;
  preFix?: JSX.Element;
  roundedFull?: boolean;

  buttonProps?: {
    type: 'button' | 'submit';
    onClick: MouseEventHandler<HTMLButtonElement>;
    title: string;
  };
}

/**
 *
 * @param isSearch Identificar se o input é do tipo busca, ira adicionar um botão no final do input com a lupa para o search
 * @param isOutline Adiciona uma borda cinza em volta do input
 * @param isError Adiciona uma borda vermelha em volta do input
 * @param suffix Você pode adicionar um ícone ou um texto no final do input
 * @param suffix Você pode adicionar um ícone ou um texto no antes do input
 * @param roundedFull Deixar as bordas todas arredondadas
 * @param buttonProps:{
 *  @param type O tipo do botão sendo "button" | "submit"
 *  @param onClick Para passar a função no onClick
 *  @param title colocar um texto no :hover do icone
 * }
 *
 */

export const InputComponent = forwardRef<
  ElementRef<typeof Input>,
  IPropsInputComponent
>(
  (
    {
      isSearch = false,
      isOutline = false,
      isError = false,
      roundedFull = false,
      buttonProps,
      suffix,
      preFix,
      ...props
    }: IPropsInputComponent,
    ref
  ) => {
    return (
      <Container
        isSearch={isSearch}
        isOutline={isOutline}
        isError={isError}
        roundedFull={roundedFull}
      >
        {preFix && <FixContent>{preFix}</FixContent>}
        <Input ref={ref} {...props} />
        <button
          type={buttonProps?.type}
          onClick={buttonProps?.onClick}
          title={buttonProps?.title}
        >
          <MagnifyingGlass />
        </button>
        {suffix && <FixContent>{suffix}</FixContent>}
      </Container>
    );
  }
);

InputComponent.displayName = 'InputComponent';
