import { HTMLAttributes } from 'react';

import { useActives } from '../../../hooks/useActives';
import { Container } from './styles';

import { transformMaskCurrency } from '../../../utils/transformMaskCurrency';
import { useTitleAndUser } from '../../../hooks/userTitle';

interface IPropsCardHomeData extends HTMLAttributes<HTMLDivElement> {
  title: string;
  value: number | string;
  isVideo?: boolean;
  isReceived?: boolean;
  diff?: number;
}

export function CardHomeData({
  title,
  value,
  isVideo = false,
  diff = 0,
  ...props
}: IPropsCardHomeData) {
  //@ts-ignore
  const { showActive } = useActives();
  //@ts-ignore
  const { permissions } = useTitleAndUser();
  const isBlocked = permissions.includes('Grátis');
  const isAdmin = permissions.includes('Admin');

  const maskValue = !isVideo && transformMaskCurrency(Number(value));

  return (
    <Container
      isBlocked={!isAdmin && isBlocked}
      {...props}
      title={
        !isAdmin &&
        isBlocked &&
        'Você precisa ter uma assinatura para visualizar os dados'
      }
    >
      <span>{title}</span>
      {isVideo ? (
        <h1
          style={{
            color: diff <= 30 ? 'green' : 'red',
          }}
        >
          {value !== '-% ' ? <>{value}</> : '-'}
        </h1>
      ) : (
        <h1>{showActive ? maskValue : 'R$ *******'}</h1>
      )}
    </Container>
  );
}
