import { useEffect, useState } from 'react';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { ButtonTab, SimuladoresContainer, TabPagesContent } from './styles';
import { JurosCompostos } from './JurosCompostos';
import { CalculadoraJurosEquivalente } from './CalculadoraJurosEquivalente';
import { CalculatorCDI } from './CalculatorCDI';

export function Simulators() {
  //@ts-ignore
  const { setTitle } = useTitleAndUser();
  const [tabDefine, setTabDefine] = useState<
    'compostos' | 'equivalentes' | 'cdi'
  >('compostos');

  useEffect(() => {
    if (!location.pathname.includes('un-logged')) setTitle('Simuladores');
  }, []);

  return (
    <SimuladoresContainer>
      <TabPagesContent>
        <ButtonTab
          isActive={tabDefine === 'compostos'}
          onClick={() => setTabDefine('compostos')}
        >
          Juros compostos
        </ButtonTab>
        <ButtonTab
          isActive={tabDefine === 'equivalentes'}
          onClick={() => setTabDefine('equivalentes')}
        >
          Juros equivalentes
        </ButtonTab>
        <ButtonTab
          isActive={tabDefine === 'cdi'}
          onClick={() => setTabDefine('cdi')}
        >
          Calcular CDI
        </ButtonTab>
      </TabPagesContent>
      {tabDefine === 'compostos' && <JurosCompostos />}
      {tabDefine === 'equivalentes' && <CalculadoraJurosEquivalente />}
      {tabDefine === 'cdi' && <CalculatorCDI />}
    </SimuladoresContainer>
  );
}
