import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { handleToast } from '../../../../components/Toast';
import { financeRoutes } from '../../../../utils/RoutesApi';
import { Container, Content } from './styles';
import { CalcButton } from '../JurosCompostos/styles';

export function CalculatorCDI() {
  const { handleSubmit, register, setValue } = useForm();
  const [result, setResult] = useState<number>(0);

  const [jurosEquivalente, setJurosEquivalente] = useState<number>(0);
  useEffect(() => {
    getCDIValue();
  }, []);

  async function getCDIValue() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_CDI}`)
      .then((response) => {
        setValue('cdiTaxa', response.data.cdi);
      })
      .catch((err) => {});
  }

  function submitForm(data: any) {
    if (!data.cdiAdd) {
      return handleToast(true, 'Informe o rendimento do CDI');
    }

    let calc = (data.cdiTaxa * Number(data.cdiAdd)) / 100;
    let taxJuros = calc / 100;
    let periodo = 1 * 12;

    let a = 1 + taxJuros;
    let potencial = 1 / periodo;
    let b = Math.pow(a, potencial);
    b = b - 1;
    b = b * 100;
    setJurosEquivalente(parseFloat(b.toFixed(4) ?? 0));

    setResult(Number(calc.toFixed(4)));
  }

  return (
    <Container>
      <Content>
        <div className="contentResult">
          <p className="result">
            Juros anual: <strong>{result}%</strong>{' '}
          </p>

          <p className="result">
            Juros mensal <strong>{jurosEquivalente}%</strong>
          </p>
        </div>

        <form onSubmit={handleSubmit(submitForm)}>
          <div className="contentForm">
            <div className="step1">
              <label htmlFor="cdiAdd">Porcentagem do rendimento</label>
              <input type="number" id="cdiAdd" {...register('cdiAdd')} />
              <i>%</i>
            </div>
            <div className="step2">
              <label htmlFor="cdiTaxa">CDI anual</label>
              <input
                type="number"
                id="cdiTaxa"
                {...register('cdiTaxa')}
                disabled
              />
            </div>
          </div>
          <div className="contentButton">
            <CalcButton
              isOutline
              type="button"
              onClick={() => setValue('cdiAdd', 0)}
            >
              LIMPAR
            </CalcButton>
            <CalcButton type="submit">CALCULAR</CalcButton>
          </div>
        </form>
      </Content>
    </Container>
  );
}
