import Countdown, { CountdownRenderProps } from 'react-countdown';
import moment from 'moment';

import { CountDownContainer, CountdownContent } from './styles';
import { useEffect, useState } from 'react';
import { X } from '@phosphor-icons/react';
import { useTitleAndUser } from '../../../hooks/userTitle';
import axios from 'axios';
import { userRoutes } from '../../../utils/RoutesApi';

interface IPropsPlan {
  NomePlano: string;
  Status: string;
  StatusPagamentoCorrente: string;
  SubscriptionId: string;
  UserName: string;
  Valor: number;
  cardLastDigitsCard: number | null;
  card_brand: number | null;
  currentPeriodEnd: string;
  currentPeriodStart: string;
  manageUrl: string;
  paymentMethod: string;
}

export function CountDownPlan() {
  //@ts-ignore
  const { user, permissions } = useTitleAndUser();
  const isTrial = permissions.includes('Trial');
  const isFreePlan = permissions.includes('Grátis');
  const isAdmin = permissions.includes('Admin');
  //@ts-ignore
  const [plan, setPlan] = useState<IPropsPlan | null>({});
  const [showCountdown, setShowCountdown] = useState(false);

  useEffect(() => {
    handlePayment();
  }, []);
  useEffect(() => {
    if (isAdmin) {
      return setShowCountdown(false);
    } else if (isTrial || isFreePlan) {
      return setShowCountdown(true);
    } else if (
      plan?.Status === 'canceled' &&
      moment(plan?.currentPeriodEnd).diff(moment(), 'days') < 30
    ) {
      setShowCountdown(true);
    } else {
      setShowCountdown(false);
    }
  }, [plan, isTrial]);

  async function handlePayment() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}${userRoutes.STATUS_PAYMENT}`)
      .then((response) => {
        setPlan(response.data);
      })
      .catch((err) => {
        //@ts-ignore

        setPlan(null);
      });
  }

  const renderer = ({ days, hours, minutes }: CountdownRenderProps) => {
    return (
      <p>
        <strong>{days}</strong> <span>d</span> <strong>{hours}</strong>{' '}
        <span>h</span> <strong>{minutes}</strong> <span>m</span>
      </p>
    );
  };
  return (
    <CountDownContainer showCountdown={showCountdown}>
      <header>
        {isFreePlan ? (
          <h1>Voltar ao premium</h1>
        ) : (
          <Countdown
            date={
              isTrial && user.date_trial
                ? new Date(user.date_trial)
                : plan && plan.Status === 'canceled'
                ? new Date(plan.currentPeriodEnd)
                : new Date()
            }
            renderer={renderer}
          />
        )}

        <button type="button" onClick={() => setShowCountdown(false)}>
          <X weight="bold" />
        </button>
      </header>
      <div
        className="countdownContent"
        onClick={() => (window.location.pathname = 'pagamentos')}
      >
        {
          <p>
            {isFreePlan
              ? 'Volte a ter acesso as 6 funciondades do iBox6 agora mesmo clicando aqui'
              : isTrial
              ? moment(user.date_trial).diff(moment(), 'days') < 15
                ? 'Parece que teste está chegando ao fim'
                : 'Você tem acesso as 6 funcionalidades'
              : plan && plan.Status === 'canceled' && plan.currentPeriodEnd
              ? moment(plan?.currentPeriodEnd).diff(moment(), 'days') < 30
                ? 'Parece que seu plano está chegando ao fim'
                : 'Incrível você ainda tem muitos dias no seu plano'
              : 'Ops! parece que estamos com problema para identificar o fim do seu plano'}
          </p>
        }
      </div>

      <div className="redContentColor" />
      <div className="blueContentColor" />
      <div className="yellowContentColor" />
    </CountDownContainer>
  );
}
