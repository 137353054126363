import StringMask from 'string-mask';
import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { chartHelpText } from '../../social/constants';
import BottomCard from './BottomCard';
import ListFatosRelevantes from '../ListFatosRelevantes';
import { NewGraphicData } from './components/graphics/NewGraphicData';
import AcoesPainel from '../../jsx/components/Dashboard/AcoesPainel';
import { financeRoutes } from '../../utils/RoutesApi';
import { useTitleAndUser } from '../../hooks/userTitle';
import { maskValueCards } from './components/maskValueCards';
import { CompanyIndexes } from './components/CompanyIndexes';
import { defaultValueCompany } from './components/defaultValue/initialValueCompany';
import { ExplanationAssetNotes } from './components/ExplanationAssetNotes';
import { AnnualAssetClose } from './components/AnnualAssetClose';
import { GlobalContainer, ContentGraphics } from './styles';
import './modalstyles.css';

import { CompareAssets } from '../../jsx/components/Dashboard/CompareAssets';
import { AnalysisActionLoader } from '../../jsx/components/LoadingModels';
import { transformMaskCurrency } from '../../utils/transformMaskCurrency';
import { AboutCompany } from './components/AboutCompany';
import { ModalDesigner } from '../ModalDesigner';
import { MainCardAnalysisAction } from './components/MainCard';
import { GraphicCotation } from './components/GraphicCotation';
import { GraphicCotationHistory } from './components/graphics/GraphicCotationHistory';

const baseURL = process.env.REACT_APP_API_URL;

export const ResumoAcao = ({
  slug,
  clearActive = (slug, type) => {},
  setSelectedActive = (slug, type) => {},
}) => {
  const { setTitle } = useTitleAndUser();

  const [loading, setLoading] = useState(false);
  const [modalFatos, setModalFatos] = useState(false);
  const [modalLucro, setModalLucro] = useState(false);
  const [modalEbitida, setModalEbitida] = useState(false);
  const [modalDivida, setModalDivida] = useState(false);
  const [modalDividaEbitda, setModalDividaEbitda] = useState(false);
  const [modalROE, setModalROE] = useState(false);
  const [modalDividendos, setModalDividendos] = useState(false);
  const [showExplanationAssetNotes, setShowExplanationAssetNotes] =
    useState(false);
  const [showModalAboutCompany, setShowModalAboutCompany] = useState(false);
  const [loadingHistoryCotantion, setLoadingHistoryCotantion] = useState(false);
  const [dataAndLinks, setDataAndLinks] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [closePrice, setClosePrice] = useState([]);
  const [dataGraphics, setDataGraphics] = useState({
    consolidado: [],
    lucroLiquidoArr: [],
    ebitdaArr: [],
    dividaLiquida: [],
    dividaLIquidaEbitdaArr: [],
    roeArr: [],
    dyArr: [],
  });
  const [showProfitXQuotation, setShowProfitXQuotation] = useState(false);
  const [companyInfos, setCompanyInfos] = useState(defaultValueCompany);
  const [modalIndexes, setModalIndexes] = useState(false);
  const [modalCompareAssets, setModalCompareAssets] = useState(false);
  const [historyCotation, setHistoryCotation] = useState([]);
  const [retornosCotacao, setRetornosCotacao] = useState({
    annualized_return: 0,
    five_year_return: 0,
    total_return: 0,
  });
  const [showModalCotation, setShowModalCotation] = useState(false);
  const [filtersHistoryCotation, setFiltersHistoryCotation] = useState({
    period: 'MAX',
  });
  const [payoutData, setPayoutData] = useState({
    asset: '',
    payout: 0,
  });

  useEffect(() => {
    getDataCompany();
    getValuesCompany();
    getDataGraphics();
    getDataCompanyNewRoute();
    getPayout();
  }, [location.pathname, slug]);
  useEffect(() => {
    setTitle(companyData.NomeAtivo ? companyData.NomeAtivo : 'Ação');
  }, [companyData]);

  useEffect(() => {
    getClosePriceLongAgo(filtersHistoryCotation);
  }, [location.pathname, slug, filtersHistoryCotation]);

  async function getPayout() {
    try {
      const response = await axios.get(
        `${baseURL}${financeRoutes.FINANCE_PAYOUT(slug.replace('.SA', ''))}`
      );
      setPayoutData(response.data);
    } catch (error) {}
  }

  async function getClosePriceLongAgo(params = {}) {
    setLoadingHistoryCotantion(true);

    try {
      const response = await axios.get(
        `${baseURL}${financeRoutes.FINANCE_COTATION_LONG_TIME}`,
        {
          params: {
            ticker: slug.replace('.SA', ''),
            period: params.period,
          },
        }
      );
      setRetornosCotacao(response.data.slice(response.data.length - 1)[0]);
      setHistoryCotation(
        response.data.slice(0, response.data.length - 1).sort((a, b) => {
          if (a.date < b.date) {
            return -1;
          }
          return true;
        })
      );
    } catch (error) {}
    setLoadingHistoryCotantion(false);
  }

  async function getDataCompanyNewRoute() {
    let data = {
      lucroliquido: 0,
      roe: 0,
      ebitda: 0,
      dividendos: 0,
      dividaliquida: 0,
      dividaebitda: 0,
      cnpjativo: '',

      setor: '',
      segmento: '',
      nome_empresa: slug.replace('.SA', ''),
      subsetor: '',
      segmento_listagem: '',
    };
    await axios
      .post(`${baseURL}${financeRoutes.FINANCE_RANK_ACTIVES_LIST}`, data)
      .then((response) => {
        let newDataResponse = response.data.data[0];
        const mainAsset = newDataResponse.Assets.split('|').sort(
          (a, b) =>
            Number(a.replace(/[a-zA-Z]/g, '')) -
            Number(b.replace(/[a-zA-Z]/g, ''))
        );
        newDataResponse.Assets = mainAsset;
        newDataResponse.SegmentoMercado = editTextSegmentoList(
          newDataResponse.SegmentoMercado
        );

        newDataResponse.linkB3 = `https://sistemaswebb3-listados.b3.com.br/listedCompaniesPage/main/${newDataResponse.CD_CVM}/overview/overview?language=pt-br`;
        newDataResponse.freeFloat = FormatfreeFloat(
          newDataResponse.PorcentagemON,
          newDataResponse.PorcentagemPN,
          newDataResponse.PorcentagemTotal
        );
        newDataResponse.tagAlong = FormatTagAlong(
          newDataResponse.SegmentoMercado
        );
        setClosePriceForActive(newDataResponse.Assets);
        setCompanyInfos(newDataResponse);
      })
      .catch((err) => {});
  }
  function editTextSegmentoList(value) {
    if (value === 'N2') {
      return 'Nível 2';
    }
    if (value === 'N1') {
      return 'Nível 1';
    }
    if (value === 'NM') {
      return 'Novo Mercado';
    }
    if (value === 'MA') {
      return 'Bovespa Mais';
    }

    return 'Tradicional';
  }

  async function setClosePriceForActive(assets) {
    let closePriceForActive = [];
    for (var tck in assets) {
      await axios
        .get(`${baseURL}${financeRoutes.FINANCE_PRICE_ACTION}`, {
          params: { search: assets[tck] },
        })
        .then((response) => {
          const priceCost = transformMaskCurrency(response.data[0].Close);
          const newItemPrice = {
            asset: assets[tck],
            priceCost: priceCost,
          };

          closePriceForActive = [...closePriceForActive, newItemPrice];
          return closePriceForActive;
        })
        .catch((err) => {});
    }
    setClosePrice(closePriceForActive);
    return closePriceForActive;
  }

  const formatPercentFloat = (value) => {
    if (typeof value === 'string')
      return `${parseInt(value.replace('-', ''))}%`;

    if (value < 0) return `${parseFloat(value * -1).toFixed(2)}%`;
    return `${parseFloat(value).toFixed(2)}%`;
  };
  async function getDataGraphics() {
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_FUNDAMENTALIST}`, {
        params: { ativo: `${slug.replace('.SA', '')}` },
      })
      .then((response) => {
        let newData = response.data;

        newData.lucroLiquidoArr = newData.consolidado.map((item) => {
          return { value: item.LucroLiquido, Ano: item.Ano };
        });
        newData.lucroLiquidoArr = newData.lucroLiquidoArr.filter(
          (item) => item.value !== 0
        );
        newData.ebitdaArr = newData.consolidado.map((item) => {
          return { Ano: item.Ano, value: item.EBITDA };
        });
        newData.ebitdaArr = newData.ebitdaArr.filter(
          (item) => item.value !== 0
        );
        newData.dividaLiquida = newData.consolidado.map((item) => {
          return { Ano: item.Ano, value: item.DividaLiquida };
        });
        newData.dividaLiquida = newData.dividaLiquida.filter(
          (item) => item.value !== 0
        );
        newData.dividaLIquidaEbitdaArr = newData.consolidado.map((item) => {
          return { Ano: item.Ano, value: item.DividaLIquidaEbitda };
        });
        newData.dividaLIquidaEbitdaArr = newData.dividaLIquidaEbitdaArr.filter(
          (item) => item.value !== 0
        );
        newData.roeArr = newData.consolidado.map((item) => {
          return { Ano: item.Ano, value: item.ROE };
        });
        newData.roeArr = newData.roeArr.filter((item) => item.value !== 0);
        newData.dyArr = newData.consolidado.map((item) => {
          return { Ano: item.Ano, value: item.DY };
        });
        newData.dyArr = newData.dyArr.filter((item) => item.value !== 0);
        setDataGraphics(newData);
      })
      .catch((err) => {});
  }
  async function getDataCompany() {
    setLoading(true);

    let cnpj;
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_CNPJ_ASSET}`, {
        params: { search: slug.substring(0, 4) },
      })
      .then((response) => {
        cnpj = response.data[0].CNPJ;
        if (cnpj.length === 13) {
          cnpj = `0${cnpj}`;
        }
      })
      .catch((err) => {
        setLoading(false);
      });
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_COMPANY}`, {
        params: { search: cnpj },
      })
      .then((response) => {
        var formatter = new StringMask('00.000.000/0000-00', {
          reverse: true,
        });

        let dataCompanyEdit = response.data[0];
        dataCompanyEdit.CNPJ_CIA = formatter.apply(dataCompanyEdit.CNPJ_CIA);
        dataCompanyEdit.linkB3 = `https://sistemaswebb3-listados.b3.com.br/listedCompaniesPage/main/${dataCompanyEdit.CD_CVM}/overview/overview?language=pt-br`;

        const date = moment(dataCompanyEdit.DT_INI_SIT).year();
        const today = moment().year();
        dataCompanyEdit.tempoEmBolsa = today - date;

        setDataAndLinks(dataCompanyEdit);
      })
      .catch((err) => {
        setLoading(false);
      });
    setLoading(false);
  }

  async function getValuesCompany() {
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_CHART_CONSOLIDATED}`, {
        params: { search: slug },
      })
      .then((response) => {
        let newData = response.data[0];
        (newData.freeFloat = FormatfreeFloat(
          newData.PorcentagemON,
          newData.PorcentagemPN,
          newData.PorcentagemTotal
        )),
          (newData.tagAlong = FormatTagAlong(newData.SegmentoMercado));
        setCompanyData(newData);
      })
      .catch((err) => {});
  }

  const FormatfreeFloat = (PorcentagemON, PorcentagemPN, PorcentagemTotal) => {
    let retorno_free_float = 0;
    if (Number(PorcentagemPN) > 0) {
      if (Number(PorcentagemON) > 0) {
        retorno_free_float = PorcentagemON.toFixed(2) + '% ON';
        retorno_free_float = retorno_free_float + ' - ';
      }
      retorno_free_float =
        '' + retorno_free_float + PorcentagemPN.toFixed(2) + '% PN - ';
      retorno_free_float =
        '' + retorno_free_float + PorcentagemTotal.toFixed(2) + '% Total';
    } else if (Number(PorcentagemON) > 0) {
      retorno_free_float = PorcentagemON.toFixed(2) + '% ON';
    }
    return retorno_free_float;
  };

  const FormatTagAlong = (seguimentolistagem) => {
    if (seguimentolistagem == 'Novo Mercado') {
      return '100% ON';
    }

    if (seguimentolistagem == 'Nível 1') {
      return '80% ON / 0% PN';
    }

    if (seguimentolistagem == 'Nível 2') {
      return '100% ON / 100% PN';
    }

    if (seguimentolistagem == 'Bovespa Mais') {
      return '100% ON';
    }

    return '80% ON / 0% PN';
  };

  const defineChartCollor = (arr) => {
    const ok = '#52C41A';
    const danger = '#C61722';

    if (arr[arr.length - 1]?.value > arr[0]?.value) {
      return ok;
    }
    return danger;
  };
  const defineChartCollorHistoryCotation = (arr) => {
    return '#3cd2f7';
  };
  const defineDividaChartCollor = (arr) => {
    const ok = '#52C41A';
    const danger = '#C61722';

    if (arr[arr.length - 1]?.value > arr[0]?.value) {
      return danger;
    } else {
      return ok;
    }
  };
  const defineDividaEbitdaChartCollor = (arr) => {
    const ok = '#52C41A';
    const danger = '#C61722';
    if (arr[arr.length - 1]?.value < 0) {
      return ok;
    } else if (arr[arr.length - 1]?.value > arr[0]?.value) {
      return danger;
    } else {
      return ok;
    }
  };

  return loading ? (
    <AnalysisActionLoader />
  ) : (
    <>
      <GlobalContainer>
        <MainCardAnalysisAction
          clearActive={clearActive}
          closePrice={closePrice}
          companyInfos={companyInfos}
          dataAndLinks={dataAndLinks}
          setModalFatos={setModalFatos}
          setShowExplanationAssetNotes={setShowExplanationAssetNotes}
          setModalCompareAssets={setModalCompareAssets}
          setModalIndexes={setModalIndexes}
          setShowModalAboutCompany={setShowModalAboutCompany}
          setShowProfitXQuotation={setShowProfitXQuotation}
        />
        <ContentGraphics>
          <BottomCard
            openModal={setModalLucro}
            label="Lucro Líquido"
            helpText={chartHelpText.lucro}
            data={dataGraphics.lucroLiquidoArr}
            fillcolor={defineChartCollor(dataGraphics.lucroLiquidoArr)}
            value={maskValueCards(
              dataGraphics.lucroLiquidoArr[
                dataGraphics.lucroLiquidoArr.length - 1
              ]?.value
                ? dataGraphics.lucroLiquidoArr[
                    dataGraphics.lucroLiquidoArr.length - 1
                  ]?.value
                : 0
            )}
            title={transformMaskCurrency(
              dataGraphics.lucroLiquidoArr[
                dataGraphics.lucroLiquidoArr.length - 1
              ]?.value
                ? dataGraphics.lucroLiquidoArr[
                    dataGraphics.lucroLiquidoArr.length - 1
                  ]?.value
                : 0
            )}
          />
          {companyInfos.segmento !== 'Bancos' && (
            <BottomCard
              openModal={setModalEbitida}
              helpText={chartHelpText.ebitida}
              label="EBITDA"
              data={dataGraphics.ebitdaArr}
              fillcolor={defineChartCollor(dataGraphics.ebitdaArr)}
              value={maskValueCards(
                dataGraphics.ebitdaArr[dataGraphics.ebitdaArr.length - 1]
                  ?.value ?? 0
              )}
              title={transformMaskCurrency(
                dataGraphics.consolidado[dataGraphics.consolidado.length - 1]
                  ?.value ?? 0
              )}
            />
          )}
          {companyInfos.segmento !== 'Bancos' && (
            <BottomCard
              helpText={chartHelpText.divida}
              openModal={setModalDivida}
              data={dataGraphics.dividaLiquida}
              label="Dívida Líquida"
              value={maskValueCards(
                dataGraphics.consolidado[dataGraphics.consolidado.length - 1]
                  ?.DividaLiquida ?? 0
              )}
              title={transformMaskCurrency(
                dataGraphics.consolidado[dataGraphics.consolidado.length - 1]
                  ?.DividaLiquida ?? 0
              )}
              fillcolor={defineDividaChartCollor(dataGraphics.dividaLiquida)}
            />
          )}
          {companyInfos.segmento !== 'Bancos' && (
            <BottomCard
              helpText={chartHelpText.dividaEbitida}
              openModal={setModalDividaEbitda}
              data={dataGraphics.dividaLIquidaEbitdaArr}
              label="DL/EBITDA"
              value={`${dataGraphics.dividaLIquidaEbitdaArr[
                dataGraphics.dividaLIquidaEbitdaArr.length - 1
              ]?.value.toFixed(2)}`}
              fillcolor={defineDividaEbitdaChartCollor(
                dataGraphics.dividaLIquidaEbitdaArr
              )}
            />
          )}

          <BottomCard
            helpText={chartHelpText.DY}
            openModal={setModalDividendos}
            data={dataGraphics.dyArr}
            label="Dividendos (DY)"
            value={formatPercentFloat(
              dataGraphics.consolidado[dataGraphics.consolidado.length - 1]
                ?.DY ?? 0
            )}
            fillcolor={defineChartCollor(dataGraphics.dyArr)}
          />
          <GraphicCotation
            // helpText={chartHelpText.ROE}
            openModal={() => setShowModalCotation(true)}
            data={historyCotation}
            label="Histórico de cotação"
            value={transformMaskCurrency(
              historyCotation.length >= 1
                ? historyCotation[historyCotation.length - 1].close
                : 0
            )}
            fillcolor={defineChartCollorHistoryCotation(historyCotation)}
            loading={loadingHistoryCotantion}
          />
        </ContentGraphics>
        {showProfitXQuotation && (
          <AnnualAssetClose
            assets={companyInfos.Assets}
            lucroLiquidoColor={defineChartCollor(dataGraphics.lucroLiquidoArr)}
            lucroLiquidoData={dataGraphics.lucroLiquidoArr}
            isShow={showProfitXQuotation}
            setIsShow={setShowProfitXQuotation}
          />
        )}
        {modalIndexes && (
          <CompanyIndexes
            isShow={modalIndexes}
            setIsShow={setModalIndexes}
            slug={companyInfos.Assets}
          />
        )}
      </GlobalContainer>
      {/**
       * Lucro Líquido
       */}
      <NewGraphicData
        state={modalLucro}
        setState={setModalLucro}
        title="Lucro Líquido"
        fillcolor={defineChartCollor(dataGraphics.lucroLiquidoArr)}
        data={dataGraphics.lucroLiquidoArr}
      />
      {/**
       * Ebitida
       */}
      <NewGraphicData
        state={modalEbitida}
        setState={setModalEbitida}
        title="EBITDA"
        fillcolor={defineChartCollor(dataGraphics.ebitdaArr)}
        data={dataGraphics.ebitdaArr}
      />
      {/**
       * Divida
       */}
      <NewGraphicData
        state={modalDivida}
        setState={setModalDivida}
        title="Dívida Líquida"
        fillcolor={defineDividaChartCollor(dataGraphics.dividaLiquida)}
        data={dataGraphics.dividaLiquida}
      />
      {/**
       * Dívida/Ebitida
       */}
      <ModalDesigner
        id="cotacaohistorico"
        isOpen={showModalCotation}
        setState={setShowModalCotation}
        size="xl"
        title="Cotação histórica"
      >
        <GraphicCotationHistory
          headerValues={retornosCotacao}
          data={historyCotation}
          color={defineChartCollorHistoryCotation(historyCotation)}
          filtersHistoryCotation={filtersHistoryCotation}
          setFiltersHistoryCotation={setFiltersHistoryCotation}
          loading={loadingHistoryCotantion}
        />
      </ModalDesigner>
      <NewGraphicData
        state={modalDividaEbitda}
        setState={setModalDividaEbitda}
        title="Dívida Líquida/EBITDA"
        fillcolor={defineDividaEbitdaChartCollor(
          dataGraphics.dividaLIquidaEbitdaArr
        )}
        data={dataGraphics.dividaLIquidaEbitdaArr}
        type="noEdit2"
      />
      {/**
       * ROE
       */}

      <NewGraphicData
        state={modalROE}
        setState={setModalROE}
        title="ROE (%)"
        fillcolor={defineChartCollor(dataGraphics.roeArr)}
        data={dataGraphics.roeArr}
        type="percent"
      />
      {/**
       * Dividendos
       */}
      <NewGraphicData
        state={modalDividendos}
        setState={setModalDividendos}
        title="Dividendos - DY %"
        fillcolor={defineChartCollor(dataGraphics.dyArr)}
        data={dataGraphics.dyArr}
        type="noEdit"
        payoutData={payoutData}
      />

      <ModalDesigner
        isOpen={modalFatos}
        setState={setModalFatos}
        id="fatos"
        size="xl"
        title="Fatos relevantes"
      >
        <ListFatosRelevantes type="ACAO" slug={slug} />
      </ModalDesigner>

      <h3
        style={{
          width: '100%',
          margin: '1rem 0',
        }}
      >
        Mais empresas
      </h3>
      <AcoesPainel
        seg={{
          search: true,
          value: companyInfos.sub_setor,
          seg: companyInfos.setor,
          segmento: companyInfos?.segmento,
        }}
        isComponent
        ticketActive={companyInfos.cnpj}
        selectedActive={setSelectedActive}
      />
      <ModalDesigner
        title={`Comparar ativo com ${slug}`}
        id="comparar"
        isOpen={modalCompareAssets}
        setState={setModalCompareAssets}
        size="xl"
      >
        <CompareAssets
          isComponent
          defaultAsset={{
            value: slug,
            label: `${slug} - ${companyInfos.NomeEmpresa}`,
          }}
        />
      </ModalDesigner>

      <ModalDesigner
        title="Explicação das notas"
        id="notas"
        isOpen={showExplanationAssetNotes}
        setState={setShowExplanationAssetNotes}
        size="xl"
      >
        <ExplanationAssetNotes />
      </ModalDesigner>

      <ModalDesigner
        // size="md"
        title={`Sobre ${slug}`}
        id="sobrecompany"
        isOpen={showModalAboutCompany}
        setState={setShowModalAboutCompany}
      >
        <AboutCompany slug={slug} />
      </ModalDesigner>
    </>
  );
};
