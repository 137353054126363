import styled from 'styled-components';

export const ContainerAsideFiltersFii = styled.aside`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
  width: 100%;
  max-width: 18.75rem;
  border-radius: 12px;
  gap: 1rem;
  padding: 1.5rem 1rem;
`;

export const AsideHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    width: 100%;
    max-width: 18.75rem;
    color: ${(props) => props.theme['gray-400']};
    font-weight: 500;
    font-size: 1rem;
  }
`;

export const ContentInputFilters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    margin: 0;
    font-size: 0.875rem;
    color: ${(props) => props.theme['gray-500']};
    font-weight: 400;
  }
  span {
    text-align: center;
    font-weight: 500;
    color: ${(props) => props.theme['gray-500']};
    font-size: 0.75rem;
  }
`;

export const ButtonShowMoreFilters = styled.button`
  all: unset;
  background: rgba(131, 196, 255, 0.3);
  border-radius: 38px;
  padding: 0.75rem 1.5rem;
  text-align: center;
  font-size: 1rem;
  color: ${(props) => props.theme['primary-800']};
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;