import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  .row {
    display: flex;
    gap: 1rem;
  }
`;

export const ContentFilters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  p {
    cursor: pointer;
  }
  p + p {
    margin-left: 10px;
  }
  .selected {
    font-weight: bold;
    color: ${(props) => props.theme.primary};
  }
`;

export const ContentList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: start;
`;

export const RowDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
  gap: 1rem;
  width: 100%;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  }
`;

export const LoadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const SelectedFilterContent = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme['gray-700']};
  h5,
  span {
    font-size: 0.875rem;
  }
`;

export const ContentFiltersAndSearch = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  .buttonFilter {
    all: unset;
    border-radius: 100%;
    box-shadow: 1px 1px 6px 0px #0000001a;
    background-color: white;
    padding: 0.75rem;
    width: 47;
    height: 47;
  }
`;


export const WarningNewMessage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: start;
  background: #fff5e2;
  border-radius: 11px;

  padding: 0.5rem 0.75rem;
  margin-bottom: 0.75rem;
  p {
    margin: 0;
    font-size: 0.675rem;
    color: #606060;
    font-weight: 400;
    a {
      color: #606060;
      font-weight: 500;
      font-size: 0.75rem;
      text-decoration: underline;
    }
  }
`;