import styled from 'styled-components';

export const ContentForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${(props) => props.theme.white};
  padding: 1rem;
  border-radius: 8px;

`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  label {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    color: ${(props) => props.theme['gray-500']};
  }

  input {
    all: unset;
    box-shadow: 2px 2px 6px 0px #0000001a;
    padding: 1rem;
    border-radius: 8px;
    outline: 1px solid transparent;

    font-weight: 400;
    font-size: 0.875rem;

    color: ${(props) => props.theme['gray-500']};

    &:focus {
      outline-color: ${(props) => props.theme['primary-800']};
    }

    &:disabled {
      cursor: not-allowed;
      background: ${(props) => props.theme['white-300']};
    }
  }
`;
