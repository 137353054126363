import { FiX } from 'react-icons/fi';
import styled from 'styled-components';

interface IPropsOutModalStyled {
  isShow: boolean;
}

export const OutModalStyled = styled.div<IPropsOutModalStyled>`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);

  color: ${(props) => props.theme.black};
  top: 0;
  right: 0;
  z-index: 1000000000000 !important;
  justify-content: flex-end;

  transform: ${(props) =>
    props.isShow ? 'translateX(0)' : 'translateX(300vh)'};
  opacity: ${(props) => (props.isShow ? 1 : 0)};
  transition: transform 0.6s, opacity 0.3s;
  h1 {
    padding: 0;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;

  justify-content: space-between;
  padding: 1rem;

  h4 {
    margin-top: 1rem;
    padding: 0 !important;
  }
`;
export const Close = styled(FiX)`
  font-size: 1.5rem;
  cursor: pointer;
`;

interface IPropsModalStyled {
  size: 'sm' | 'md' | 'lg' | 'xl';
  fullSize: boolean;
}

export const ModalStyled = styled.div<IPropsModalStyled>`
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: ${(props) => props.theme.white};
  border-radius: 1rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: ${(props) => (props.fullSize ? '100%' : 'auto')};
`;

export const Content = styled.div`
  padding: 1rem;
  height: 100vh;

  overflow-y: auto;
  ::-webkit-scrollbar {
    /* display: none; */
  }
`;
