import ReactApexChart from 'react-apexcharts';
import { ContentPercentNota } from '../NewTableCompareAssets/styles';
import { Tooltip } from 'antd';
import { useState } from 'react';
import { ExplanationAssetNotes } from '../../../../../../components/ResumoAcao/components/ExplanationAssetNotes';
import { ModalDesigner } from '../../../../../../components/ModalDesigner';

interface IPropsGraphicNote {
  active: {
    codigo_fii: string;
    nome_fundo: string;
    nota_final: number;
    data_funcionamento_numerico: number;
    qtde_ativos: number;
    vacancia_fisica: number;
    dividendo_yeld: number;
    valor_patrimonial_por_cota: number;
    tipo_fii: string;
  };
}

export function GraphicNote({ active }: IPropsGraphicNote) {
  const [showExplanationAssetNotes, setShowExplanationAssetNotes] =
    useState<boolean>(false);

  const compare = {
    series: [
      active.data_funcionamento_numerico === 3
        ? 100
        : active.data_funcionamento_numerico === 2
        ? 67
        : 33,
      active.dividendo_yeld === 3 ? 100 : active.dividendo_yeld === 2 ? 67 : 33,
      active.qtde_ativos === 3 ? 100 : active.qtde_ativos === 2 ? 67 : 33,
      active.vacancia_fisica === 3
        ? 100
        : active.vacancia_fisica === 2
        ? 67
        : 33,
      active.valor_patrimonial_por_cota === 3
        ? 100
        : active.valor_patrimonial_por_cota === 2
        ? 67
        : 33,
    ],
    options: {
      chart: {
        height: 390,
        type: 'donut',
      },
      yaxis: {
        labels: {
          //@ts-ignore
          formatter: (val) => `${val}%`,
        },
      },
      grid: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },

      legend: {
        show: false,
        position: 'bottom',
      },

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              total: {
                show: false,
                label: 'Media',
                formatter: () => `${active.nota_final.toFixed(0)}%`,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
        //@ts-ignore
        formatter: (val) => `${val.toFixed(0)}%`,
      },
      tooltip: {
        y: {
          //@ts-ignore
          formatter: (val) => `${val.toFixed(0)}%`,
        },
      },

      labels: [
        'Tempo em bolsa',
        'DY',
        'Quant. Ativos',
        'Vacância física',
        'Valor patrimonial',
      ],
    },
  };

  return (
    <>
      <ContentPercentNota onClick={() => setShowExplanationAssetNotes(true)}>
        <ReactApexChart
          //@ts-ignore
          options={compare.options}
          series={compare.series}
          type="donut"
          width={100}
        />
        {/* <Tooltip title="Essa é a nota da empresa com base na média das nossas avaliações. Basta clicar no gráfico para ver a explicação de cada uma das notas"> */}
        <h6>{active.nota_final.toFixed(0)}%</h6>
        {/* </Tooltip> */}
      </ContentPercentNota>
      {/* <ModalDesigner
        id="analisenota"
        isOpen={showExplanationAssetNotes}
        setState={setShowExplanationAssetNotes}
        title="Explicação das notas"
        size="lg"
        fullSize
      >
        <ExplanationAssetNotes />
      </ModalDesigner> */}
    </>
  );
}
