import Chart from './Chart';

import {
  BottomCard as Card,
  BottomCardInfo,
  BottomCardHeader,
  CardInfoValue,
} from './styles';
import { Tooltip } from 'antd';
import { Info } from '@phosphor-icons/react';

const BottomCard = ({ data, fillcolor, value, label, openModal, helpText }) => {
  return (
    <Card
      onClick={() => {
        openModal(true);
      }}
    >
      <BottomCardHeader>
        <span>{label}</span>
        <Tooltip title={helpText}>
          <Info size="1rem" color="var(--primary)" />
        </Tooltip>
      </BottomCardHeader>
      <BottomCardInfo>
        <CardInfoValue>{value}</CardInfoValue>
      </BottomCardInfo>
      <Chart
        data={data.map((item) => item.value)}
        fillcolor={fillcolor}
        height={60}
      />
    </Card>
  );
};

export default BottomCard;
