import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) => props.theme.white};
  padding: 2rem;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  .title span {
    text-decoration: none;
    color: white !important;
    font-size: 14px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .contentResult {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    margin-bottom: 2.5rem;
    @media (max-width: 768px) {
      flex-direction: column;
    }

    .result {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 2rem;
      border-radius: 8px;
      border: 1px solid ${(props) => props.theme['gray-300']};
      width: 100%;
      text-align: center;

      font-size: 1.125rem;
      color: ${(props) => props.theme['gray-600']};
      font-weight: 500;

      strong {
        color: ${(props) => props.theme['gray-800']};
        font-size: 1.75rem;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    border: 1px solid ${(props) => props.theme['gray-n-300']};
    padding: 1.5rem;
    border-radius: 16px;

    label {
      font-weight: 500;
      font-size: 1.125rem;
      color: ${(props) => props.theme['gray-600']};
      width: 100%;
    }
    .contentForm {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      div {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        position: relative;
        input {
          all: unset;
          padding: 8px;
          border-bottom: 1px solid ${(props) => props.theme['gray-n-400']};
          font-size: 16px;
          color: ${(props) => props.theme.black};
          background: ${(props) => props.theme['orange-50']};

          transition: all 0.3;
          :hover {
            box-shadow: 0 0 5px 1px ${(props) => props.theme.primary};
          }
          :hover:disabled {
            cursor: not-allowed;
            box-shadow: 0 0 5px 1px red;
          }
        }
        input[type='number']::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        input[type='number'] {
          -moz-appearance: textfield;
          appearance: textfield;
        }
        label {
          margin: 0;
        }
        i {
          position: absolute;
          right: 0.5rem;
          bottom: 0.5rem;
          font: 400 1rem 'Poppins', sans-serif;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    .contentButton{
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      gap: 1rem;
      @media (max-width: 480px) {
      flex-direction: column;
    }

    }
  }

  p {
    margin: 0;
    font-size: 1rem;
    color: #212121;
    strong {
      font-weight: 600;
      color: black;
    }
  }
  @media (max-width: 480px) {
    max-width: none;
  }
`;
