import { Newspaper, Wall } from '@phosphor-icons/react';

import {
  Container,
  ContentGraphic,
  HeaderCardActive,
  ColDiv,
} from './FiiCardStyle';
import ReactApexChart from 'react-apexcharts';
import { Tooltip } from 'antd';
import { cores } from '../../../utils/coresGraficos';

const FiiGrid = ({
  asset: {
    codigo_fii,
    data_funcionamento_numerico,
    dividendo_yeld,
    nome_fundo,
    nota_final,
    qtde_ativos,
    vacancia_fisica,
    valor_patrimonial_por_cota,
    tipo_fii,
  },
  onSelectedActive = ({ slug, type }) => {},
  isComponent,
}) => {
  const compare = {
    series: [
      data_funcionamento_numerico === 3
        ? 100
        : data_funcionamento_numerico === 2
        ? 67
        : 33,
      dividendo_yeld === 3 ? 100 : dividendo_yeld === 2 ? 67 : 33,
      qtde_ativos === 3 ? 100 : qtde_ativos === 2 ? 67 : 33,
      vacancia_fisica === 3 ? 100 : vacancia_fisica === 2 ? 67 : 33,
      valor_patrimonial_por_cota === 3
        ? 100
        : valor_patrimonial_por_cota === 2
        ? 67
        : 33,
    ],
    options: {
      chart: {
        height: 390,
        type: 'donut',
      },
      yaxis: {
        labels: {
          //@ts-ignore
          formatter: (val) => `${val}%`,
        },
      },
      grid: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },

      legend: {
        show: false,
        position: 'bottom',
      },

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              total: {
                show: false,
                label: 'Media',
                formatter: () => `${nota_final.toFixed(0)}%`,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
        //@ts-ignore
        formatter: (val) => `${val.toFixed(0)}%`,
      },
      tooltip: {
        y: {
          //@ts-ignore
          formatter: (val) => `${val.toFixed(0)}%`,
        },
      },

      labels: [
        'Tempo em bolsa',
        'DY',
        'Quant. Ativos',
        'Vacância física',
        'Valor patrimonial',
      ],
      colors: cores,

    },
  };

  return (
    <Container
      onClick={() => {
        onSelectedActive({
          slug: codigo_fii,
          type: 'fii',
        });
      }}
    >
      <HeaderCardActive>
        {tipo_fii === 'Tijolo' ? (
          <Wall weight="bold" size={24} color="white" />
        ) : (
          <Newspaper weight="bold" size={24} color="white" />
        )}
        <span
          onClick={() => {
            onSelectedActive({
              slug: codigo_fii,
              type: 'fii',
            });
          }}
        >
          {codigo_fii}
        </span>
      </HeaderCardActive>
      <ColDiv>
        <ContentGraphic value={nota_final.length}>
          <div className="contentGraphicValue">
            <ReactApexChart
              //@ts-ignore
              options={compare.options}
              series={compare.series}
              type="donut"
              width={150}
            />
            <Tooltip title="Essa é a nota da empresa com base na média das nossas avaliações">
              <h6>{nota_final.toFixed(0)}%</h6>
            </Tooltip>
          </div>
        </ContentGraphic>
        <div style={{ color: 'black' }}>
          <h6
            onClick={() => {
              onSelectedActive({
                slug: codigo_fii,
                type: 'fii',
              });
            }}
            title={nome_fundo
              .replace('FUNDO DE INVESTIMENTO IMOBILIÁRIO', '')
              .replace('FUNDO DE INVESTIMENTO IMOBILIARIO', '')
              .replace('-', '')
              .replace(' - ', '')
              .replace('FII', '')}
          >
            {nome_fundo
              .replace('FUNDO DE INVESTIMENTO IMOBILIÁRIO', '')
              .replace('FUNDO DE INVESTIMENTO IMOBILIARIO', '')
              .replace('-', '')
              .replace(' - ', '')
              .replace('FII', '')}
          </h6>
        </div>
      </ColDiv>
    </Container>
  );
};

export default FiiGrid;
