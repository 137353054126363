import ReactApexChart from 'react-apexcharts';
import { Tooltip } from 'antd';
import { GraphicNoteFiiContainer } from './styles';
import { cores } from '../../../../utils/coresGraficos';

export interface IPropsFiiRankActive {
  codigo_fii: string;
  data_funcionamento_numerico: number;
  dividendo_yeld: number;
  nome_fundo: string;
  nota_final: number;
  qtde_ativos: number;
  vacancia_fisica: number;
  valor_patrimonial_por_cota: number;
  tipo_fii:string
  corretora:string
}

interface IPropsGraphicNoteFii {
  fii: IPropsFiiRankActive;
  graphicWidth: number;
}

export function GraphicNoteFii({ fii, graphicWidth }: IPropsGraphicNoteFii) {
  const compare = {
    series: [
      fii.data_funcionamento_numerico === 3
        ? 100
        : fii.data_funcionamento_numerico === 2
        ? 67
        : 33,
      fii.dividendo_yeld === 3 ? 100 : fii.dividendo_yeld === 2 ? 67 : 33,
      fii.qtde_ativos === 3 ? 100 : fii.qtde_ativos === 2 ? 67 : 33,
      fii.vacancia_fisica === 3 ? 100 : fii.vacancia_fisica === 2 ? 67 : 33,
      fii.valor_patrimonial_por_cota === 3
        ? 100
        : fii.valor_patrimonial_por_cota === 2
        ? 67
        : 33,
    ],
    options: {
      chart: {
        height: 390,
        type: 'donut',
      },
      yaxis: {
        labels: {
          //@ts-ignore
          formatter: (val) => `${val}%`,
        },
      },
      grid: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },

      legend: {
        show: false,
        position: 'bottom',
      },

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              total: {
                show: false,
                label: 'Media',
                formatter: () => `${fii.nota_final.toFixed(0)}%`,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
        //@ts-ignore
        formatter: (val) => `${val.toFixed(0)}%`,
      },
      tooltip: {
        y: {
          //@ts-ignore
          formatter: (val) => `${val.toFixed(0)}%`,
        },
      },

      labels: [
        'Tempo em bolsa',
        'DY',
        'Quant. Ativos',
        'Vacância física',
        'Valor patrimonial',
      ],
      colors: cores,

    },
  };

  return (
    <GraphicNoteFiiContainer>
      <ReactApexChart
        //@ts-ignore
        options={compare.options}
        series={compare.series}
        type="donut"
        width={graphicWidth}
      />
      <Tooltip title="Essa é a nota da empresa com base na média das nossas avaliações">
        <h6>{fii.nota_final ? fii.nota_final.toFixed(0) : 0}%</h6>
      </Tooltip>
    </GraphicNoteFiiContainer>
  );
}
