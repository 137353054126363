import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';

export const MainAndSideCardContainer = styled.div`
  @media (max-width: 1220px) {
    flex-wrap: wrap;
    flex-direction: column;
    height: auto;
  }
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  /* height: 425px; */
`;

export const MainCard = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.white};
  width: 100%;

  padding: 1rem;
  height: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  .backButton {
    all: unset;
    line-height: 0;
    color: ${(props) => props.theme['gray-700']};
    font-size: 1.25rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    font-size: 1.5rem;
    font-weight: 500;
    color: #707070;
    margin-bottom: 1rem;
  }

  @media (max-width: 480px) {
    .backButton {
      font-size: 1rem;
    }
  }
`;

export const MoreButtonContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 15px;
`;
export const MainCardHeader = styled.div`
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  /* padding: 0px 145px 0px 60px; */

  background-color: ${(props) => props.theme.white};
`;
export const InfoContainer = styled.div`
  color: rgba(43, 42, 42, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;

  h1 {
    font-size: 1.2rem;
    margin: 0;
  }
`;

export const Percent = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
`;
export const InfoBoxContainer = styled.div`
  color: rgba(43, 42, 42, 1);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  gap: 1rem;
`;


export const ContentTab = styled(ScrollContainer)`
  display: flex;
  flex-direction: row;

  gap: 1.5rem;
  margin-bottom: 1rem;

  width: 100%;
`;

interface IPropsTabComponent {
  isFull?: boolean;
}

export const TabComponent = styled.button<IPropsTabComponent>`
  all: unset;
  border-radius: 21px;
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid ${(props) => props.theme['primary-800']};
  font-size: 0.875rem;
  font-weight: 500;
  color: ${(props) => props.theme['primary-800']};
  white-space: nowrap;

  width: ${(props) => props.isFull && '100%'};
  text-align: ${(props) => props.isFull && 'center'};
`;
