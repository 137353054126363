import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const RowContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
  gap: 1rem;
  width: 100%;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ContentTab = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  select {
    font-size: 1rem;
    color: ${(props) => props.theme.black};
    border: ${(props) => props.theme.border};
    border-radius: 4px;
    padding: 0.5rem;
  }
  strong {
    color: ${(props) => props.theme['gray-700']};
    font-size: 1rem;
  }
`;

export const ContentCardAssetsRigths = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: column;

    span {
      font-size: 0.75rem;
      font-weight: 400;
      color: ${(props) => props.theme['gray-700']};
    }
    strong {
      font-size: 1.5rem;
      font-weight: 700;
      color: ${(props) => props.theme['gray-700']};
    }
  }
`;
