import ReactApexChart from 'react-apexcharts';

import { ColDiv, Container, DivRow, HeaderCardContent } from './styles';

interface IPropsPortfolioBalanceSheetStatistics {
  percentage: {
    fii: number | 'NaN';
    action: number | 'NaN';
    rendaFixa: number | 'NaN';
  };
}

export function PortfolioBalanceSheetStatistics({
  percentage,
}: IPropsPortfolioBalanceSheetStatistics) {
  const graphicDataAction = {
    series: [percentage.action],
    options: {
      chart: {
        type: 'radialBar',
      },
      grid: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      colors: ['var(--graphic-action)'],
      plotOptions: {
        radialBar: {
          track: {
            background: 'rgba(21,21,21,0.3)',
          },
          hollow: {
            size: '70%',
          },
        },
      },

      responsive: [
        {
          breakpoint: 1344,
          options: {
            chart: {
              width: 180,
            },
          },
        },
        {
          breakpoint: 1289,
          options: {
            chart: {
              width: 160,
              height: 200,
            },
          },
        },
        {
          breakpoint: 1229,
          options: {
            chart: {
              width: 140,
              height: 180,
            },
          },
        },
        {
          breakpoint: 1169,
          options: {
            chart: {
              width: 120,
              height: 160,
            },
          },
        },
        {
          breakpoint: 1112,
          options: {
            chart: {
              width: 110,
              height: 140,
            },
          },
        },
        {
          breakpoint: 1049,
          options: {
            chart: {
              width: 100,
              height: 130,
            },
          },
        },
      ],
      labels: ['Ações'],
    },
  };
  const graphicDataFii = {
    series: [percentage.fii],
    options: {
      chart: {
        type: 'radialBar',
      },
      grid: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      colors: ['var(--graphic-fii)'],
      plotOptions: {
        radialBar: {
          track: {
            background: 'rgba(21,21,21,0.3)',
          },
          hollow: {
            size: '70%',
          },
        },
      },

      responsive: [
        {
          breakpoint: 1344,
          options: {
            chart: {
              width: 180,
            },
          },
        },
        {
          breakpoint: 1289,
          options: {
            chart: {
              width: 160,
              height: 200,
            },
          },
        },
        {
          breakpoint: 1229,
          options: {
            chart: {
              width: 140,
              height: 180,
            },
          },
        },
        {
          breakpoint: 1169,
          options: {
            chart: {
              width: 120,
              height: 160,
            },
          },
        },
        {
          breakpoint: 1112,
          options: {
            chart: {
              width: 110,
              height: 140,
            },
          },
        },
        {
          breakpoint: 1049,
          options: {
            chart: {
              width: 100,
              height: 130,
            },
          },
        },
      ],
      labels: ['FIIs'],
    },
  };
  const graphicDataRF = {
    series: [percentage.rendaFixa],
    options: {
      chart: {
        type: 'radialBar',
      },
      grid: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      colors: ['var(--graphic-total)'],
      plotOptions: {
        radialBar: {
          track: {
            background: 'rgba(21,21,21,0.3)',
          },
          hollow: {
            size: '70%',
          },
        },
      },
      labels: ['Renda Fixa'],
      responsive: [
        {
          breakpoint: 1344,
          options: {
            chart: {
              width: 180,
              height: 210,
            },
          },
        },
        {
          breakpoint: 1289,
          options: {
            chart: {
              width: 160,
              height: 200,
            },
          },
        },
        {
          breakpoint: 1229,
          options: {
            chart: {
              width: 140,
              height: 180,
            },
          },
        },
        {
          breakpoint: 1169,
          options: {
            chart: {
              width: 120,
              height: 160,
            },
          },
        },
        {
          breakpoint: 1112,
          options: {
            chart: {
              width: 110,
              height: 140,
            },
          },
        },
        {
          breakpoint: 1049,
          options: {
            chart: {
              width: 100,
              height: 130,
            },
          },
        },
      ],
    },
  };

  return (
    <Container>
      <HeaderCardContent>
        <h4 className="text-black">Carteira - Análise Geral</h4>
      </HeaderCardContent>
      <p>Distribuição da carteira</p>

      <DivRow>
        <ColDiv value={percentage.action.toString().length}>
          {/* @ts-ignore */}
          <ReactApexChart
            options={graphicDataAction.options}
            series={graphicDataAction.series}
            type="radialBar"
            width={200}
            height={220}
          />
        </ColDiv>
        <ColDiv value={percentage.fii.toString().length}>
          {/* @ts-ignore */}
          <ReactApexChart
            options={graphicDataFii.options}
            series={graphicDataFii.series}
            type="radialBar"
            width={200}
            height={220}
          />
        </ColDiv>
        <ColDiv value={percentage.rendaFixa.toString().length}>
          {/* @ts-ignore */}
          <ReactApexChart
            options={graphicDataRF.options}
            series={graphicDataRF.series}
            type="radialBar"
            width={200}
            height={220}
          />
        </ColDiv>
      </DivRow>
    </Container>
  );
}
