import styled from 'styled-components';

export const ContainerFeedbackUser = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${(props) => props.theme['gray-700']};
  width: 100%;
  height: 20.3rem;
  padding: 1rem;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .redContentColor {
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 10px 120px 160px red;
  }
  .yellowContentColor {
    position: absolute;
    bottom: 0;
    left: 0;
    box-shadow: 0 10px 120px 160px ${(props) => props.theme['brand-secondary']};
  }
  .blueContentColor {
    position: absolute;
    bottom: 50%;
    right: 0;
    box-shadow: 0 10px 120px 160px ${(props) => props.theme.primary};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  z-index: 1;
  background: rgba(21, 21, 21, 0.5);
  border-radius: 4px;
  padding: 1rem;
  p {
    margin: 0;
    margin-top: 1rem;
    font-size: 1.2rem;
    color: ${(props) => props.theme.white};
    line-height: 130%;
  }
  span {
    margin-top: 0.5rem;

    color: ${(props) => props.theme.white};
    font-size: 0.75rem;
    line-height: 110%;
  }
`;
