import { handleToast } from '../../components/Toast';

import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from '../../services/AuthService';
import authSlice from '../slices/auth';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const REFRESH_CONFIRMED_ACTION = '[refresh action] confirmed refresh';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function signupAction(email, password, username, cpf, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);

        dispatch(confirmedSignupAction(response.data));

        history.push('/dashboard');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  localStorage.removeItem(process.env.REACT_APP_USER_VERSION);
  localStorage.removeItem('filterActionsPainel-v0.0.1');
  localStorage.removeItem('filterFiiPainel-v0.0.2');
  localStorage.removeItem('closeFeedbackNoResponse');
  localStorage.removeItem('promotion');

  if (history) {
    history.push('/login');
  } else {
    window.location.replace('/login');
  }
  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction({
  email,
  password,
  history,
  register = false,
  setLoading,
  registerBusiness = false,
  params = null,
  token = null,
}) {
  let getTheme = localStorage.getItem('useTheme');

  return (dispatch) => {
    let dataLogin = {};
    if (token !== null) {
      dataLogin = {
        token,
        username: '',
        password: '',
      };
    } else {
      dataLogin = {
        username: email,
        password,
      };
    }

    login(dataLogin)
      .then((response) => {
        let user = {
          ...response.data.user,
          data_trial: response.data.dias_trial,
        };
        const cpf = user.cpf;
        if (/.*[a-zA-Z].*/.test(cpf)) {
          user.cpf = 'new cpf';
        }

        if (user.profile.image) {
          const isValidProfileImage = !!user.profile.image.match(
            'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
          );
          if (!isValidProfileImage) {
            user.profile.image =
              process.env.REACT_APP_API_URL + user.profile.image;
          }
        }
        if (user.profile.banner) {
          const isValidProfileBanner = !!user.profile.banner.match(
            'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
          );
          if (!isValidProfileBanner) {
            user.profile.banner =
              process.env.REACT_APP_API_URL + user.profile.banner;
          }
        }
        const data = {
          ...response.data,
          user: user,
        };

        if (!getTheme) {
          localStorage.setItem('useTheme', 'light');
        }

        saveTokenInLocalStorage(data);

        dispatch(loginConfirmedAction(data));
        dispatch(
          authSlice.actions.setAuthTokens({
            token: response.data.access,
            refreshToken: response.data.refresh,
          })
        );
        dispatch(authSlice.actions.setAccount(data.user));
        if (response.data.UserType.includes('Admin')) {
          handleToast(
            false,
            'Login efetuado com sucesso, parabéns você é um administrador'
          );
          const redirectPath = localStorage.getItem('redirectPath') || '/';
          localStorage.removeItem('redirectPath'); // Remove a URL de destino após o uso
          history.replace(redirectPath);

          return;
        }
        if (response.data.dias_trial >= 0) {
          if (response.data.dias_trial === 0) {
            if (response.data.user.status_pagamento !== 'unpaid') {
              handleToast(false, `Login efetuado com sucesso`);
              const redirectPath = localStorage.getItem('redirectPath') || '/';
              localStorage.removeItem('redirectPath'); // Remove a URL de destino após o uso
              history.replace(redirectPath);

              return;
            }

            handleToast(
              false,
              `Login efetuado com sucesso, seu teste gratuito termina hoje`
            );
            const redirectPath = localStorage.getItem('redirectPath') || '/';
            localStorage.removeItem('redirectPath'); // Remove a URL de destino após o uso
            history.replace(redirectPath);

            return;
          } else if (response.data.dias_trial === 1) {
            handleToast(
              false,
              `Login efetuado com sucesso, falta ${response.data.dias_trial} dia para o teste acabar`
            );
            const redirectPath = localStorage.getItem('redirectPath') || '/';
            localStorage.removeItem('redirectPath'); // Remove a URL de destino após o uso
            history.replace(redirectPath);

            return;
          } else {
            handleToast(
              false,
              `Login efetuado com sucesso, faltam ${response.data.dias_trial} dias para o teste acabar`
            );
            if (register) {
              return history.push('/cadastro-concluido');
            }
            if (registerBusiness) {
              if (params !== null) {
                return history.push(`/dashboard/led${params}`);
              } else {
                return history.push(`/dashboard/led`);
              }
            }
            const redirectPath = localStorage.getItem('redirectPath') || '/';
            localStorage.removeItem('redirectPath'); // Remove a URL de destino após o uso
            history.replace(redirectPath);

            return;
          }
        } else {
          handleToast(false, 'Login efetuado com sucesso');

          const redirectPath = localStorage.getItem('redirectPath') || '/';
          localStorage.removeItem('redirectPath'); // Remove a URL de destino após o uso
          history.replace(redirectPath);

          return;
        }
      })
      .catch((err) => {
        setLoading(false);
        handleToast(true, 'Usuário ou senha incorreto');
        dispatch(loginFailedAction('Erro no login'));
      });
  };
}

export function refreshConfirmed(data) {
  return {
    type: REFRESH_CONFIRMED_ACTION,
    payload: data,
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
