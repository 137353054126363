import { Dropdown, Menu, Progress, Tooltip } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  CaretDown,
  CaretUp,
  Eye,
  EyeSlash,
  Warning,
} from '@phosphor-icons/react';
import { RenderConditionalObjective } from '../../../../../RenderConditionalObjective';
import { useActives } from '../../../../../../../hooks/useActives';
import { filterTableSorte } from '../../../../../../../utils/filterTableSort';
import { validateFloatNumber } from '../../../../ValidateFloatNumber';
import { defineDisplayColor } from '../../../../DefineDisplayColor';

import { IWalletProps } from '../../../../../../../utils/@types/wallet';
import { transformMaskCurrency } from '../../../../../../../utils/transformMaskCurrency';
import { ButtonComponent } from '../../../../../../../components/ButtonComponent';
import { TypeActiveContent } from '../../../../../AppsMenu/AppProfile/stylePostDetails';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FFF',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const header = [
  { label: 'Ativo', active: 'tickerSymbol' },
  { label: 'Tipo', active: 'productTypeName' },
  { label: 'Nota', active: 'nota' },
  { label: 'Cotação', active: 'closingPrice' },
  { label: 'Quant.', active: 'equitiesQuantity' },
  { label: 'Patrimônio', active: 'updateValue' },
  { label: 'Objetivo' },
  { label: 'Atual', active: 'percent' },
  { label: 'Diferença', active: 'gap' },
  { label: 'Preço médio', active: 'PrecoMedio' },
  { label: 'Rentabilidade', active: 'Rentabilidade' },
  { label: 'Lucro/Prejuízo', active: 'lucro' },
];

interface IPropsAllActivesTable {
  walletValues: IWalletProps;
  setWalletValues: Function;
  filterBrokerage?: string;
  setRule: Function;
  setShowModalCreateRule: Function;
  filterShowColumnTable: string[];
  setShowModalFilterColumnTable: Function;
  typeWallet: boolean;
}

export function AllActivesTable({
  walletValues,
  setWalletValues,
  filterBrokerage = '',
  setRule,
  setShowModalCreateRule,
  filterShowColumnTable,
  setShowModalFilterColumnTable,
  typeWallet,
}: IPropsAllActivesTable) {
  //@ts-ignore
  const { showActive } = useActives();
  const [showAmountActives, setShowAmountActives] = useState<boolean>(false);
  const [showActiveName, setShowActiveName] = useState<boolean>(false);
  const history = useHistory();

  const totalValueInTable = transformMaskCurrency(
    walletValues.sumAcao + walletValues.sumsEFT + walletValues.sumsFII
  );

  const showActiveColumnTable = filterShowColumnTable.includes('Ativo');
  const showTypeColumnTable = filterShowColumnTable.includes('Tipo');
  const showNoteColumnTable = filterShowColumnTable.includes('Nota');
  const showClosePriceColumnTable = filterShowColumnTable.includes('Cotação');
  const showAmountColumnTable = filterShowColumnTable.includes('Quant.');
  const showPatrimonyColumnTable = filterShowColumnTable.includes('Patrimônio');
  const showGoalColumnTable = filterShowColumnTable.includes('Objetivo');
  const showCurrentColumnTable = filterShowColumnTable.includes('Atual');
  const showGapColumnTable = filterShowColumnTable.includes('Diferença');
  const showProfitColumnTable =
    filterShowColumnTable.includes('Lucro/Prejuízo');
  const showProfitabilityColumnTable =
    filterShowColumnTable.includes('Rentabilidade');
  const showAveragePriceColumnTable =
    filterShowColumnTable.includes('Preço médio');

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
      <div className="headerTableContent">
        <ButtonComponent
          type="button"
          onClick={() => setShowModalFilterColumnTable(true)}
        >
          Escolher colunas da tabela
        </ButtonComponent>
        {walletValues.message !== '' && (
          <Dropdown
            overlay={
              <Menu
                style={{
                  maxWidth: '18.75rem',
                }}
                items={[
                  {
                    key: '1',
                    label: <strong>{walletValues.message}</strong>,
                  },
                ]}
              />
            }
            placement="bottom"
          >
            <Warning size={30} color="red" />
          </Dropdown>
        )}
      </div>

      <Table
        sx={window.screen.width <= 480 ? { minWidth: 300 } : { minWidth: 700 }}
        aria-label="customized table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            {header.map((item) => {
              if (filterShowColumnTable.includes(item.label)) {
                return (
                  <StyledTableCell key={item.label}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.625rem',
                        fontSize: '0.95rem',
                      }}
                    >
                      {item.label}
                      {item.active && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <CaretUp
                            size={14}
                            color="black"
                            onClick={() => {
                              setWalletValues({
                                ...walletValues,
                                positionAcao: filterTableSorte({
                                  data: walletValues.positionsAll,
                                  dir: 'asc',
                                  //@ts-ignore
                                  key: item.active,
                                }),
                              });
                            }}
                          />
                          <CaretDown
                            size={14}
                            color="black"
                            onClick={() => {
                              setWalletValues({
                                ...walletValues,
                                positionAcao: filterTableSorte({
                                  data: walletValues.positionsAll,
                                  dir: 'desc',
                                  //@ts-ignore
                                  key: item.active,
                                }),
                              });
                            }}
                          />
                        </div>
                      )}
                      {item.label === 'Quant.' &&
                        (showAmountActives ? (
                          <Eye
                            size={20}
                            color="black"
                            onClick={() =>
                              setShowAmountActives(!showAmountActives)
                            }
                          />
                        ) : (
                          <EyeSlash
                            size={20}
                            color="black"
                            onClick={() =>
                              setShowAmountActives(!showAmountActives)
                            }
                          />
                        ))}
                      {item.label === 'Ativo' &&
                        (showActiveName ? (
                          <Eye
                            size={20}
                            color="black"
                            onClick={() => setShowActiveName(!showActiveName)}
                          />
                        ) : (
                          <EyeSlash
                            size={20}
                            color="black"
                            onClick={() => setShowActiveName(!showActiveName)}
                          />
                        ))}
                    </div>
                  </StyledTableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {walletValues.positionsAll.length > 0 ? (
            walletValues.positionsAll.map((item, index) => (
              <StyledTableRow key={index}>
                {showActiveColumnTable && (
                  <StyledTableCell
                    className="reduce link"
                    onClick={() => {
                      if (item.productTypeName === 'acao') {
                        history.push(`/acao/${item.tickerSymbol}`);
                      } else {
                        history.push(`/fii/${item.tickerSymbol}`);
                      }
                    }}
                  >
                    {showActiveName || showActive ? (
                      <>
                        <span>{item.tickerSymbol}</span>
                        <br />
                        <strong title={item.corporationName}>
                          {' '}
                          {item.corporationName}
                        </strong>
                      </>
                    ) : (
                      '*****'
                    )}
                  </StyledTableCell>
                )}
                {showTypeColumnTable && (
                  <StyledTableCell>
                    <TypeActiveContent
                      //@ts-ignore
                      typeActive={
                        item.productTypeName === 'acao'
                          ? 'acao'
                          : item.productTypeName !== 'acao' &&
                            item.productTypeName !== 'tesourodireto' &&
                            item.productTypeName !== 'rendafixa'
                          ? 'FII'
                          : 'rendaFixa'
                      }
                    >
                      {item.productTypeName === 'acao'
                        ? 'Ação'
                        : item.productTypeName !== 'acao' &&
                          item.productTypeName !== 'tesourodireto' &&
                          item.productTypeName !== 'rendafixa'
                        ? 'FII'
                        : 'Renda Fixa'}
                    </TypeActiveContent>
                  </StyledTableCell>
                )}
                {showNoteColumnTable && (
                  <StyledTableCell style={{ width: '9.375rem' }}>
                    <Tooltip
                      title={
                        item.nota <= 0
                          ? 'Nota não aplicável a esse ativo ou não encontrada'
                          : `${item.nota.toFixed(0)}%`
                      }
                    >
                      {item.nota <= 0 ? (
                        <>N/A</>
                      ) : (
                        <Progress
                          percent={item.nota}
                          strokeColor={
                            item.nota <= 50
                              ? 'red'
                              : item.nota <= 60
                              ? 'yellow'
                              : 'var(--primary)'
                          }
                          showInfo={false}
                          trailColor="rgba(21,21,21,0.2)"
                        />
                      )}
                    </Tooltip>
                  </StyledTableCell>
                )}
                {showClosePriceColumnTable && (
                  <StyledTableCell>
                    {transformMaskCurrency(item.closingPrice)}
                  </StyledTableCell>
                )}
                {showAmountColumnTable && (
                  <StyledTableCell>
                    {showActive || showAmountActives
                      ? validateFloatNumber(
                          item.equitiesQuantity.toFixed(2).replace('.', ',')
                        )
                      : '****'}
                  </StyledTableCell>
                )}
                {showPatrimonyColumnTable && (
                  <StyledTableCell
                    className="reduce"
                    title={
                      showActive
                        ? transformMaskCurrency(item.updateValue)
                        : 'R$ ******'
                    }
                  >
                    {showActive
                      ? transformMaskCurrency(item.updateValue)
                      : 'R$ ******'}
                  </StyledTableCell>
                )}

                {showGoalColumnTable && (
                  //@ts-ignore
                  <RenderConditionalObjective
                    active={item}
                    exceptions={item.Exception}
                    filterBrokerage={filterBrokerage}
                    setRule={setRule}
                    setShowModalCreateRule={setShowModalCreateRule}
                    walletValues={walletValues}
                    typeWallet={typeWallet}
                  />
                )}
                {showCurrentColumnTable && (
                  <StyledTableCell
                    style={{
                      color: defineDisplayColor({
                        item,
                        exceptions: item.Exception,
                        type: filterBrokerage === '' ? 'all' : 'broker',
                        wallet: walletValues,
                        //@ts-ignore
                        filterBrokerage:
                          filterBrokerage === '' ? null : filterBrokerage,
                        typeWallet: typeWallet,
                      }),
                    }}
                  >
                    {item?.percent?.toFixed(2)}%
                  </StyledTableCell>
                )}
                {showGapColumnTable && (
                  <StyledTableCell>{item?.gap?.toFixed(2)}%</StyledTableCell>
                )}
                {showAveragePriceColumnTable && (
                  <StyledTableCell
                    className="reduce"
                    title={
                      showActive
                        ? transformMaskCurrency(item.PrecoMedio)
                        : 'R$ ******'
                    }
                  >
                    {showActive
                      ? transformMaskCurrency(item.PrecoMedio)
                      : 'R$ ******'}
                  </StyledTableCell>
                )}

                {showProfitabilityColumnTable && (
                  <StyledTableCell
                    style={{
                      color: item.Rentabilidade < 0 ? 'red' : '#44bd32',
                    }}
                  >
                    {item?.Rentabilidade?.toFixed(2)}%
                  </StyledTableCell>
                )}

                {showProfitColumnTable && (
                  <StyledTableCell
                    className="reduce"
                    title={
                      showActive
                        ? transformMaskCurrency(item.lucro)
                        : 'R$ ******'
                    }
                    style={{
                      color: item.lucro < 0 ? 'red' : '#44bd32',
                    }}
                  >
                    {showActive
                      ? transformMaskCurrency(item.lucro)
                      : 'R$ ******'}
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))
          ) : (
            <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
              Ainda não há dados
            </h1>
          )}
        </TableBody>
      </Table>
      <footer className="footer-table">
        <b>Total</b>
        <b>{showActive ? totalValueInTable : 'R$ ******'}</b>
      </footer>
    </TableContainer>
  );
}
