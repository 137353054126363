import styled, { css } from 'styled-components';

interface IPropsCard {
  typePlan: 'Anual' | 'Mensal' | 'Free';
}

export const CardPlanContainer = styled.div<IPropsCard>`
  display: flex;
  flex-direction: column;
  position: relative;

  width: 100%;
  min-width: 21.25rem;
  max-width: 21.25rem;
  height: 100%;

  border-radius: 16px;
  background: ${(props) => props.theme.white};

  border: ${(props) =>
    props.typePlan === 'Free'
      ? `1px solid #303F4B`
      : `2px solid #303F4B`};

  .fancybox-inner {
    z-index: 1000000000000000 !important;
  }
`;

export const TypePlan = styled.div<IPropsCard>`
  display: flex;
  justify-content: flex-end;
  width: 7rem;
  height: 6rem;
  padding-top: 0.5rem;
  padding-right: 0.25rem;
  clip-path: polygon(100% 0, 0 0, 100% 69%);
  background: #303F4B;

  position: absolute;
  right: -1px;
  top: -1px;
  border-top-right-radius: 16px;
  span {
    font-size: 0.875rem;
    color: #fff;
    font-weight: 500;
    line-height: 21px;
  }
`;

export const PromotionIcon = styled.div`
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  background: white;
  border-radius: 100%;
  overflow: hidden;
  padding: 1rem;
  border: 1px solid #0054b1;
`;

export const PromotionCard = styled.div`
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #0176e1;
  padding: 0.75rem;

  span {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

export const HeaderCard = styled.header`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin: 1.5rem auto 0;
  width: 100%;

  h3 {
    margin: 0;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 54px;
    display: flex;
    gap: 0.75rem;
    align-items: first baseline;
    color: #EC6643;

    span {
      font-size: 1.75rem;
      color: #EC6643;
      font-weight: 400;
      line-height: 48px;
    }
  }

  .textFree {
    font-weight: 400;
    font-size: 0.75rem;
    color: #252525;
    line-height: 18px;
    margin: 0;
  }
`;

export const PriceContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .priceOriginal{
    flex-direction: row;
    color: #6A717E
    .risco{
      text-decoration: line-through;
    }
  }

  h4 {
    font-weight: 600;
    font-size: 3rem;
    color: #353840;
    line-height: 100%;
    margin: 0;
    span {
      font-size: 0.75rem;
      font-weight: 400;
    }
  }
  p {
    margin: 0;
    font-size: 0.75rem;
    font-weight: 400;
    color: #5e5e5e;
  }

  .promotionText {
    font-size: 1rem;
    color: ${(props) => props.theme['gray-700']};

    span {
      text-decoration: line-through;
    }
  }
`;

export const InformationText = styled.div`
  display: flex;
  justify-content: center;
  background: #EC6643;
  width: 100%;
  /* max-width: 21.25rem; */
  padding: 0.25rem;
  position: relative;

  h2 {
    font-weight: 600;
    font-size: 1.312rem;
    color: ${(props) => props.theme.white};
    margin: 0;
  }
`;

export const ListFunctionalitiesContent = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 1rem;
  margin-top: 2.25rem;
  margin-bottom: auto;
`;

interface IPropsFunc {
  disabled: boolean;
}

export const FunctionalityContent = styled.div<IPropsFunc>`
  display: grid;
  grid-template-columns: 1.125rem 1fr;

  gap: 0.75rem;
  width: 100%;

  svg {
    color: ${(props) => (props.disabled ? '#A4A6AC' : props.theme.white)};
    width: 1.125rem;
    height: 1.125rem;
    padding: ${(props) => (props.disabled ? 0 : '0.1rem')};
    font-size: 1rem;

    ${(props) =>
      !props.disabled &&
      css`
        background-color: ${props.theme.primary};
        border-radius: 100%;
      `}
  }

  strong {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${(props) => (props.disabled ? '#A4A6AC' : '#353840')};
  }
`;

interface IPropsButtonSelect {
  currentPlan: boolean;
}

export const ButtonSelect = styled.button<IPropsButtonSelect>`
  padding: 0.5rem;
  /* topo | direita | inferior | esquerda */
  margin: 2.25rem 1rem 1rem;

  background: ${(props) => props.currentPlan ? props.theme.white : '#303F4B' };
  color: ${(props) => props.currentPlan ? '#303F4B' : props.theme.white};
  border: ${(props) =>
    props.currentPlan ? `1px solid #303F4B` : 0};
  border-radius: 8px;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;

  :hover {
    filter: brightness(0.9);
    transition: filter 0.3s;
  }

  a {
    color: ${(props) =>
      props.currentPlan ? props.theme.primary : props.theme.white};
  }
`;

export const IconModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    background-color: ${(props) => props.theme['danger-300']};
    padding: 1.5rem;
    border-radius: 99999px;
  }
`;

export const ContentTextModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2.5rem;

  p {
    margin: 0;
    color: #6c6c6c;
    font-size: 1rem;
    text-align: center;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  .outlineGray {
    border-color: ${(props) => props.theme['gray-600']};
    color: ${(props) => props.theme['gray-600']};
  }
`;