import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';

export const RendaPassivaContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderPageContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  width: 100%;

  align-items: center;

  span {
    width: 100%;
    max-width: 18.75rem;
    color: ${(props) => props.theme['gray-400']};
    font-weight: 500;
    font-size: 1rem;
  }
`;

export const TabContent = styled(ScrollContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;

  @media (max-width: 480px) {
    gap: 0;
  }
`;

interface IPropsButton {
  isActive: boolean;
}

export const ButtonTab = styled.button<IPropsButton>`
  all: unset;
  background: ${(props) =>
    props.isActive ? props.theme['primary-800'] : props.theme.white};
  padding: 0.675rem 0.875rem;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  width: 100%;
  max-width: 20rem;

  font-weight: 500;
  font-size: 1.125rem;
  text-align: center;
  color: ${(props) =>
    props.isActive ? props.theme.white : props.theme['gray-300']};
  white-space: nowrap;
  @media (max-width: 480px) {
    /* padding: 1rem 0.5rem; */
    font-size: 0.75rem;
  }
`;
