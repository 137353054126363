import { useTitleAndUser } from '../../../hooks/userTitle';
import { useHistory } from 'react-router-dom';
import { Container } from './HeaderMenuStyles';

import {
  Calculator,
  ChartBar,
  ChartLine,
  CurrencyCircleDollar,
  CurrencyDollar,
  GearSix,
  GraduationCap,
  House,
  Info,
  List,
  SignOut,
  UserGear,
  Users,
  Wallet,
  X,
} from '@phosphor-icons/react';
import { handleToast } from '../../../components/Toast';
import { logout } from '../../../store/actions/AuthActions';
import { useDispatch } from 'react-redux';
import { Menu } from 'antd';
import userdefault from '../../../images/profile/userdefault.png';

interface IPropsHeaderMenu {
  setShowMenu: Function;
  children?: React.ReactNode;
}

export function HeaderMenu({ setShowMenu, children }: IPropsHeaderMenu) {
  const isPhone =
    process.env.REACT_APP_TYPE === 'iPhone' ||
    process.env.REACT_APP_TYPE === 'Android';
  const history = useHistory();
  const dispatch = useDispatch();

  //@ts-ignore
  const { user, permissions, setUser } = useTitleAndUser();
  const isAdmin = permissions.includes('Admin');
  const navList = [
    {
      label: 'Painel administrativo',
      icon: <UserGear size="1.2rem" />,
      key: 0,
      submenu: [
        {
          label: 'Dashboard',
          path: '/admin-painel/dashboard',
        },
        {
          label: 'Feedback',
          path: '/admin-painel/feedback',
          key: 0.1,
        },
      ],
    },
    {
      label: 'Página inicial',
      icon: <House size="1.2rem" />,
      path: '/dashboard',
      key: 1,
    },

    {
      label: 'Análise',
      icon: <ChartBar size="1.2rem" />,
      key: 7,
      path: '/analysis',
      // submenu: [
      //   {
      //     label: 'FIIs',
      //     path: '/FiiPainel',
      //     key: 7.1,
      //   },
      //   {
      //     label: 'Ações',
      //     path: '/AcoesPainel',
      //     key: 7.2,
      //   },
      //   {
      //     label: 'Comparar ativos',
      //     path: '/compare-assets',
      //     key: 7.3,
      //   },
      // ],
    },
    {
      label: 'Carteira',
      icon: <Wallet size="1.2rem" />,
      key: 2,
      path: '/wallet',
      // submenu: [
      //   {
      //     label: 'Todos os ativos',
      //     path: '/my-wallet',
      //     key: 2.2,
      //   },
      //   {
      //     label: 'Ações',
      //     path: '/carteira-acao',
      //     key: 2.4,
      //   },
      //   {
      //     label: 'FIIs',
      //     path: '/carteira-fii',
      //     key: 2.3,
      //   },
      //   {
      //     label: 'Importação manual',
      //     path: '/post-details',
      //     key: 2.1,
      //   },
      //   {
      //     label: 'Reserva de emergência',
      //     path: '/mattress',
      //     key: 2.5,
      //   },
      // ],
    },
    {
      label: 'Renda passiva',
      icon: <CurrencyCircleDollar size="1.2rem" />,
      key: 3,
      path: '/renda-passiva',
      // submenu: [
      //   {
      //     label: 'Calendário de dividendos',
      //     path: '/calendario-dividendos',
      //     key: 3.1,
      //   },
      //   {
      //     label: 'Meus proventos recebidos',
      //     path: '/renda',
      //     key: 3.2,
      //   },
      //   {
      //     label: 'Meus próximos proventos',
      //     path: '/proventos',
      //     key: 3.3,
      //   },
      // ],
    },
    {
      label: 'Simuladores',
      icon: <Calculator size="1.2rem" />,
      key: 4,
      path: '/simuladores',

      // submenu: [
      //   {
      //     label: 'Juros compostos',
      //     path: '/calculadora',
      //     key: 4.1,

      //     // block: true,
      //   },

      //   // {
      //   //   label: 'Dividendos',
      //   //   path: '/calculadora-dividendos',
      //   // },
      //   // {
      //   //   label: 'FIIs',
      //   //   path: '/calculadora-fiis',
      //   // },
      //   {
      //     label: 'Juros equivalentes',
      //     path: '/calculadora-juros-equivalente',
      //     key: 4.2,

      //     // block: true,
      //   },
      //   {
      //     label: 'CDI',
      //     path: '/calculadora-cdi',
      //     key: 14.3,
      //   },
      // ],
    },
    {
      label: 'Imposto de renda',
      icon: <ChartLine size="1.2rem" />,
      key: 50,
      path: '/imposto-renda',

      // submenu: [
      //   {
      //     label: 'Relatórios',
      //     path: '/imposto-renda',
      //     // block: true,
      //     key: 50.1,
      //   },
      //   {
      //     label: 'Negociações de ativos',
      //     path: '/transactions/0',
      //     // block: true,
      //     key: 50.2,
      //   },
      //   {
      //     label: 'Custódia inicial',
      //     path: '/inicial-custody',
      //     // block: true,
      //     key: 50.3,
      //   },
      // ],
    },
    {
      label: 'Tutoriais',
      icon: <Info size="1.2rem" />,
      path: '/tutoriais',
    },
    {
      label: 'Educação',
      icon: <GraduationCap size="1.2rem" />,
      key: 5,

      submenu: [
        {
          label: 'Aulas',
          path: '/Educacao',
          key: 5.1,
        },
        {
          label: 'Gestão de vídeo',
          path: '/MyChannel',
          key: 5.2,
        },
      ],
    },
    {
      label: 'Comunidade',
      icon: <Users size="1.2rem" />,
      path: '/recommended-posts',
      key: 6,
    },
    {
      label: 'Assinaturas',
      icon: <CurrencyDollar size="1.2rem" />,
      path: '/pagamentos',
      key: 90,
    },
    {
      label: 'Configurações',
      icon: <GearSix size="1.2rem" />,
      key: 8,
      path: '/profile-edit',
      // submenu: [
      //   {
      //     label: 'Perfil',
      //     path: '/profile-edit',
      //     key: 8.2,
      //   },
      // ],
    },
    {
      label: 'Sair',
      key: 9,

      icon: <SignOut color="#ffff" />,
      path: '/sair',
    },
  ];
  const navListSubChannel = [
    {
      label: 'Página inicial',
      icon: <House size="1.2rem" />,
      path: '/dashboard',
      key: 10,
    },

    {
      label: 'Análise',
      icon: <ChartBar size="1.2rem" />,
      key: 11,
      path: '/analysis',
      // submenu: [
      //   {
      //     label: 'FIIs',
      //     path: '/FiiPainel',
      //     key: 11.1,
      //   },
      //   {
      //     label: 'Ações',
      //     path: '/AcoesPainel',
      //     key: 11.2,
      //   },
      //   {
      //     label: 'Comparar ativos',
      //     path: '/compare-assets',
      //     key: 11.3,
      //   },
      // ],
    },
    {
      label: 'Carteira',
      icon: <Wallet size="1.2rem" />,
      key: 12,
      path: '/wallet',
    },
    {
      label: 'Renda passiva',
      icon: <CurrencyCircleDollar size="1.2rem" />,
      key: 13,
      path: '/renda-passiva',
    },
    {
      label: 'Simuladores',
      icon: <Calculator size="1.2rem" />,
      key: 14,
      path: '/simuladores',
    },
    {
      label: 'Imposto de renda',
      icon: <ChartLine size="1.2rem" />,
      key: 50,
      path: '/imposto-renda',

      // submenu: [
      //   {
      //     label: 'Relatórios',
      //     path: '/imposto-renda',
      //     // block: true,
      //     key: 50.1,
      //   },
      //   {
      //     label: 'Negociações de ativos',
      //     path: '/transactions/0',
      //     // block: true,
      //     key: 50.2,
      //   },
      //   {
      //     label: 'Custódia inicial',
      //     path: '/inicial-custody',
      //     // block: true,
      //     key: 50.3,
      //   },
      // ],
    },
    {
      label: 'Tutoriais',
      icon: <Info size="1.2rem" />,
      path: '/tutoriais',
    },
    {
      label: 'Educação',
      icon: <GraduationCap size="1.2rem" />,
      path: '/Educacao',
      key: 15,
    },
    {
      label: 'Comunidade',
      icon: <Users size="1.2rem" />,
      path: '/recommended-posts',
      key: 16,
    },
    {
      label: 'Assinaturas',
      icon: <CurrencyDollar size="1.2rem" />,
      path: '/pagamentos',
      key: 60,
    },
    {
      label: 'Configurações',
      icon: <GearSix size="1.2rem" />,
      key: 17,
      path: '/profile-edit',
      // submenu: [
      //   {
      //     label: 'Perfil',
      //     path: '/profile-edit',
      //     key: 17.2,
      //   },
      // ],
    },
    {
      label: 'Sair',
      key: 18,
      icon: <SignOut color="#ffff" />,
      path: '/sair',
    },
  ];

  const navMap = navList.map((nav) => {
    if (!nav.submenu) {
      if (nav.label === 'Sair') {
        return {
          key: nav.key,
          icon: nav.icon,
          label: nav.label,
          onClick: () => {
            dispatch(logout(history));
            setUser({});
          },
        };
      } else {
        return {
          key: nav.key,
          icon: nav.icon,
          label: nav.label,
          onClick: () => {
            history.push(nav.path);
            setShowMenu(false);
          },
        };
      }
    } else {
      return {
        key: nav.key,
        icon: nav.icon,
        label: nav.label,
        children: nav.submenu.map((subNav) => {
          //@ts-ignore
          if (subNav.block) {
            return {
              key: subNav.key,
              label: subNav.label,
              onClick: () => {
                handleToast(true, `${subNav.label} está em desenvolvimento`);
              },
            };
          } else {
            if (isPhone && subNav.label === 'Pagamentos') {
              return {
                key: subNav.key,
                label: subNav.label,
                onClick: () => {
                  window.location.href = 'https://app.ibox6.com.br/pagamentos';
                  setShowMenu(false);
                },
              };
            }
            return {
              key: subNav.key,
              label: subNav.label,
              onClick: () => {
                history.push(subNav.path);
                setShowMenu(false);
              },
            };
          }
        }),
      };
    }
  });
  const navMapNoChannel = navListSubChannel.map((nav, i) => {
    // @ts-ignore
    if (!nav.submenu) {
      if (nav.label === 'Sair') {
        return {
          key: nav.key,
          icon: nav.icon,
          label: nav.label,
          onClick: () => {
            dispatch(logout(history));
            setUser({});
          },
        };
      } else {
        return {
          key: nav.key,
          icon: nav.icon,
          label: nav.label,
          onClick: () => {
            history.push(nav.path);
            setShowMenu(false);
          },
        };
      }
    } else {
      return {
        key: nav.key,
        icon: nav.icon,
        label: nav.label,
        // @ts-ignore
        children: nav.submenu.map((subNav) => {
          //@ts-ignore
          if (subNav.block) {
            return {
              key: subNav.key,
              label: subNav.label,
              onClick: () => {
                handleToast(true, `${subNav.label} está em desenvolvimento`);
              },
            };
          } else {
            if (isPhone && subNav.label === 'Pagamentos') {
              return {
                key: subNav.key,
                label: subNav.label,
                onClick: () => {
                  window.location.href = 'https://app.ibox6.com.br/pagamentos';
                  setShowMenu(false);
                },
              };
            }
            return {
              key: subNav.key,
              label: subNav.label,
              onClick: () => {
                history.push(subNav.path);
                setShowMenu(false);
              },
            };
          }
        }),
      };
    }
  });

  return (
    <Container>
      <header>
        <img
          src={
            user
              ? user?.profile?.image
                ? user?.profile?.image
                : userdefault
              : userdefault
          }
          alt={user.Name ?? 'user'}
        />
        <strong>Menu</strong>
        <List size={28} color="#ffffff" onClick={() => setShowMenu(false)} />
      </header>
      {/* {children} */}
      <br />
      <Menu
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        style={{
          height: '100%',

          borderRight: 0,
          overflowY: 'auto',
          overflowX: 'hidden',
          background: 'transparent',
          color: '#FFF',
        }}
        //@ts-ignore
        items={isAdmin ? navMap : navMapNoChannel}
      />
      {/* {user.username === 'Jhonatan' || user.username === 'fabioaurelios' ? (
        <ul className="containerList">
          {navMap.map((item) => (
            <React.Fragment key={item.key}>
              <li
                className="contentList"
                onClick={() =>
                  item.onClick
                    ? item.onClick()
                    : showRoutes === item.label
                    ? setShowRoutes('')
                    : setShowRoutes(item.label)
                }
              >
                <span>
                  {item.icon} {item.label}
                </span>
                {!item.onClick && <CaretDown size={20} color="#ffffff" />}
              </li>

              <SubList show={item.label === showRoutes}>
                {item.children &&
                  item.children.map((subItem, i) => (
                    <li key={i + 100} onClick={subItem?.onClick}>
                      {subItem?.label}
                    </li>
                  ))}
              </SubList>
            </React.Fragment>
          ))}
        </ul>
      ) : (
        <ul className="containerList">
          {navMapNoChannel.map((item) => (
            <React.Fragment key={item.key}>
              <li
                className="contentList"
                onClick={() =>
                  item.onClick
                    ? item.onClick()
                    : showRoutes === item.label
                    ? setShowRoutes('')
                    : setShowRoutes(item.label)
                }
              >
                <span>
                  {item.icon} {item.label}
                </span>
                {!item.onClick && <CaretDown size={20} color="#ffffff" />}
              </li>

              <SubList
                show={item.label === showRoutes}
                className={item.label === showRoutes ? 'show' : ''}
              >
                {item.children &&
                  item.children.map((subItem, i) => (
                    <li
                      className="activeItem"
                      //@ts-ignore
                      key={i + 100}
                      //@ts-ignore
                      onClick={subItem?.onClick}
                    >
                      {
                        //@ts-ignore
                        subItem?.label
                      }
                    </li>
                  ))}
              </SubList>
            </React.Fragment>
          ))}
        </ul>
      )} */}
    </Container>
  );
}
