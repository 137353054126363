import styled, { css } from 'styled-components';

interface IProsButtonContainer {
  br: 0 | 4 | 8 | 'full';
  size: 'btn-default' | 'btn-medium' | 'btn-small';
  isOutline: boolean;
}

export const ButtonContainer = styled.button<IProsButtonContainer>`
  all: unset;
  width: 100%;

  max-width: ${(props) =>
    props.size === 'btn-default'
      ? '21.25rem'
      : props.size === 'btn-medium'
      ? '17.5rem'
      : '13.75rem'};

  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme['primary-800']};
  color: ${(props) => props.theme['white-100']};
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  padding: 0.625rem 1rem;
  border-radius: ${(props) => (props.br !== 'full' ? `${props.br}px` : '37px')};
  border: 2px solid transparent;
  box-shadow: ${(props) => props.theme['shadow-1']};

  ${(props) =>
    props.isOutline &&
    css`
      border-color: ${props.theme['primary-800']};
      color: ${props.theme['primary-800']};
      background: transparent;
    `}
  :disabled {
    background: ${(props) => props.theme['white-300']};
    color: ${(props) => props.theme['gray-200']};
    cursor: not-allowed;
  }
  :hover:not(:disabled) {
    cursor: pointer;
    border-color: ${(props) => !props.isOutline && props.theme['primary-100']};
    transition: border-color 0.3s;
  }
`;
