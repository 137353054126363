import { useEffect, useState } from 'react';
import { RowDiv, TutoriaisContainer } from './styles';
import axios from 'axios';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { Heading } from '../../../components/DefaultStylesTypography';
import { baseURL } from '../../../utils/baseURL';
import { CardVideo } from './components/CardVideo';

interface IPropsVideoData {
  category: number;
  channel: number;
  channelThumbnail: string;
  channelTitle: string;
  comment: [];
  date: Date;
  description: string;
  dislike: [];
  id: number;
  like: [];
  thumbnail: string;
  title: string;
  video_id: string;
  view: [];
}

interface IPropsVideo {
  count: number;
  current: number;
  data: IPropsVideoData[];
  length: number;
  links: {
    next: null | string;
    previous: null | string;
  };
  total_pages: number;
}

export function Tutoriais() {
  //@ts-ignore
  const { setTitle } = useTitleAndUser();
  const [selectedVideo, setSelectedVideo] = useState<IPropsVideoData>({
    category: 2,
    channel: 0,
    channelThumbnail: '',
    channelTitle: '',
    comment: [],
    date: new Date(),
    description: '',
    dislike: [],
    id: 1,
    like: [],
    thumbnail: '',
    title: 'Conheça a plataforma',
    video_id: '41fd2922-e917-4d85-a28e-a57d6c0ac5d7',
    view: [],
  });
  const [iBoxVideos, setIBoxVideos] = useState<IPropsVideo>({
    count: 0,
    current: 1,
    data: [],
    length: 0,
    links: {
      next: null,
      previous: null,
    },
    total_pages: 0,
  });

  useEffect(() => {
    setTitle('Tutoriais');
    getCategoryVideo();
  }, []);

  async function getCategoryVideo() {
    await axios
      .get(`${baseURL}/videoscube/api/video/?category__id=2&page_size=1000`)
      .then((response) => {
        let res = response.data;
        res.data = res.data.sort((a: IPropsVideoData, b: IPropsVideoData) => {
          //@ts-ignore
          if (a.date < b.date) return 1;
          if (a.date > b.date) return -1;
          return 0;
        });

        setIBoxVideos(res);
      })
      .catch((error) => {});
  }
  const linkVideo = `https://player-vz-447732c4-8c0.tv.pandavideo.com.br/embed/?v=${selectedVideo.video_id}`;

  return (
    <TutoriaisContainer>
      <Heading as="h3" variant="tertiary">
        {selectedVideo.title}
      </Heading>
      <iframe
        src={linkVideo}
        height={
          window.screen.width < 480
            ? '200px'
            : window.screen.width < 700
            ? '400px'
            : '600px'
        }
        style={{ borderRadius: '20px' }}
        width="100%"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>

      <RowDiv>
        {iBoxVideos.data.length &&
          iBoxVideos.data.map((video, i) => {
            return (
              <CardVideo
                key={i}
                video={video}
                isTutorial
                handleNewTutorial={(newVideo: IPropsVideoData) => {
                  setSelectedVideo(newVideo);
                  window.scroll(0, 0);
                }}
              />
            );
          })}
      </RowDiv>
    </TutoriaisContainer>
  );
}
