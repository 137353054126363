import { useEffect, useState } from 'react';
import { SideBar } from './SideBar';
import { useNewTheme } from '../../../hooks/useNewTheme';
import { Header } from './Header';

const JobieNav = ({ onClick: ClickToAddEvent }) => {
  const [toggle, setToggle] = useState('');
  const onClick = (name) => setToggle(toggle === name ? '' : name);
  const { shortSidebar, setShortSidebar, isMobile, setIsMobile } =
    useNewTheme();

  useEffect(() => {
    if (window.screen.width <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.screen.width]);
  return (
    <>
      <Header
        onNotification={() => onClick('notification')}
        onProfile={() => onClick('profile')}
        toggle={toggle}
        onBox={() => onClick('box')}
        onClick={() => ClickToAddEvent()}
        shortSidebar={shortSidebar}
      />

      <SideBar
        shortSideBar={shortSidebar}
        setShortSidebar={setShortSidebar}
        isMobile={isMobile}
      />
    </>
  );
};

export default JobieNav;
