import { useEffect, useState } from 'react';
import { Heading } from '../../../../../components/DefaultStylesTypography';
import { ButtonDesign } from '../../../../../components/ButtonDesign';
import { ModalDesigner } from '../../../../../components/ModalDesigner';
import imageBlack11 from '../../../../../../src/assets/business/black11.jpg';
import imageBlack13 from '../../../../../../src/assets/business/black13.jpg';

export function TutorialIbox() {
  const [showTutorial, setShowTutorial] = useState(true); // Sempre exibir o tutorial

  const handleSubscriptionRedirect = () => {
    window.location.href = '/pagamentos'; // URL da página de assinaturas
  };

  return (
    <>
      <ModalDesigner
        id="tutorial"
        isOpen={showTutorial}
        setState={setShowTutorial}
        size="lg"
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <img src={imageBlack11} alt="Black Friday 11" style={{ width: '48%', height: 'auto', borderRadius: '8px' }} />
            <img src={imageBlack13} alt="Black Friday 13" style={{ width: '48%', height: 'auto', borderRadius: '8px' }} />
          </div>
          <br />
          <br />
          <Heading
            as="h5"
            variant="sextiary"
            style={{ fontWeight: 900, fontSize: '1.5rem', color: '#373737' }}
          >
            Black Friday
          </Heading>
          <p
            style={{
              fontWeight: 400,
              fontSize: '1rem',
              color: '#6C6C6C',
              lineHeight: '150%',
              marginTop: '1rem',
              marginBottom: 0,
            }}
          >
            {/* Preparamos um vídeo para lhe mostrar algumas funcionalidades da
            plataforma. Nossa equipe dedicou tempo e energia para destacar
            alguns dos recursos mais essenciais e úteis, a fim de proporcionar a
            você uma experiência incrível. */}
            Aproveite a <strong>Black Friday</strong> para se tornar um iBoxer 
            e garantir nossos planos com <strong>50% de desconto!</strong> 
            <br/>Essa é a melhor oferta do ano, válida <strong>por tempo limitado.</strong>
          </p>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '1rem',
            }}
          >
            <ButtonDesign onClick={handleSubscriptionRedirect}>
              VENHA SER IBOXER!
            </ButtonDesign>
          </div>
        </div>
      </ModalDesigner>
    </>
  );
}

