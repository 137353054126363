import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${(props) => props.theme.white};
  padding: 1rem;

  .cardShowValue {
    background: ${(props) => props.theme.white};
    border-radius: 4px;
    box-shadow: ${(props) => props.theme['card-shadow-2']};
    padding: 1rem;
    width: 100%;
    /* max-width: 343px; */
    height: 100%;
    /* max-height: 210px; */
    display: flex;
    flex-direction: column;

    h3 {
      margin: 0;
    }

    p {
      margin: 0;
      font: 400 1rem 'Poppins', sans-serif;
      line-height: 1.8;
      color: #a4a3ab;
    }
    strong {
      margin: 0;
      font: 400 1rem 'Poppins', sans-serif;
      line-height: 1.8;
      color: #1a1a1a;
    }
  }

  .contentFilter {
    display: flex;
    gap: 1rem;
    padding: 1rem 0.5rem;
    background: ${(props) => props.theme.white};
    border-radius: 8px;
    margin-bottom: 1rem;

    p {
      font-size: 1rem;
      font-weight: bold;
      color: ${(props) => props.theme.black};
      cursor: pointer;
      margin: 0;
    }
    .activeFilter {
      color: ${(props) => props.theme.primary};
      border-bottom: 2px solid ${(props) => props.theme.primary};
    }
  }

  .finalList {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;

    h3 {
      font: 400 0.75rem 'Poppins', sans-serif;
      color: ${(props) => props.theme.primary};
      cursor: pointer;
      transition: filter 0.3s;
      :hover {
        filter: brightness(0.9);
      }
    }
  }

  /* @media (max-width: 480px) {
    .cardShowValue {
      .typeFormated {
        strong {
          font-size: 0.875rem;
        }
      }
    }
  } */
`;

interface IPropsContentTab {
  tab: number;
}

export const ContentTabs = styled.div<IPropsContentTab>`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.white};
  margin-bottom: 1rem;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: row;
  }

  p {
    margin: 0;
    padding: 0.625rem 1.5rem;
    font: 500 1rem #a4a3ab 'Poppins', sans-serif;
    cursor: pointer;
  }
  .activeTab {
    background-color: #e7f4ff;
    font: 500 1rem 'Poppins', sans-serif;
    color: ${(props) => props.theme.primary};
    border-bottom: 2px solid ${(props) => props.theme.primary};
    border-radius: ${(props) => (props.tab === 1 ? '4px 0 0 4px' : '0')};
  }
  .totalValue {
    strong {
      color: ${(props) => props.theme.black};
    }
  }
`;

export const HeaderDate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background: ${(props) => props.theme.white};
  border-radius: 4px;
  padding: 1rem;
  align-items: center;
  box-shadow: ${(props) => props.theme['card-shadow-2']};

  h3 {
    margin: 0;
    font: 500 1rem 'Poppins', sans-serif;
  }

  div {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    align-items: center;

    span {
      width: auto;
    }
    @media (max-width: 480px) {
      gap: 0;
    }
  }

  @media (max-width: 340px) {
    gap: 1rem;
  }

  /* @media (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    div {
      justify-content: space-between;
      width: 100%;
    }
  } */
`;
