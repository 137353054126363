import { lazy, Suspense, useEffect } from 'react';
import Cookies from 'js-cookie';

/// Components
import Index from './jsx';
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, useLocation, withRouter } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';

import { ToastContainer } from 'react-toastify';
import { ActivesProvider } from './context/ActiveContext';
import { NewThemeContextProvider } from './context/NewThemeContext';
import { NewPassword } from './jsx/pages/NewPassword';
/// Style
import './vendor/bootstrap-select/dist/css/bootstrap-select.min.css';
import './css/style.css';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './context/UserContext';

import './styles.css';
import { Business } from './jsx/pages/Business';
import { Simulators } from './jsx/pages/Simulators';
const SignUp = lazy(() => import('./jsx/pages/Registration'));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

const isIPhone = process.env.REACT_APP_TYPE === 'iPhone';

function App(props) {
  const uLocation = useLocation();
  const dispatch = useDispatch();
  const paramsTraffic =
    window?.location?.search &&
    window?.location?.search
      .substr(1)
      .split('&')
      .reduce((queryParams, param) => {
        const [key, value] = param.split('=');
        queryParams[key] = value;
        return queryParams;
      }, {});

  useEffect(() => {
    if (
      !location.pathname.includes('/login') &&
      !location.pathname.includes('/page-recover-password') &&
      !location.pathname.includes('/page-register') &&
      !location.pathname.includes('/page-forgot-password') &&
      !location.pathname.includes('/v') &&
      !location.pathname.includes('/trial/simulator/un-logged')
    ) {
      checkAutoLogin(dispatch, props.history);
    } else {
      if (localStorage.userDetails) {
        window.location.replace('/dashboard');
      }
    }
  }, [dispatch, props.history]);

  useEffect(() => {
    if (!!paramsTraffic?.utm_source) {
      Cookies.set('traffic', JSON.stringify(paramsTraffic), { expires: 7 });
    }
  }, []);

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/page-register" component={SignUp} />
      <Route path="/page-recover-password" component={NewPassword} />
      <Route path="/v" component={Business} />
      <Route path="/trial/simulator/un-logged" component={Simulators} />
    </Switch>
  );

  const isPublicRoute =
    window.location.pathname.includes('login') ||
    window.location.pathname.includes('page-register') ||
    window.location.pathname.includes('page-recover-password') ||
    window.location.pathname === 'v' ||
    window.location.pathname.includes('un-logged') ||
    window.location.pathname.includes('/trial/simulator/un-logged');

  if (!props.isAuthenticated && !isPublicRoute) {
    const link = uLocation.search
      ? `${uLocation.pathname}${uLocation.search}`
      : uLocation.pathname;
    localStorage.setItem('redirectPath', link);
  }

  if (props.isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <AuthProvider>
            <NewThemeContextProvider>
              <ActivesProvider>
                <Index />
                <ToastContainer
                  position="top-right"
                  autoClose={50000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  className={`${isIPhone && 'iPhoneToast'} newZIndex`}
                />
              </ActivesProvider>
            </NewThemeContextProvider>
          </AuthProvider>
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
        <ToastContainer
          position="top-right"
          autoClose={50000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className={isIPhone && 'iPhoneToast'}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
