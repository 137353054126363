import styled from 'styled-components';

export const SignPlanContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: ${(props) => props.theme.white};
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;

  *::-webkit-scrollbar {
    display: none !important;
  }

  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.primary} #e3e3e3;

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 3px !important;
  }

  *::-webkit-scrollbar-track {
    background: #e3e3e3;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.primary};
    border-radius: 20px;
    border: 3px solid #e3e3e3;
  }

  .dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
  }

  .dot {
    border: none;
    width: 50px;
    height: 5px;
    background: #c5c5c5;
    border-radius: 99px;
    margin: 0 0.25rem 1rem;
    cursor: pointer;
  }

  .dot:focus {
    outline: none;
  }

  .dot.active {
    background: ${(props) => props.theme.primary};
  }
`;
export const BannerContent = styled.img`
  width: 100%;
  max-width: 1000px;
  margin-top: 80px;
  margin-bottom: 10px;
`;
export const ButtonBack = styled.button`
  all: unset;
  position: absolute;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  line-height: 21px;
  color: #656872;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-weight: 500;
  gap: 1.5rem;
  left: 3.06rem;
  top: 2.25rem;
  background: ${(props) => props.theme.white};
  svg {
    height: 1rem;
    width: 1rem;
  }

  @media (max-width: 760px) {
    left: 2.125rem;
    top: 2.125rem;
  }
`;

export const SignPlanHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  font-family: 'Poppins', sans-serif;

  .titulo{
    font-size: 16px;
  }
  .black {
    padding: 0.25rem 3.06rem;
    background: #000;
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    color: #fff;
  }
  .friday{
    padding: 0.25rem 3.06rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    background: #EC6643;
    color: ${(props) => props.theme.white};
  }

  h5 {
    width: 100%;
    max-width: 1126px;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
  }

  p {
    font-weight: 500;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-400']};
    width: 100%;
    max-width: 31.875rem;
    text-align: center;
    margin: 0;
  }

  @media (max-width: 970px) {
    margin-top: 5rem;
  }

  @media (max-width: 760px) {
    span {
      line-height: 12px;
      padding: 0.5rem 1.75rem;
      font-size: 0.5rem;
    }
    h3 {
      font-size: 1.5rem;
      text-align: center;
      padding: 0 1rem;
    }

    p {
      font-size: 0.875rem;
    }
  }
`;

export const TabFilterStyle = styled.div`
  display: flex;
  border-radius: 999px;
  border: 1px solid ${(props) => props.theme['gray-500']};
  position: relative;
  margin-top: 2rem;
  background: ${(props) => props.theme.white};
  span {
    position: absolute;
    right: 1.25rem;
    top: -2.25rem;
    background: #EC6643;
    padding: 0.5rem;
    padding-bottom: 2rem;
    z-index: -1;
    border-radius: 8px;

    font-weight: 500;
    font-size: 1.25rem;
    color: ${(props) => props.theme.white};
  }

  @media (max-width: 625px) {
    span {
      font-size: 0.75rem;
      padding-bottom: 1rem;
      top: -1.5rem;
    }
  }
`;

interface IPropsButtonSelectedFilter {
  isActive: boolean;
}

export const ButtonSelectedFilter = styled.button<IPropsButtonSelectedFilter>`
  border-radius: 9999px;
  border: 0;
  padding: 1rem 3.625rem;
  background: ${(props) =>
    props.isActive ? '#303F4B' : 'transparent'};
  color: ${(props) =>
    props.isActive ? props.theme.white : props.theme['gray-500']};
  font-weight: 500;
  font-size: 1.25rem;

  @media (max-width: 625px) {
    padding: 1rem 1.25rem;
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.75rem;
  }
`;

export const PlanListContent = styled.div`
  display: flex;
  width: 100%;
  /* align-items: flex-end; */
  gap: 2rem;
  /* overflow: auto; */

  .keen-slider__slide {
    max-width: 21.25rem;
    min-width: 21.25rem;
  }

  @media (min-width: 1500px) {
    justify-content: center;
  }

  @media (max-width: 1100px) {
    /* gap: 0; */
    justify-content: start;

    padding-left: 1rem;
  }

  @media (max-width: 480px) {
    /* max-width: 92vw; */
    margin-right: 1rem;
    justify-content: start;
  }
`;