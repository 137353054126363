import axios from 'axios';
import PlaceholderLoading from 'react-placeholder-loading';
import { DownOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useState, useEffect } from 'react';

import * as Constants from '../Constants';
import {
  Container,
  ContainerVideos,
  ContentFilterSearchVideo,
  Divider,
  RowDiv,
  ShowLess,
} from './HomeVideosStyles';
import { BannerLastVideo } from './components/BannerLastVideo';
import { CardVideo } from './components/CardVideo';
import { useTitleAndUser } from '../hooks/userTitle';
import { EducationLoader } from '../jsx/components/LoadingModels';
const { Search } = Input;

const HomeVideos = () => {
  const { setTitle } = useTitleAndUser();
  // Videos State
  const [videos, setVideos] = useState({
    count: 0,
    current: 1,
    data: [],
    length: 0,
    links: {
      next: null,
      previous: null,
    },
    total_pages: 0,
  });
  const [isVideoFetched, setIsVideoFetched] = useState(false);
  const [category, setCategory] = useState(Array.from({ length: 10 }));
  const skeleton = Array.from({ length: 8 });
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingAll, setLoadingAll] = useState(false);


  const [videoAction, setVideoAction] = useState({
    count: 0,
    current: 1,
    data: [],
    length: 0,
    links: {
      next: null,
      previous: null,
    },
    total_pages: 0,
  });
  const [videoFi, setVideoFi] = useState({
    count: 0,
    current: 1,
    data: [],
    length: 0,
    links: {
      next: null,
      previous: null,
    },
    total_pages: 0,
  });
  const [videoFundoInvestimento, setVideoFundoInvestimento] = useState({
    count: 0,
    current: 1,
    data: [],
    length: 0,
    links: {
      next: null,
      previous: null,
    },
    total_pages: 0,
  });
  const [videoEducation, setVideoEducation] = useState({
    count: 0,
    current: 1,
    data: [],
    length: 0,
    links: {
      next: null,
      previous: null,
    },
    total_pages: 0,
  });
  const [iBoxVideos, setIBoxVideos] = useState({
    count: 0,
    current: 1,
    data: [],
    length: 0,
    links: {
      next: null,
      previous: null,
    },
    total_pages: 0,
  });
  const [videoFilter, setVideoFilter] = useState([]);

  // UseEffect
  useEffect(() => {
    setTitle('Educação');
  }, []);

  useEffect(() => {
    getAllVideos();
    axios.get(`${Constants.api}/videoscube/api/category/`).then((response) => {
      let array = response.data;
      for (var i = array.length - 1; i > 0; i--) {
        // Generate random number
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      setCategory(array);
      // setIscategoryFetched(true);
    });
  }, []);

  useEffect(() => {
    setTimeout(
      () => {
        getCategoryVideo(1);
        getCategoryVideo(2);
        getCategoryVideo(3);
        getCategoryVideo(4);
        getCategoryVideo(5);
      },

      1000
    );
  }, [category]);

  async function getAllVideos() {
    setLoadingAll(true);

    await axios
      .get(`${Constants.api}/videoscube/api/video/`, {
        params: {
          page_size: 4,
          page: 1,
          exclude_category: 2,
        },
      })
      .then((response) => {
        let res = response.data;
        res.data = res.data.sort((a, b) => {
          //@ts-ignore
          if (a.date < b.date) return 1;
          if (a.date > b.date) return -1;
          return 0;
        });
        setVideos(res);
        setIsVideoFetched(true);
      })
      .catch((error) => {
        return;
      });
    setLoadingAll(false);
  }
  function handleChangeNewPageAll(link) {
    axios
      .get(link)
      .then((response) => {
        let res = response.data;
        res.data = [...videos.data, ...response.data.data];
        res.data = res.data.sort((a, b) => {
          //@ts-ignore
          if (a.date < b.date) return 1;
          if (a.date > b.date) return -1;
          return 0;
        });
        setVideos(res);
      })
      .catch(function (error) {});
  }

  function handleChangeNewPageAction(link) {
    axios
      .get(link)
      .then((response) => {
        let res = response.data;
        res.data = [...videoAction.data, ...response.data.data];
        res.data = res.data.sort((a, b) => {
          //@ts-ignore
          if (a.date < b.date) return 1;
          if (a.date > b.date) return -1;
          return 0;
        });
        setVideoAction(res);
      })
      .catch(function (error) {});
  }
  function handleChangeNewPageFi(link) {
    axios
      .get(link)
      .then((response) => {
        let res = response.data;
        res.data = [...videoFi.data, ...response.data.data];
        res.data = res.data.sort((a, b) => {
          //@ts-ignore
          if (a.date < b.date) return 1;
          if (a.date > b.date) return -1;
          return 0;
        });
        setVideoFi(res);
      })
      .catch(function (error) {});
  }
  function handleChangeNewPageEducation(link) {
    axios
      .get(link)
      .then((response) => {
        let res = response.data;
        res.data = [...videoEducation.data, ...response.data.data];
        res.data = res.data.sort((a, b) => {
          //@ts-ignore
          if (a.date < b.date) return 1;
          if (a.date > b.date) return -1;
          return 0;
        });
        setVideoEducation(res);
      })
      .catch(function (error) {});
  }
  function handleChangeNewPageFundoInvestimento(link) {
    axios
      .get(link)
      .then((response) => {
        let res = response.data;
        res.data = [...videoFundoInvestimento.data, ...response.data.data];
        res.data = res.data.sort((a, b) => {
          //@ts-ignore
          if (a.date < b.date) return 1;
          if (a.date > b.date) return -1;
          return 0;
        });
        setVideoFundoInvestimento(res);
      })
      .catch(function (error) {});
  }
  function handleChangeNewPageIBox(link) {
    axios
      .get(link)
      .then((response) => {
        let res = response.data;
        res.data = [...iBoxVideos.data, ...response.data.data];
        res.data = res.data.sort((a, b) => {
          //@ts-ignore
          if (a.date < b.date) return 1;
          if (a.date > b.date) return -1;
          return 0;
        });
        setIBoxVideos(res);
      })
      .catch(function (error) {});
  }

  function handleChangeSearch(search) {
    setLoading(true);
    let value = search.target.value;
    setSearch(value);
    if (value.length <= 0) {
      setIsSearch(false);
    } else {
      setIsSearch(true);
    }

    axios
      .get(`${Constants.api}/videoscube/api/video/`, {
        params: {
          search: value,
        },
      })
      .then((response) => {
        setVideoFilter(response.data.data);
      })
      .catch((error) => {
        return;
      });

    setLoading(false);
  }

  async function getCategoryVideo(value) {
    setIsVideoFetched(false);

  

    await axios
      .get(
        `${Constants.api}/videoscube/api/video/?category__id=${value}&page_size=4`
      )
      .then((response) => {
        let res = response.data;
        res.data = res.data.sort((a, b) => {
          //@ts-ignore
          if (a.date < b.date) return 1;
          if (a.date > b.date) return -1;
          return 0;
        });
        if (value === 1) {
          setVideoFi(res);
        } else if (value === 2) {
          setIBoxVideos(res);
        } else if (value === 3) {
          setVideoEducation(res);
        } else if (value === 4) {
          setVideoAction(res);
        } else if (value === 5) {
          setVideoFundoInvestimento(res);
        }
        setIsVideoFetched(true);
        
      })
      .catch((error) => {
      });
  }

  return loadingAll ? (
    <EducationLoader />
  ) : (
    <Container>
      <BannerLastVideo />
      <ContentFilterSearchVideo>
        <Search
          placeholder="Buscar..."
          enterButton="Buscar"
          size="large"
          loading={loading}
          onChange={(e) => handleChangeSearch(e)}
          className="z-0"
        />
      </ContentFilterSearchVideo>

      {isSearch ? (
        <>
          <Divider>
            <h2>{search}</h2>
          </Divider>

          <ContainerVideos>
            <RowDiv>
              {videoFilter.length &&
                videoFilter.map((video, i) => {
                  return <CardVideo key={video.video_id} video={video} />;
                })}
            </RowDiv>

            {!isVideoFetched ? (
              <>
                {skeleton.map((placeholder) => {
                  return (
                    <div
                      className="col-12 col-sm-6 col-md-4 col-lg-3"
                      key={placeholder}
                    >
                      <div>
                        <PlaceholderLoading
                          shape="rect"
                          width={'100%'}
                          height={170}
                        />
                      </div>
                      <div style={{ marginTop: 10 }}>
                        <PlaceholderLoading
                          shape="rect"
                          height={'15'}
                          width={'90%'}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </ContainerVideos>
          <br />
        </>
      ) : (
        <>
          {videoAction.data.length >= 1 && (
            <Divider>
              <h2>Ações</h2>
            </Divider>
          )}

          {videoAction.data.length > 0 && (
            <ContainerVideos>
              <RowDiv>
                {videoAction.data.map((video, i) => {
                  return <CardVideo key={video.video_id} video={video} />;
                })}
              </RowDiv>
              {!isVideoFetched && (
                <>
                  {skeleton.map((placeholder) => {
                    return (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3"
                        key={placeholder}
                      >
                        <div>
                          <PlaceholderLoading
                            shape="rect"
                            width={'100%'}
                            height={170}
                          />
                        </div>
                        <div style={{ marginTop: 10 }}>
                          <PlaceholderLoading
                            shape="rect"
                            height={'15'}
                            width={'90%'}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
              {videoAction.data.length > 4 && (
                <ShowLess onClick={() => getCategoryVideo(4)}>
                  Mostrar menos
                </ShowLess>
              )}
              <footer>
                {videoAction.data.length < videoAction.count ? (
                  <p
                    onClick={() =>
                      handleChangeNewPageAction(videoAction.links.next)
                    }
                  >
                    Ver mais <DownOutlined />
                  </p>
                ) : (
                  <>
                    <br />
                  </>
                )}
              </footer>
            </ContainerVideos>
          )}

          {videoFi.data.length >= 1 && (
            <Divider>
              <h2>Fundos imobiliários</h2>
            </Divider>
          )}

          {videoFi.data.length > 0 && (
            <ContainerVideos>
              <RowDiv>
                {videoFi.data.map((video, i) => {
                  return <CardVideo key={video.video_id} video={video} />;
                })}
              </RowDiv>
              {!isVideoFetched && (
                <>
                  {skeleton.map((placeholder) => {
                    return (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3"
                        key={placeholder}
                      >
                        <div>
                          <PlaceholderLoading
                            shape="rect"
                            width={'100%'}
                            height={170}
                          />
                        </div>
                        <div style={{ marginTop: 10 }}>
                          <PlaceholderLoading
                            shape="rect"
                            height={'15'}
                            width={'90%'}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              )}

              {videoFi.data.length > 4 && (
                <ShowLess onClick={() => getCategoryVideo(1)}>
                  Mostrar menos
                </ShowLess>
              )}
              <footer>
                {videoFi.data.length < videoFi.count ? (
                  <p onClick={() => handleChangeNewPageFi(videoFi.links.next)}>
                    Ver mais <DownOutlined />
                  </p>
                ) : (
                  <>
                    <br />
                  </>
                )}
              </footer>
            </ContainerVideos>
          )}
          {videoEducation.data.length > 0 && (
            <Divider>
              <h2>Educação financeira</h2>
            </Divider>
          )}

          {videoEducation.data.length > 0 && (
            <ContainerVideos>
              <RowDiv>
                {videoEducation.data.map((video, i) => {
                  return <CardVideo key={video.video_id} video={video} />;
                })}
              </RowDiv>
              {!isVideoFetched && (
                <>
                  {skeleton.map((placeholder) => {
                    return (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3"
                        key={placeholder}
                      >
                        <div>
                          <PlaceholderLoading
                            shape="rect"
                            width={'100%'}
                            height={170}
                          />
                        </div>
                        <div style={{ marginTop: 10 }}>
                          <PlaceholderLoading
                            shape="rect"
                            height={'15'}
                            width={'90%'}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              )}

              {videoEducation.data.length > 4 && (
                <ShowLess onClick={() => getCategoryVideo(3)}>
                  Mostrar menos
                </ShowLess>
              )}
              <footer>
                {videoEducation.data.length < videoEducation.count ? (
                  <p
                    onClick={() =>
                      handleChangeNewPageEducation(videoEducation.links.next)
                    }
                  >
                    Ver mais <DownOutlined />
                  </p>
                ) : (
                  <>
                    <br />
                  </>
                )}
              </footer>
            </ContainerVideos>
          )}
          {iBoxVideos.data.length > 0 && (
            <Divider>
              <h2>Tutoriais</h2>
            </Divider>
          )}

          {iBoxVideos.data.length > 0 && (
            <ContainerVideos>
              <RowDiv>
                {iBoxVideos.data.map((video, i) => {
                  return <CardVideo key={video.video_id} video={video} />;
                })}
              </RowDiv>
              {!isVideoFetched && (
                <>
                  {skeleton.map((placeholder) => {
                    return (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3"
                        key={placeholder}
                      >
                        <div>
                          <PlaceholderLoading
                            shape="rect"
                            width={'100%'}
                            height={170}
                          />
                        </div>
                        <div style={{ marginTop: 10 }}>
                          <PlaceholderLoading
                            shape="rect"
                            height={'15'}
                            width={'90%'}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              )}

              {iBoxVideos.data.length > 4 && (
                <ShowLess onClick={() => getCategoryVideo(2)}>
                  Mostrar menos
                </ShowLess>
              )}
              <footer>
                {iBoxVideos.data.length < iBoxVideos.count ? (
                  <p
                    onClick={() =>
                      handleChangeNewPageIBox(iBoxVideos.links.next)
                    }
                  >
                    Ver mais <DownOutlined />
                  </p>
                ) : (
                  <>
                    <br />
                  </>
                )}
              </footer>
            </ContainerVideos>
          )}
          {videoFundoInvestimento.data.length > 0 && (
            <Divider>
              <h2>Fundos de investimentos</h2>
            </Divider>
          )}

          {videoFundoInvestimento.data.length > 0 && (
            <ContainerVideos>
              <RowDiv>
                {videoFundoInvestimento.data.map((video, i) => {
                  return <CardVideo key={video.video_id} video={video} />;
                })}
              </RowDiv>
              {!isVideoFetched && (
                <>
                  {skeleton.map((placeholder) => {
                    return (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3"
                        key={placeholder}
                      >
                        <div>
                          <PlaceholderLoading
                            shape="rect"
                            width={'100%'}
                            height={170}
                          />
                        </div>
                        <div style={{ marginTop: 10 }}>
                          <PlaceholderLoading
                            shape="rect"
                            height={'15'}
                            width={'90%'}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              )}

              {videoFundoInvestimento.data.length > 4 && (
                <ShowLess onClick={() => getCategoryVideo(5)}>
                  Mostrar menos
                </ShowLess>
              )}
              <footer>
                {videoFundoInvestimento.data.length <
                videoFundoInvestimento.count ? (
                  <p
                    onClick={() =>
                      handleChangeNewPageFundoInvestimento(
                        videoFundoInvestimento.links.next
                      )
                    }
                  >
                    Ver mais <DownOutlined />
                  </p>
                ) : (
                  <>
                    <br />
                  </>
                )}
              </footer>
            </ContainerVideos>
          )}

          <Divider>
            <h2>Todos</h2>
          </Divider>

          {videos.data.length > 0 && (
            <ContainerVideos>
              <RowDiv>
                {videos.data.map((video, i) => {
                  return <CardVideo key={video.video_id} video={video} />;
                })}
              </RowDiv>

              {!isVideoFetched && (
                <>
                  {skeleton.map((placeholder) => {
                    return (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3"
                        key={placeholder}
                      >
                        <div>
                          <PlaceholderLoading
                            shape="rect"
                            width={'100%'}
                            height={170}
                          />
                        </div>
                        <div style={{ marginTop: 10 }}>
                          <PlaceholderLoading
                            shape="rect"
                            height={'15'}
                            width={'90%'}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              )}

              {videos.data.length > 4 && (
                <ShowLess onClick={() => getAllVideos()}>
                  Mostrar menos
                </ShowLess>
              )}
              <footer>
                {videos.data.length < videos.count ? (
                  <p onClick={() => handleChangeNewPageAll(videos.links.next)}>
                    Ver mais <DownOutlined />
                  </p>
                ) : (
                  <>
                    <br />
                  </>
                )}
              </footer>
            </ContainerVideos>
          )}
        </>
      )}
    </Container>
  );
};

export default HomeVideos;
