import { useEffect, useState } from 'react';
import { PortfolioBalanceSheetStatistics } from '../../../components/PortfolioBalanceSheetStatistics';
import { Container, RowDiv } from './homeStyles';
import { useNewTheme } from '../../../hooks/useNewTheme';
import { useActives } from '../../../hooks/useActives';
import { useTitleAndUser } from '../../../hooks/userTitle';
import { CarouselDashInfos } from './components/CarouselDashInfos';
import { DataHomeResume } from './components/DataHomeResume';
import { TutorialIbox } from './components/TutorialIbox';
import { NewsComponent } from './components/NewsComponent';
import { Heading } from '../../../components/DefaultStylesTypography';
import { CongratulationsCreatingAccount } from './components/CongratulationsCreatingAccount';
import './customToastStyles.css';
import { ModalDesigner } from '../../../components/ModalDesigner';
import { string } from 'zod';
import { expireMensages } from '../../../social/constants';
import axios from 'axios';
import { userRoutes } from '../../../utils/RoutesApi';
import moment from 'moment';

const Home = () => {
  const { user } = useTitleAndUser();
  const announcement = localStorage.getItem(process.env.REACT_APP_ANNOUNCEMENT);
  const route = window.location.pathname;
  const updateNewsAtt = localStorage.getItem(process.env.REACT_APP_NEWS);
  const [modalWelcome, setModalWelcome] = useState(
    () => route === '/dashboard/led'
  );
  const [isPromotion, setIsPromotion] = useState(
    () => announcement !== 'close'
  );
  const { setTitle } = useTitleAndUser();
  const { shortSidebar } = useNewTheme();
  const { percentage } = useActives();
  const isLead = localStorage.getItem('isLead');
  const isLeadJSON = isLead ? JSON.parse(isLead) : [];
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [plan, setPlan] = useState({});

  const checkPlanExpiry = (plan) => {
    try {
      if (plan.currentPeriodEnd) {
        const daysLeft = moment(plan?.currentPeriodEnd).diff(moment(), 'days');

        const messageKey = String(daysLeft);
        const message = expireMensages[messageKey];
        if (message) {
          setModalMessage(message);
          setModalVisible(true);
        }
      }
    } catch (error) {}
  };

  async function handlePayment() {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}${userRoutes.STATUS_PAYMENT}`)
        .then((response) => {
          setPlan(response.data);
        })
        .catch((err) => {
          //@ts-ignore

          setPlan(null);
        });
    } catch (error) {}
  }

  if (!isLeadJSON.includes(user.email) && route === '/dashboard/led') {
    setTimeout(() => {
      localStorage.setItem(
        'isLead',
        JSON.stringify([...isLeadJSON, user.email])
      );
    }, 5000);
  }
  useEffect(() => {
    handlePayment();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    setTitle('Página inicial');
    localStorage.removeItem('news_v_1.0.9');
    localStorage.removeItem('promotion');

    if (announcement !== 'close') {
      localStorage.setItem(process.env.REACT_APP_ANNOUNCEMENT, 'close');
    }
    if (updateNewsAtt !== 'false') {
      localStorage.setItem(process.env.REACT_APP_NEWS, 'false');
    }

    checkPlanExpiry(plan);
  }, [announcement, route, setTitle, updateNewsAtt, user.email, plan]);

  return (
    <div>
      <header>
        <Container>
          <DataHomeResume />

          <RowDiv isShort={shortSidebar}>
            <PortfolioBalanceSheetStatistics percentage={percentage} />

            <CarouselDashInfos />
          </RowDiv>

          <div style={{ width: '100%', maxWidth: '1360px' }}>
            <Heading as="h3" variant="tertiary" className="tutorial">
              Tutorial
            </Heading>
            <div style={{ position: 'relative', paddingTop: '56.25%' }}>
              <iframe
                id="panda-41fd2922-e917-4d85-a28e-a57d6c0ac5d7"
                src="https://player-vz-447732c4-8c0.tv.pandavideo.com.br/embed/?v=41fd2922-e917-4d85-a28e-a57d6c0ac5d7"
                style={{
                  border: 'none',
                  borderRadius: 16,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
                allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
                allowFullScreen
                width="100%"
                height="100%"
              ></iframe>
            </div>
          </div>
          <TutorialIbox />

          <ModalDesigner
            isOpen={isPromotion}
            id="congratulations"
            title="📢 Atenção, iBoxer!"
            setState={(newState) => {
              // emptyCacheStorage();
              // window.location.reload();
              localStorage.setItem(process.env.REACT_APP_ANNOUNCEMENT, 'close');
              setIsPromotion(newState);
              // setNewAtt(newState);
            }}
          >
            <NewsComponent />
          </ModalDesigner>

          <ModalDesigner
            isOpen={modalWelcome}
            id="congratulations"
            setState={setModalWelcome}
          >
            <CongratulationsCreatingAccount />
          </ModalDesigner>
          <div id="avisoExpira">
            <ModalDesigner
              id="avisoModal"
              className="modal-designer"
              isOpen={modalVisible}
              setState={setModalVisible}
            >
              <h2 id="aviso">📢Sua assinatura está expirando!</h2>
              <h4 id="subtititulo">Olá, iBoxer!</h4>
              <p id="mensagem">{modalMessage}</p>
            </ModalDesigner>
          </div>
        </Container>
      </header>
    </div>
  );
};
export default Home;
