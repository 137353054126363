import { Carousel } from 'antd';
import axios from 'axios';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { LinkB3Card } from '../../../../../components/LinkB3Card';
import { useTitleAndUser } from '../../../../../hooks/userTitle';
import { videosRoutes } from '../../../../../utils/RoutesApi';

import { FeaturedVideo } from './components/FeaturedVideo';

import styles from './styles.module.css';
import { NewsComponentDash } from './components/NewsComponentDash';
import { FeedbackUser } from './components/FeedbackUser';
import { curseImg } from '../../../../../Constants/images';

interface IPropsVideos {
  category: number;
  channel: number;
  channelThumbnail: string;
  channelTitle: string;
  comment: number[];
  date: string;
  description: string;
  dislike: number[];
  id: number;
  like: number[];
  thumbnail: string;
  title: string;
  video_id: string;
  view: number[];
}

export function CarouselDashInfos() {
  //@ts-ignore
  const { user } = useTitleAndUser();
  const [videos, setVideos] = useState<IPropsVideos[]>([]);
  useEffect(() => {
    handleGetLastFiveVideos();
  }, []);

  function handleGetLastFiveVideos() {
    axios
      .get(`${process.env.REACT_APP_API_URL}${videosRoutes.LAST_FIVE_VIDEOS}`)
      .then((response) => {
        setVideos([response.data[0], response.data[1]]);
      })
      .catch((error) => {});
  }

  return (
    <Carousel
      arrows
      nextArrow={<CaretRight size={32} color="black" alt="Próximo slide" />}
      prevArrow={<CaretLeft size={32} color="black" alt="Slide anterior" />}
      autoplay
      autoplaySpeed={5000}
      className={styles.Container}
    >
      {user.carteira !== 'automatico' && <LinkB3Card />}
      {/* <CardCurse /> */}
      {videos.map((video) => (
        <FeaturedVideo video={video} key={video.video_id} />
      ))}
      <FeedbackUser />
      <div className={styles.CurseContent}>
        <a href="https://www.oinvestidorcompleto.com.br/" target="_blank">
          <img src={curseImg} alt="" />
        </a>
      </div>
      <NewsComponentDash />
    </Carousel>
  );
}
