import styled from 'styled-components';
import { css } from 'styled-components';

interface IPropsContainerInput {
  isSearch: boolean;
  isOutline: boolean;
  isError: boolean;
  roundedFull: boolean;
}

export const Container = styled.div<IPropsContainerInput>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${(props) => (props.roundedFull ? '40px' : '8px')};
  position: relative;
  gap: 0.25rem;
  border: 1px solid transparent;
  background: ${(props) => props.theme.white};
  width: 100%;
  padding: 0.875rem 1rem;
  box-shadow: ${(props) => props.theme['shadow-1']};

  button {
    all: unset;
    display: ${(props) => (props.isSearch ? 'flex' : 'none')};
    color: black;
    cursor: pointer;
    color: ${(props) => props.theme['gray-700']};
    line-height: 0;
    font-size: 1.125rem;
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &:has(input:focus) {
    border-color: ${(props) => props.theme['primary-800']};
  }

  &:has(input:disabled) {
    cursor: not-allowed;
    background: ${(props) => props.theme['white-300']};
    button {
      cursor: not-allowed;
    }
  }

  ${(props) =>
    props.isOutline &&
    css`
      border-color: ${props.theme['gray-200']};
    `}
  ${(props) =>
    props.isError &&
    css`
      border-color: ${props.theme['danger-300']};
    `}
`;

export const Input = styled.input`
  all: unset;
  width: 100%;
  color: ${(props) => props.theme['gray-400']};
  font-size: ${(props) => props.theme.spacing[16]};
  ::placeholder {
    color: ${(props) => props.theme['gray-100']};
  }
`;

export const FixContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .prefix {
    color: ${(props) => props.theme['gray-400']};
    font-size: ${(props) => props.theme.spacing[16]};
  }
`;
