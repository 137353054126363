import { styled } from 'styled-components';

export const AsideContentFilters = styled.aside`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
  width: 100%;
  max-width: 18.75rem;
  border-radius: 12px;
  gap: 1rem;
  padding: 1.5rem 1rem;

  @media (max-width: 1100px) {
    margin-bottom: 5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const ContentInputFilters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    margin: 0;
    font-size: 0.875rem;
    color: ${(props) => props.theme['gray-500']};
    font-weight: 400;
  }
  span {
    text-align: center;
    font-weight: 500;
    color: ${(props) => props.theme['gray-500']};
    font-size: 0.75rem;
  }

  select {
    box-shadow: 2px 2px 6px 0px #0000001a;
    border-radius: 8px;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 400;
    color: ${(props) => props.theme['gray-200']};
    border: none;
  }
`;

export const ButtonShowMoreFilters = styled.button`
  all: unset;
  background: rgba(131, 196, 255, 0.3);
  border-radius: 38px;
  padding: 0.75rem 1.5rem;
  text-align: center;
  font-size: 1rem;
  color: ${(props) => props.theme['primary-800']};
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;
