import {  useState, ChangeEvent } from 'react';


import { Container, Content, WarningText } from './styles';
import { Info } from '@phosphor-icons/react';

export function CalculadoraJurosEquivalente() {

  const [taxaJuros, setTaxaJuros] = useState<number>();
  const periodoJuros = 1;
  const [jurosEquivalente, setJurosEquivalente] = useState<number>();

  const periodoJurosEquivalente = 1;


  function onChangeDefineEquivalentRate(e: ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);
    if (value && value !== 0 && periodoJuros && periodoJurosEquivalente) {
      let taxJuros = value / 100;
      let periodo = periodoJuros * 12;

      let a = 1 + taxJuros;
      let potencial = periodoJurosEquivalente / periodo;
      let b = Math.pow(a, potencial);
      b = b - 1;
      b = b * 100;
      setJurosEquivalente(parseFloat(b.toFixed(4) ?? 0));
    }

    setTaxaJuros(value);
  }

  function onChangeEquivalentRate(e: ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value);

    if (value !== 0 && value !== undefined) {
      let taxJuros = value / 100;
      let periodo = periodoJuros * 12;

      let a = 1 + taxJuros;
      let potencial = periodoJurosEquivalente * periodo;
      let b = Math.pow(a, potencial);
      b = b - 1;
      b = b * 100;
      setTaxaJuros(Number(b.toFixed(2)) ?? 0);
    }

    setJurosEquivalente(value);
  }

  return (
    <Container>
      <Content>
        <WarningText>
          <Info />
          <p>
            Você sabia que em se tratando de juros compostos, uma taxa de 12% ao
            ano não equivale a uma taxa de 1% ao mês?
          </p>
        </WarningText>
        <p>Calcule a taxa equivalente colocando a taxa que você já sabe.</p>
        <div className="row">
          <div className="inputContent maskPercent step1">
            <label htmlFor="taxJur">Taxa anual</label>
            <input
              id="taxJur"
              type="number"
              step="0.0001"
              placeholder="ex: 10"
              value={taxaJuros}
              onChange={onChangeDefineEquivalentRate}
            />
          </div>
          <div className="inputContent maskPercent step2">
            <label htmlFor="jurEquivalente">Taxa mensal</label>
            <input
              id="jurEquivalente"
              type="number"
              step="0.0001"
              placeholder="ex: 1"
              onChange={onChangeEquivalentRate}
              value={jurosEquivalente}
            />
          </div>
        </div>
      </Content>
    </Container>
  );
}
